import axios from "axios";
import appConfig from "@/config/app";
import store from "@/store";

const appUrl = appConfig.APP_URL;

const adminUrl = `${appUrl}/admin`;
const vendorUrl = `${appUrl}/vendor`;

const instance = axios.create({
  baseURL: appUrl,
  timeout: 0
});

instance.interceptors.request.use(
  config => {
    //  Generate cancel token source
    const source = axios.CancelToken.source();

    // Set cancel token on this request
    config.cancelToken = source.token;

    // Add to vuex to make cancellation available from anywhere
    store.commit("ADD_CANCEL_TOKEN", source);

    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);

export const $axios = instance;

export const $adminAxios = axios.create({
  baseURL: adminUrl,
  timeout: 0
});

export const $vendorAxios = axios.create({
  baseURL: vendorUrl,
  timeout: 0
});

export const $UpdateAuthorizationTokens = () => {
  const userType = localStorage.getItem(`userType`);

  if (userType === "SUPER_ADMIN" || userType === "ADMIN") {
    $adminAxios.defaults.headers.common.Authorization = localStorage.getItem(
      "adminAccessToken"
    );
  } else if (userType === "VENDOR") {
    $vendorAxios.defaults.headers.common.Authorization = localStorage.getItem(
      "vendorAccessToken"
    );
  } else {
    $vendorAxios.defaults.headers.common.Authorization = null
  }
};

$UpdateAuthorizationTokens();

export default {
  $axios,
  $adminAxios,
  $vendorAxios,
  $UpdateAuthorizationTokens
};
