const appVersion = "0.0.1";

const env =
  process.env && process.env.VUE_APP_STAGE ? process.env.VUE_APP_STAGE : "DEV";

const config = {
  APP_VERSION: appVersion,
  APP_URL: process.env.VUE_APP_URL,
  USER_WEBSITE_URL: process.env.VUE_APP_USER_WEBSITE_URL,
  VUE_APP_STAGE: env,
  PRODUCT_IMAGES_URL: process.env.VUE_APP_PRODUCT_IMAGES_URL,
  product_images_sample_image_url: 'sample-image.jpg',
};

export default config;
