<template>
  <div>
    <el-form label-position="left" label-width="100px" style="padding: 10px;" >
      <h3 style="margin-top: 30px;">Sign In</h3>
      <el-row style="margin-top: 20px;">
        <el-col :lg="6" :md="6" :sm="6" :xs="24">
          <el-form-item label="Email">
            <el-input v-model="email"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :lg="6" :md="6" :sm="6" :xs="24">
          <el-form-item label="Password">
            <el-input type="password" v-model="password"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="6">
          <el-form-item>
            <el-button
              type="primary" @click="signIn">
              <span v-if="loading">
                <i class="el-icon-loading"></i>
              </span>
              <span v-else>
                Sign In
              </span>
            </el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import { $adminAxios, $UpdateAuthorizationTokens } from '@/config/axios'
export default {
  data() {
    return {
      email: null,
      password: null,
      loading: false,
    }
  },
  methods: {
    async signIn() {
      try {
        this.loading = true

        const data = {
          email: this.email,
          password: this.password,
        }

        await this.$store.dispatch('auth/signIn', data)
        if (this.$store.getters['auth/getSignInErrorMessage']) {
          this.$notify.error({
            title: 'Error',
            message: this.$store.getters['auth/getSignInErrorMessage'],
            type: 'error'
          });

          return
        }

        this.$notify.success({
          title: 'Success',
          message: 'Successfully logged in',
        })

        const userProfile = this.$store.getters['auth/getUserProfileData']
        const userType = userProfile.user_type

        if (userType === 'VENDOR') {
          this.$router.push('/vendor/orders-main')
        } else {
          this.$router.push('/admin/orders-main')
        }
      } catch (err) {
        console.error(err)
        this.$notify.error({
          title: 'Error',
          message: err.message || 'Something went wrong',
          type: 'error'
        });
      } finally {
        this.loading = false
      }
    },
    apiCall (url, payload) {
      return $adminAxios.post(url, payload)
    },
  }
}
</script>
