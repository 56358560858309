<template>
  <div>
    <el-scrollbar class="customer-details-scroll">
      <el-form label-position="left" label-width="100px" :model="locationInformationData" >
        <!-- <el-row>
          <el-col>
            <el-button @click="addNewArea" title="Add new area"><i class="el-icon-plus"></i></el-button>
          </el-col>
        </el-row> -->
        <el-row>
          <el-col :span="8">
            <el-input rows="6" type="textarea" v-model="areasStr" placeholder="area names"></el-input>
            <el-button @click="splitAreas" title="Split Areas">Fill Data</el-button>
          </el-col>
        </el-row>
        <el-table
          class="table"
          :data="areas"
          style="width: 100%; text-align: left; margin-top: 10px;">
          <el-table-column
            label="Area Name"
            width="250px"
            prop="title">
            <template slot-scope="{ row }">
              <el-input v-model="row.title"></el-input>
            </template>
          </el-table-column>
          <el-table-column
            label="Slots Available"
            width="250px"
            prop="deliverable_slots" >
            <template slot-scope="{ row }">
              <el-select v-model="row.deliverable_slots" multiple collapse-tags>
                <el-option key="T1" label="Standard" value="T1"></el-option>
                <el-option key="T2" label="Morning" value="T2"></el-option>
                <el-option key="T3" label="Afternoon" value="T3"></el-option>
                <el-option key="T4" label="Evening" value="T4"></el-option>
                <el-option key="T5" label="Midnight" value="T5"></el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column
            label="Standard"
            prop="timeslot_1">
            <template slot-scope="{ row }">
              <el-input v-model="row.deliverable_slots_prices.T1" :disabled="isDeliverable(row, 'T1')"></el-input>
            </template>
          </el-table-column>
          <el-table-column
            label="Morning"
            prop="T2">
            <template slot-scope="{ row }">
              <el-input v-model="row.deliverable_slots_prices.T2" :disabled="isDeliverable(row, 'T2')"></el-input>
            </template>
          </el-table-column>
          <el-table-column
            label="Afternoon"
            prop="T3">
            <template slot-scope="{ row }">
              <el-input v-model="row.deliverable_slots_prices.T3" :disabled="isDeliverable(row, 'T3')"></el-input>
            </template>
          </el-table-column>
          <el-table-column
            label="Evening"
            prop="timeslot_4">
            <template slot-scope="{ row }">
              <el-input v-model="row.deliverable_slots_prices.T4" :disabled="isDeliverable(row, 'T4')"></el-input>
            </template>
          </el-table-column>
          <el-table-column
            label="Midnight"
            prop="T5">
            <template slot-scope="{ row }">
              <el-input v-model="row.deliverable_slots_prices.T5" :disabled="isDeliverable(row, 'T5')"></el-input>
            </template>
          </el-table-column>
        </el-table>
        <el-row>
          <el-col>
            <el-button @click="addNewArea" title="Add new area"><i class="el-icon-plus"></i></el-button>
          </el-col>
        </el-row>
        <br/>
        <el-row>
          <el-col :span="6">
            <!-- <el-form-item> -->
              <el-button
                type="primary" @click="updateData">{{ buttonText }}</el-button>
            <!-- </el-form-item> -->
          </el-col>
        </el-row>
      </el-form>
    </el-scrollbar>
  </div>
</template>

<script>
import { $adminAxios } from '../../../config/axios'
export default {
  components: {
  },
  props: [
    'locationInformation',
    'locationId',
  ],
  data() {
    return {
      locationInformationData: {},
      locationsLoading: false,
      locationSearchString: null,
      areas: [{}],
      areasStr: '',
    }
  },
  methods: {
    updateData() {
      const newData = []

      this.areas.forEach(el => {
        el.title = el.title && el.title.trim()
        el.title = (el.title || '').replace(/\s\s+/g, '')

        if (!el.title) {
          return
        }

        newData.push(el)
      })

      console.log(newData)

      this.$emit('updateData', { areas: newData })
    },
    addNewArea() {
      this.areas.push({
        deliverable_slots: ['T1', 'T2', 'T3', 'T4', 'T5'],
        deliverable_slots_prices: {},
      })
    },
    removeArea(index) {
      console.log('sdsd', index)
      this.areas.splice(index, 1)
    },
    splitAreas() {
      const data = this.areasStr.split('\n').filter(el => el)
      console.log(data)
      const tempAreas = data.reduce((acc, cur) => {
        const temp = cur.split(',').filter(el => el).map(el => el.replace(/\s+/g, ' ').trim()).filter(el => el)
        acc.push(...temp)
        return acc
      }, [])

      tempAreas.forEach(area => {
        this.areas.push({
          title: area,
          deliverable_slots: ['T1', 'T2', 'T3', 'T4', 'T5'],
          deliverable_slots_prices: {},
        })
      })

      this.areasStr = ''
    }
  },
  computed: {
    buttonText() {
      return this.locationId ? 'Update Areas' : 'Update Areas'
    },
    isDeliverable() {
      return (row, tsId) => {
        return !row.deliverable_slots?.includes(tsId)
      }
    },
  },
  watch: {
    locationInformation(val) {
      this.locationInformationData = JSON.parse(JSON.stringify(val))
      this.areas = JSON.parse(JSON.stringify(val)).areas.map(el => {
        el.inside_city = el.inside_city === false ? el.inside_city : true;
        el.deliverable_slots = el.deliverable_slots || ['T1', 'T2', 'T3', 'T4', 'T5']
        el.deliverable_slots_prices = el.deliverable_slots_prices || {}
        return el;
      }) || this.areas
    },
  }
}
</script>

<style lang="scss" scoped>
.customer-details-scroll {
  position: relative;
  height: calc(100vh - 120px);
  margin-top: 10px;
}
</style>
