<template>
  <div>
    <el-scrollbar class="customer-details-scroll">
      <el-form label-position="left" label-width="100px" :model="locationInformationData" >
        <el-row>
          <el-col :span="6">
            <el-form-item label="Title">
              <el-input v-model="locationInformationData.title"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6">
            <el-form-item label="Status">
              <el-select v-model="locationInformationData.status" placeholder="Select Status">
                <el-option label="Draft" value="DRAFT"></el-option>
                <el-option label="Active" value="ACTIVE"></el-option>
                <el-option label="Inactive" value="INACTIVE"></el-option>
                <el-option label="Archive" value="ARCHIVED"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6">
            <el-form-item label="Is Popular">
              <el-checkbox v-model="locationInformationData.is_popular" name="type"></el-checkbox>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="Phone">
              <el-time-select
                placeholder="Start time"
                v-model="locationInformationData.open_time"
                :picker-options="{
                  start: '00:00',
                  step: '00:05',
                  end: '23:55'
                }">
              </el-time-select>
              <el-time-select
                style="margin-left: 5px;"
                placeholder="End time"
                v-model="locationInformationData.close_time"
                :picker-options="{
                  start: '00:05',
                  step: '00:05',
                  end: '23:55',
                  minTime: locationInformationData.open_time
                }">
              </el-time-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6">
            <!-- <el-form-item> -->
              <el-button
                type="primary" @click="updateData">{{ buttonText }}</el-button>
            <!-- </el-form-item> -->
          </el-col>
        </el-row>
      </el-form>
    </el-scrollbar>
  </div>
</template>

<script>
import { $adminAxios } from '../../../config/axios'
export default {
  components: {
  },
  props: [
    'locationInformation',
    'locationId',
  ],
  data() {
    return {
      locationInformationData: {},
      locationsLoading: false,
      locationSearchString: null,
    }
  },
  methods: {
    updateData() {
      this.$emit('updateData', this.locationInformationData)
    },
  },
  computed: {
    buttonText() {
      return this.locationId ? 'Update Location' : 'Create Location'
    },
  },
  watch: {
    locationInformation(val) {
      this.locationInformationData = JSON.parse(JSON.stringify(val))
    },
  }
}
</script>

<style lang="scss" scoped>
.customer-details-scroll {
  position: relative;
  height: calc(100vh - 120px);
  margin-top: 10px;
}
</style>
