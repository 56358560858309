import store from "@/store";

import view404 from "@/views/view404";
import vendorMainIndex from "@/views/vendorMainIndex";

// import ordersView from "@/views/orders";
import vendorOrdersDashboardView from "@/views/vendorOrdersDashboard";
import vendorOrdersHistoryView from "@/views/vendorOrdersHistory";
import vendorOrdersStatsView from "@/views/vendorOrdersStats";
import vendorOrdersMainView from "@/views/vendorOrdersMainView";
import vendorSingleOrderView from "@/views/vendorSingleOrder";
import signInComponent from "@/components/admin/signIn";

import {
  doesUserHasVendorAccess as doesUserCanAccess,
} from '@/middleware/auth'

export default {
  path: "/vendor",
  component: vendorMainIndex,
  children: [
    {
      path: "/",
      redirect: "orders-main"
    },
    {
      path: "orders-main",
      name: "vendorOrdersMain",
      component: vendorOrdersMainView,
      beforeEnter: doesUserCanAccess
    },
    {
      path: "orders",
      name: "vendorOrders",
      component: vendorOrdersDashboardView,
      beforeEnter: doesUserCanAccess
    },
    {
      path: "orders-history",
      name: "vendorOrdersHistory",
      component: vendorOrdersHistoryView,
      beforeEnter: doesUserCanAccess
    },
    {
      path: "stats",
      name: "vendorDeliveredOrdersStats",
      component: vendorOrdersStatsView,
      beforeEnter: doesUserCanAccess
    },
    {
      path: "orders/:order_id",
      name: "vendorSingleOrder",
      component: vendorSingleOrderView,
      beforeEnter: doesUserCanAccess
    },
    {
      path: "signin",
      name: "signin",
      component: signInComponent
    }
  ]
};
