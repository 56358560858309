<template>
  <div class="main-body" v-loading="vendorLoading">
      <div style="margin-left: 20px;">
        <el-scrollbar class="customer-details-scroll">
          <el-row>
            <h3 style="margin-bottom: 20px;">Vendor Information</h3>
            <vendor-main-information-update @updateData="updateVendormainInformation" :vendorId="vendorId" :vendorInformation="vendorInformation" />
          </el-row>
        </el-scrollbar>
      </div>
  </div>
</template>

<script>
import { $adminAxios } from '../config/axios'
import moment from 'moment'

import vendorMainInformationUpdate from '@/components/admin/vendorUpdate/vendorMainInformationUpdate.vue'

export default {
  components: {
    vendorMainInformationUpdate,
  },
  data() {
    return {
      vendorInformation: { },
      metaDataInformation: {},
      vendor: {},
      vendorLoading: true,
      firstTab: "images",
      vendorId: null,
    }
  },
  mounted() {
  },
  methods: {
    async createData (data) {
      this.vendorLoading = true

      const url = `/vendors`

      try {
        const response = await $adminAxios.post(url, data)

        const vendorDataInDb = response.data.data

        this.vendorId = vendorDataInDb.auto_id
        this.vendor = vendorDataInDb

        this.vendorInformation = this.vendor

        this.$notify.success({
          title: 'Success',
          message: 'Successfully created vendor',
          type: 'success'
        });

        this.$router.push(`/admin/vendors/${vendorDataInDb.auto_id}`)
      } catch (err) {
        console.error(err)
        this.$notify.error({
          title: 'Error',
          message: err.message || 'Something went wrong',
          type: 'error'
        });
      }

      this.vendorLoading = false
    },
    async updateVendormainInformation (data) {
      if (this.vendorId) {
        await this.updateData(data, 'mainVendorInformation')
      } else {
        await this.createData(data)
      }
    },
    async updateData (data, updateType) {
      const vendorId = this.vendorId

      this.vendorLoading = true

      let url
      if (updateType === 'mainVendorInformation') {
        url = `/vendors/${vendorId}/main-vendor-information`
      }

      try {
        await $adminAxios.patch(url, data)

        this.$notify.success({
          title: 'Success',
          message: 'Successfully updated vendor',
          type: 'success'
        });
      } catch (err) {
        console.error(err)
        this.$notify.error({
          title: 'Error',
          message: err.message || 'Something went wrong',
          type: 'error'
        });
      }

      this.vendorLoading = false
    },
  }
}
</script>

<style lang="scss" scoped>
.customer-details-scroll {
  position: relative;
  height: calc(100vh - 75px);
  margin-top: 10px;
}
</style>
