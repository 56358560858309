<template>
  <div>
    <el-scrollbar class="customer-details-scroll">
      <el-form label-position="left" label-width="100px" :model="productInformationData" >
        <el-row>
          <el-col :span="6">
            <el-form-item label="Title">
              <el-input v-model="productInformationData.title"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-if="productId">
          <el-col :span="6">
            <el-form-item label="URL">
              <el-input v-model="productInformationData.url"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6">
            <el-form-item label="Location">
              <el-select
                v-model="productInformationData.location_id"
                :loading="locationsLoading"
                remote
                :remote-method="fetchLocations"
                filterable
                placeholder="Select Location"
                style="display: block;"
              >
                <el-option v-for="location of locations" :key="location.auto_id" :label="location.title" :value="location.auto_id"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <!-- <el-row>
          <el-col :span="6">
            <el-form-item label="Vendor">
              <el-select
                v-model="productInformationData.vendor_id"
                :loading="vendorsLoading"
                remote
                :remote-method="fetchVendors"
                filterable
                placeholder="Select Vendor"
                style="display: block;"
              >
                <el-option v-for="vendor of vendors" :key="vendor.auto_id" :label="vendor.title" :value="vendor.auto_id"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row> -->
        <el-row>
          <el-col :span="12">
            <el-form-item label="Categories">
              <el-select
                v-model="productInformationData.categories"
                multiple
                filterable
                default-first-option
                placeholder="Select Categories"
                style="display: block"
              >
                <el-option v-for="category of categories" :key="category.auto_id" :label="category.title" :value="category.auto_id"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6">
            <el-form-item label="Main Category Id">
              <el-select
                v-model="productInformationData.main_category_id"
                filterable
                placeholder="Select Main Category"
                default-first-option
              >
                <el-option v-for="category of categories" :key="category.auto_id" :label="category.title" :value="category.auto_id"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6">
            <el-form-item label="Product Type">
              <el-select
                v-model="productInformationData.product_type"
                filterable
                placeholder="Select Product Type"
              >
                <el-option v-for="productType of productTypes" :key="productType" :label="productType" :value="productType"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6">
            <el-form-item label="Status">
              <el-select v-model="productInformationData.status" placeholder="Select Status">
                <el-option label="Active" value="ACTIVE"></el-option>
                <el-option label="Draft" value="DRAFT"></el-option>
                <el-option label="Archive" value="ARCHIVED"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6">
            <el-form-item label="Can Eggless">
              <el-checkbox v-model="productInformationData.can_eggless" name="type"></el-checkbox>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6">
            <el-form-item label="Stock Availability">
              <el-checkbox v-model="productInformationData.stock_availability" name="type"></el-checkbox>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6">
            <el-form-item label="SKU">
              <el-input v-model="productInformationData.sku"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6">
            <el-form-item label="Rating">
              <el-input v-model="productInformationData.total_rating"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6" style="margin-left: 10px">
            <el-form-item label="Total Votes">
              <el-input v-model="productInformationData.total_votes"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6">
            <el-form-item label="Status">
              <el-select v-model="productInformationData.main_tag" placeholder="Select Status">
                <el-option label="None" value=""></el-option>
                <el-option label="Best Seller" value="BEST_SELLER"></el-option>
                <el-option label="Premium" value="PREMIUM"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6">
            <el-form-item label="Earliest Delivery">
              <el-select v-model="productInformationData.earliest_delivery" placeholder="Select">
                <el-option label="Today" value="TODAY"></el-option>
                <el-option label="Tomorrow" value="TOMORROW"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-button @click="addDescriptionItem()" style="margin-top: 10px;">Add Description Item</el-button>

        <el-row v-for="(item, index) of newDescription" :key="index" style="margin-top: 20px;">
          <el-col :span="6">
            <el-form-item label="Sub Title">
              <el-input v-model="newDescription[index].title"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" style="margin-left: 10px;">
            <el-form-item label="Description">
              <el-input type="textarea" rows="6" v-model="newDescription[index].description"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="2">
            <el-button style="color: red;" @click="deleteNewDescriptionRow(index)" type="text" size="small">Delete</el-button>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="12">
            <el-form-item label="Delivery Information">
              <el-checkbox-group v-model="selectedDeliveryInformations" >
                <el-checkbox label="Cake"></el-checkbox>
                <el-checkbox label="Flowers"></el-checkbox>
              </el-checkbox-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="Care Instructions">
              <el-checkbox-group v-model="selectedCareInstructions" >
                <el-checkbox label="Cake"></el-checkbox>
                <el-checkbox label="Flowers"></el-checkbox>
              </el-checkbox-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="Please Note">
              <el-checkbox-group v-model="selectedPleaseNotes" >
                <el-checkbox label="Cake"></el-checkbox>
                <el-checkbox label="Flowers"></el-checkbox>
              </el-checkbox-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="Extra Description">
              <el-input type="textarea" rows="6" v-model="productInformationData.extra_description"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6">
            <el-form-item>
              <el-button
                :disabled="productLoading" type="primary" @click="updateData"
              >
                {{ buttonText }}
              </el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-scrollbar>
  </div>
</template>

<script>
// import { Editor } from '@toast-ui/vue-editor';
import { $adminAxios } from '../../../config/axios'
export default {
  components: {
    // editor: Editor
  },
  props: [
    'productInformation',
    'productId',
    'productLoading',
  ],
  data() {
    return {
      selectedDeliveryInformations: [],
      selectedCareInstructions: [],
      selectedPleaseNotes: [],
      newDescription: [{ title: 'Product Details', descriptions: [] }],
      productInformationData: { status: 'DRAFT', stock_availability: true },
      locations: [],
      locationsLoading: false,
      locationSearchString: null,
      // vendors: [],
      // vendorsLoading: false,
      // vendorSearchString: null,
      categories: [],
      categoriesLoading: false,
      categorySearchString: null,
      selectedCategories: [],
      productTypes: ['cakes', 'chocolates', 'flowers', 'combo', 'gifts'],
    }
  },
  async mounted() {
    await this.fetchCategories()
  },
  methods: {
    updateData() {
      this.productInformationData.delivery_information_description = this.selectedDeliveryInformations
      this.productInformationData.care_instructions_description = this.selectedCareInstructions
      this.productInformationData.please_note_description = this.selectedPleaseNotes
      this.productInformationData.new_description = this.newDescription

      this.$emit('updateData', this.productInformationData)
    },
    addDescriptionItem() {
      this.newDescription.push({ title: null, descriptions: [] })
    },
    async fetchLocations (str) {
      this.locationsLoading = true
      this.locationSearchString = str
      try {
        let url = '/locations?get_all=true&minimal=true'
        if (str) {
          url = url + '&title=' + str
        }

        const response = await $adminAxios.get(url)
        if (str === this.locationSearchString) {
          this.locations = response.data.data
        }
      } catch (err) {
        // nothing
      }
      this.locationsLoading = false
    },
    // async fetchVendors (str) {
    //   this.vendorsLoading = true
    //   this.vendorSearchString = str
    //   try {
    //     let url = `/vendors?get_all=true&minimal=true&location_id=${this.productInformationData.location_id}`
    //     if (str) {
    //       url = url + '&title=' + str
    //     }

    //     const response = await $adminAxios.get(url)
    //     if (str === this.vendorSearchString) {
    //       this.vendors = response.data.data
    //     }
    //   } catch (err) {
    //     // nothing
    //   }
    //   this.vendorsLoading = false
    // },
    async fetchCategories () {
      try {
        const url = '/categories?get_all=true&minimal=true'

        const response = await $adminAxios.get(url)
        this.categories = response.data.data
      } catch (err) {
        // nothing
      }
    },
    deleteNewDescriptionRow(index) {
      console.log('hi')
      this.newDescription.splice(index, 1)
    },
  },
  computed: {
    buttonText() {
      return this.productId ? 'Update Product' : 'Create Product'
    },
  },
  watch: {
    productInformation(val) {
      if (val) {
        this.productInformationData = JSON.parse(JSON.stringify(val))

        this.selectedDeliveryInformations = val.delivery_information_description || []
        this.selectedCareInstructions = val.care_instructions_description || []
        this.selectedPleaseNotes = val.please_note_description || []
        this.newDescription = val.new_description || [{ title: null, descriptions: [] }]

        if (val.location_data && val.location_data.auto_id) {
          this.locations = [val.location_data]
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.customer-details-scroll {
  position: relative;
  height: calc(100vh - 120px);
  margin-top: 10px;
}
</style>
