import { $adminAxios, $vendorAxios, $UpdateAuthorizationTokens } from "../../config/axios";

import {
  saveAccessTokenToLocalStorage,
  clearUserLocalStorage,
} from "@/middleware/auth";

// initial state
const state = {
  authenticatedUser: false,
  countries: [],
  profile: {},
  signInErrorMessage: null,
};

const getters = {
  isAuthenticated: state => state.authenticatedUser,
  getUserProfileData: state => state.profile,
  getSignInErrorMessage: state => state.signInErrorMessage,
};

const mutations = {
  SET_AUTH_STATUS(state, status) {
    state.authenticatedUser = status;
  },
  SET_USER_DATA(state, userData) {
    state.profile = userData;
  },
  SET_SIGN_IN_ERROR_MESSAGE(state, data) {
    state.signInErrorMessage = data;
  },
};

const actions = {
  signIn: async (context, { email, password }) => {
    try {
      const $axios = getAxiosFile()
      const response = await $axios.post("/signin", { email, password });

      const userProfile = response.data.data

      saveAccessTokenToLocalStorage(response.data);
      $UpdateAuthorizationTokens()

      context.commit("auth/SET_AUTH_STATUS", true, { root: true });
      context.commit("auth/SET_USER_DATA", userProfile, { root: true });
      context.commit("auth/SET_SIGN_IN_ERROR_MESSAGE", null, { root: true });
    } catch (err) {
      console.error(err)
      context.commit("auth/SET_SIGN_IN_ERROR_MESSAGE", getMessageFromError(err), { root: true });
    }
  },
  fetchUserProfile: async (context,) => {
    try {
      const axios = getAxiosFile()

      const response = await axios.get("/profile");

      const { user_details } = response.data;

      context.commit("auth/SET_AUTH_STATUS", true, { root: true });
      context.commit("auth/SET_USER_DATA", user_details, { root: true });

      return { user_details };
    } catch (err) {
      console.error(err)
      context.commit("auth/SET_SIGN_IN_ERROR_MESSAGE", getMessageFromError(err), { root: true });
    }
  },
  redirectedUserSignOut(context) {
    context.commit("SET_AUTH_STATUS", false);
    localStorage.clear();
  },
  signOut (context) {
    clearUserLocalStorage();
    $UpdateAuthorizationTokens()

    context.commit("auth/SET_USER_DATA", null, { root: true });
    context.commit("auth/SET_AUTH_STATUS", false, { root: true });
  },
};

function getAxiosFile () {
  const currentRoute = location.pathname
  const isVendorSite = location.hostname === 'partner.cakewya.in' || currentRoute.startsWith('/vendor')

  return isVendorSite ? $vendorAxios : $adminAxios
}

function getMessageFromError(err) {
  return (err.response && err.response.data && err.response.data.message) || 'Something went wrong'
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
