<template>
  <div class="main-body">
    <el-scrollbar class="customer-details-scroll">
      <div class="" style="margin-top: 15px;">
        <el-row>
          <el-col :xs="12" :sm="4" :md="4" :lg="3" style="margin-left: 10px">
            <el-input v-model="searchString" placeholder="Search" clearable=""></el-input>
          </el-col>
          <el-col :xs="12" :sm="4" :md="4" :lg="2">
            <el-button @click="searchData">Search</el-button>
          </el-col>
          <el-col :sm="12" :md="6" :lg="3" :xl="2">
            <el-button @click="createCategory">Add Category</el-button>
          </el-col>
        </el-row>
      </div>
      <div class="">
        <el-table
          v-loading="categoriesLoading"
          class="table"
          :lazy="categoriesLoading"
          :data="categories"
          style="width: 100%; text-align: left; "
          @sort-change="sortChange">
          <el-table-column
            label="Title"
            prop="title"
            sortable>
            <template slot-scope="{ row }">
              <el-link :href="getCategoryUrl(row.auto_id)" >{{ row.title }}</el-link>
            </template>
          </el-table-column>
          <el-table-column
            label="Status"
            prop="status">
          </el-table-column>
          <el-table-column
            label="URL"
            prop="url">
          </el-table-column>
        </el-table>
      </div>
      <div style="margin-top: 20px; text-align: center;">
        <el-pagination
          @current-change="handlePageChange"
          @size-change="handlePageSizeChange"
          :current-page.sync="page"
          :page-sizes="[30, 40 ,50]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="totalCategoriesCount">
        </el-pagination>
      </div>
    </el-scrollbar>
  </div>
</template>

<script>
import { $adminAxios } from '../config/axios'
import priceMixin from '../mixins/priceMixin'
import moment from 'moment'

export default {
  name: 'Categories',
  mixins: [
    priceMixin,
  ],
  data: function () {
    return {
      defaultTableProps: {
        size: "mini",
        border: true,
      },
      columns: [{
        prop: 'title',
        label: 'Title',
        required: true,
      }],
      categories: [],
      totalCategoriesCount: 0,
      categoriesLoading: true,
      page: 1,
      pageSize: 30,
      searchString: '',
      orderBy: 'title',
      orderType: 'asc',
    }
  },
  computed: {
    computedTableProps() {
      return {
        ...this.defaultTableProps,
        ...(this.tableProps || {}),
      };
    },
  },
  mounted() {
    this.fetchCategories()
  },
  methods: {
    getCategoryUrl(categorId) {
      return `/admin/categories/${categorId}`
    },
    async fetchCategories (page, limit) {
      page = page || this.page
      limit = limit || this.pageSize

      this.categoriesLoading = true

      try {
        let queryParamsString = `page=${page}&limit=${limit}&status[]=ACTIVE&status[]=INACTIVE`

        if (this.searchString) {
          queryParamsString += `&search_string=${this.searchString}`
        }
        if (this.orderBy && this.orderType) {
          queryParamsString += `&order_type=${this.orderType}&order_by=${this.orderBy}`
        }

        const response = await $adminAxios.get(`/categories?${queryParamsString}`)
        this.categories = response.data.data
        this.totalCategoriesCount = response.data.total
      } catch (err) {
        console.error(err)
        this.$notify.success({
          title: 'Error',
          message: err.message || 'Something went wrong',
          type: 'error'
        });
      }

      this.categoriesLoading = false
    },
    getRowValue(object, columnObject) {
      const path = columnObject.prop;
      const type = columnObject.type;

      const value =
        typeof this.$lodash.result(object, path) === "undefined"
          ? "--"
          : this.$lodash.result(object, path);

      if (type === "date") {
        const valueTemp =
          value && value !== "--"
? {
              type: "string",
              value: moment.utc(new Date(value)).format('yyyy-mm-dd'),
            }
: { type: "string", value: "--" };
        return valueTemp;
      }
      if (Array.isArray(value)) {
        return { type: "array", value };
      }
      return { type: "string", value };
    },
    handlePageChange(page) {
      this.fetchCategories(page, this.size)
    },
    handlePageSizeChange(size) {
      this.fetchCategories(this.page, size)
    },
    createCategory() {
      this.$router.push('/admin/categories/create')
    },
    searchData() {
      this.page = 1
      this.fetchCategories()
    },
    sortChange(el) {
      this.orderBy = el.prop
      this.orderType = el.order === 'ascending' ? 'asc' : 'desc'
      this.page = 1
      this.fetchCategories()
    },
  },
}
</script>

<style lang="scss" scoped>
.customer-details-scroll {
  position: relative;
  height: calc(100vh - 75px);
  margin-top: 10px;
}
</style>
