<template>
  <div class="main-body">
    <div style="margin-left: 20px">
      <el-scrollbar class="customer-details-scroll">
        <el-row>
          <h3 style="margin-bottom: 20px">Order Information - {{ orderId }}</h3>

          <main class="col-md-9">
            <article class="card">
              <div class="card-header">
                <div class="d-flex justify-content-between align-items-center mb-10">
                  <div class="">
                    <strong class="d-inline-block mr-3">Order ID: {{ order.auto_id }}</strong>
                  </div>
                </div>
                <div class="d-flex justify-content-between align-items-center mb-10">
                  <div class="">
                    <span><strong>Delivery Date:</strong> {{ orderDeliveryDate(order) }} </span>
                  </div>
                </div>
                <div class="d-flex justify-content-between align-items-center">
                  <div class="">
                    <span><strong>Order Status: </strong>
                      <el-select @change="updateOrderStatus('status')" v-model="orderStatusTypesData.status" placeholder="Select">
                        <el-option
                          v-for="item in orderStatuses"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value">
                        </el-option>
                      </el-select>
                    </span>
                  </div>
                </div>
                <div class="" v-if="order.status === 'cancelled'">
                  <b>Cancelled Reason:</b> {{ order.cancelled_reason }}
                </div>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-md-6 col-sm-6">
                    <h4 class="text-muted">Delivery Address</h4>
                    <p>
                      {{ shippingAddress.name }} <br />
                      Address: {{ shippingAddress.line1 }} <br />
                      Landmark: {{ shippingAddress.landmark }} <br />
                      Pincode: {{ shippingAddress.pincode }} <br />
                      City: {{ shippingAddress.city }} <br />
                      Phone: {{ shippingAddress.phone }} <br />
                      Alternate Phone: {{ shippingAddress.alternative_phone || '--' }} <br />
                    </p>
                  </div>
                  <div class="col-md-6 col-sm-6">
                    <h4 class="text-muted">Sender Details</h4>
                    <p>
                      Sender Name: {{ order.sender_name }}
                      <!-- <br/> -->
                      <!-- Sender Phone: {{ order.sender_phone }} -->
                    </p>

                    <h4 class="text-muted">Payment</h4>
                    <p>
                      Subtotal: &#8377;{{order.vendor_sub_total_price}} <br />
                      Shipping fee: &#8377;{{order.vendor_shipping_price || 0}} <br />
                      <span class="b">Total: &#8377;{{order.vendor_total_price}} </span><br/>
                    </p>
                  </div>
                </div>
              </div>
              <div class="table-responsive"
                  style="border-top: 1px solid rgba(0,0,0,.125);">
                <table class="table">
                  <tbody>
                    <tr v-for="(item, index) of order.items" :key="index">
                      <td width="65">
                        <a :href="getProductUserUrl(item.product_details.url)" target="_blank">
                          <img
                            :src="getProductFirstImageUrlFromImages(item.product_details.images)"
                            class="img-xs border"
                          />
                        </a>
                      </td>
                      <td>
                        <p class="title mb-0">{{ (item.product_details && item.product_details.title) || '' }} </p>
                        <var class="price text-muted">&#8377; {{ item && item.product_variant && item.product_variant.bakery_price + ( item.product_variant.extra_variant_cost || 0 ) }}, {{ individualPrice(item.auto_id) }}</var>
                      </td>
                      <td style="line-height: 1.5">
                        <span v-if="item.variant_details.Size && item.product_details.product_type !== 'gifts'">
                          {{ getOptions().labelConstants['Size'][item.product_details.product_type] }}: {{ item.product_variant._so.Size }}<br />
                        </span>
                        <span v-if="item.product_details.product_type === 'cakes' || item.has_cake">
                          Message On Cake: {{ item.message_on_cake || '' }} <br />
                          Eggless: {{ item.egg_less ? 'Yes' : 'No' }} <br />
                        </span>
                        Quantity: {{ item.quantity || 1 }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- table-responsive .end// -->
            </article>
          </main>
        </el-row>
      </el-scrollbar>
    </div>
  </div>
</template>

<script>
import { $adminAxios, $vendorAxios } from "../config/axios";
import CONFIG from '@/config/app'
import moment from "moment";
import errorHandlerMixin from "@/mixins/errorHandlerMixin";

export default {
  mixins: [
    errorHandlerMixin,
  ],
  computed: {
    shippingAddress() {
      return (this.order && this.order.shipping_address) || {}
    },
    billingAddress() {
      return (this.order && this.order.billing_address) || {}
    },
    orderDeliveryDate() {
      return order => {
        const deliveryDateInDiffFormat = order.delivery_date_string && order.delivery_date_string.split('-').reverse().join('-')
        return `${deliveryDateInDiffFormat}, ${order.delivery_time_slot_name} (${order.delivery_time_slot})`
      }
    },
    orderPlacedDate() {
      return order => {
        return moment(order.created_at).format('MMM DD, yy hh:mm A')
      }
    },
    getFullImageUrl() {
      return url => {
        return url ? `${this.underscoreConfig.s3ProductImagesBaseUrl}/${url}` : `${this.underscoreConfig.s3ProductImagesBaseUrl}/${this.underscoreConfig.productSampleUrl}`
      }
    },
    individualPrice() {
      return itemId => {
        const item = this.order.items.find(el => el.auto_id === itemId)
        let cost = (item.quantity || 1) * (item.product_variant.bakery_price + ( item.product_variant.extra_variant_cost || 0 ) )

        if (item.egg_less) {
          const eggLessCost = Number((item.product_variant._so.Size).split(/kg/i)[0]) * 100 // todo update eggless cost from vendor data
          cost += (item.quantity || 1) * eggLessCost
        }

        return cost
      }
    },
  },
  data: function () {
    return {
      orderId: null,
      order: {},
      mainLoading: true,
      s3ProductsBaseUrl: CONFIG.PRODUCT_IMAGES_URL,
      productSampleUrl: CONFIG.product_images_sample_image_url,
      underscoreConfig: {
        imagesBaseUrl: CONFIG.PRODUCT_IMAGES_URL,
        s3ProductImagesBaseUrl: CONFIG.PRODUCT_IMAGES_URL,
        productSampleUrl: CONFIG.product_images_sample_image_url,
      },
      orderStatuses: [{
        value: 'open',
        label: 'Not Acknowledged',
      }, {
        value: 'processed',
        label: 'Accepted',
      }, {
        value: 'out_for_delivery',
        label: 'Out For Delivery',
      }, {
        value: 'delivered',
        label: 'Delivered',
      }, {
        value: 'cancelled',
        label: 'Cancelled',
      }],
      orderPaymentStatuses: ['pending', 'authorized', 'paid', 'partially_paid', 'refunded', 'partially_refunded', 'voided', 'failed', 'cancelled'],
      orderStatusTypesData: {},
      paymentEdit: false,
      orderStatusInRazorPay: '',
    };
  },
  mounted() {
    this.orderId = this.$route.params.order_id;
    this.fetchOrder();
  },
  methods: {
    getProductImagesFullUrl (url) {
      return `${this.underscoreConfig.imagesBaseUrl}/${url || this.productSampleUrl}`
    },
    getProductFirstImageUrlFromImages (images) {
      return this.getProductImagesFullUrl(images && images[0] && images[0].src)
    },
    getProductUserUrl(productUrl) {
      console.log(CONFIG)
      return CONFIG.USER_WEBSITE_URL + '/gift/' + productUrl
    },
    getOptions() {
      return {
        labelConstants: {
          Size: {
            cakes: 'Weight',
            flowers: 'Units',
            combos: 'Units',
            chocolates: 'Units',
          },
        }
      }
    },
    toTitleCase (phrase = '') {
      return phrase
        .toLowerCase()
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ')
    },
    async fetchOrder(data) {
      this.mainLoading = true;

      const url = `/orders/${this.orderId}`;

      try {
        const response = await $vendorAxios.get(url, data);

        const orderData = response.data.data;

        this.order = orderData;
        this.orderStatusTypesData = {
          financial_status: this.order.financial_status,
          status: this.order.status,
        }
      } catch (err) {
        this.ehm__errorMessages(err, true);
      }

      this.mainLoading = false;
    },
    async updateOrderDetails(url, data) {
      this.mainLoading = true;

      try {
        await $vendorAxios.patch(url, data);

        this.$notify.success({
          title: "Success",
          message: "Successfully updated order details",
          type: "success",
        });
      } catch (err) {
        this.ehm__errorMessages(err, true);
      }

      this.mainLoading = false;
    },
    async updateOrderStatus(statusType) {
      const data = {
        status_type: statusType,
        status_value: this.orderStatusTypesData[statusType]
      }

      const url = `/orders/${this.orderId}/status`;

      this.updateOrderDetails(url, data)
    },
    paymentHideAndEnable(status) {
      this.paymentEdit = status
    },
    getDateForCopy(order) {
      return moment.utc(order.delivery_date_string).format('DD MMM, yyyy')
    },
    copyMarkup() {
      const htmlElement = this.$refs.orderMarkUp;

      let data = ''

      htmlElement.childNodes.forEach((el, index) => {
        if (el.tagName === 'BR') {
          data += '\n'
        } else {
          data += index > 0 ? '\n' + el.textContent : el.textContent
        }
      })

      navigator.clipboard.writeText(data);
      this.$notify.success({
        title: "Success",
        message: "Copied",
        type: "success",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.customer-details-scroll {
  position: relative;
  height: calc(100vh - 75px);
  margin-top: 10px;
}

td img {
  width: 75px;
}

.d-flex {
  display: flex;
}

.card-header {
  padding: 0.75rem 1.25rem !important;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.card-header {
  padding: 0.75rem 1.25rem !important;
}

.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-body {
  flex: 1 1 auto;
  padding: 1rem 1rem;
}

p {
  color: #333;
}
p {
  line-height: 30px;
}
p {
  margin-top: 0;
  margin-bottom: 1rem;
}

table {
  width: 100%;
}

.table > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
  background-color: var(--bs-table-bg);
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}

.align-items-center {
  align-items: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}
</style>

<style>
.mb-0 {
  margin-bottom: 0;
}
.mb-10 {
  margin-bottom: 10px;
}
.mb-20 {
  margin-bottom: 20px;
}

.alert-success {
    color: #3c763d;
    background-color: #dff0d8;
    border-color: #d6e9c6;

}
.alert-success p {
  color: #3c763d;
  margin-bottom: 0px;
}
.alert {
    padding: 15px;
    margin-bottom: 20px;
    border: 1px solid transparent;
    border-radius: 4px;
}
.pointer {
  cursor: pointer;
}
</style>
