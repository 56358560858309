<template>
  <div class="main-body">
    <el-scrollbar class="customer-details-scroll">
    <div class="">
      <el-row style="margin-top: 15px;">
        <el-col :xs="12" :sm="4" :md="4" :lg="2">
          <el-button @click="createProduct">Add Product</el-button>
        </el-col>
        <el-col :xs="12" :sm="4" :md="4" :lg="2">
          <el-select @change="changeCity(locationId)" v-model="locationId" filterable placeholder="Select City">
            <el-option
              v-for="city in cities"
              :key="city.auto_id"
              :label="city.title"
              :value="city.auto_id">
            </el-option>
          </el-select>
        </el-col>
        <el-col :xs="12" :sm="4" :md="4" :lg="3">
          <el-input v-model="productSearchString" placeholder="Search Product" clearable=""></el-input>
        </el-col>
        <el-col :xs="12" :sm="4" :md="4" :lg="2">
          <el-button @click="searchProduct">Search</el-button>
        </el-col>
      </el-row>
    </div>
    <br/>
    <div class="">
      <el-table
        v-loading="productsLoading"
        class="table"
        :lazy="productsLoading"
        :data="products"
        style="width: 100%; text-align: left; ">
        <el-table-column
          label="Title"
          prop="title">
          <template slot-scope="{ row }">
            <img v-if="row.images && row.images.length" :src="getFullImageUrl(row.images[0].src)" style="width: 60px; height: 60px;" />
            <img v-else :src="getFullImageUrl('sample-image.jpg')" style="width: 60px; height: 60px;" />
          </template>
        </el-table-column>
        <el-table-column
          label="Title"
          prop="title">
          <template slot-scope="{ row }">
            <el-link :href="getProductUrl(row.auto_id)" >{{ row.title }}</el-link>
          </template>
        </el-table-column>
        <el-table-column
          label="City Or Location"
          prop="location_data">
          <template slot-scope="{ row }">
            <p>{{ row.location_data && row.location_data.auto_id ? row.location_data.title : 'NA' }} </p>
          </template>
        </el-table-column>
        <el-table-column
          label="Status"
          prop="status">
        </el-table-column>
        <el-table-column
          label="Stock Availability"
          >
          <template slot-scope="{ row }">
            {{ row.stock_availability ? 'Yes': 'No' }}
          </template>
        </el-table-column>
        <el-table-column
          label="price"
          >
          <template slot-scope="{ row }">
            {{ addSymbol((row.default_variant && row.default_variant.price) || 0) }}
          </template>

        </el-table-column>
        <el-table-column
          label="Sale Price">
          <template slot-scope="{ row }">
            {{ addSymbol((row.default_variant && row.default_variant.sale_price) || 0) }}
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div style="margin-top: 20px; text-align: center;">
      <el-pagination
        @current-change="handlePageChange"
        @size-change="handlePageSizeChange"
        :current-page.sync="page"
        :page-sizes="[30, 40 ,50]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalProductsCount">
      </el-pagination>
    </div>
    </el-scrollbar>
  </div>
</template>

<script>
import CONFIG from '@/config/app'
import { $adminAxios } from '../config/axios'
import priceMixin from '../mixins/priceMixin'
import moment from 'moment'

export default {
  name: 'Products',
  mixins: [
    priceMixin,
  ],
  data: function () {
    return {
      cities: [{
        title: 'Default',
        auto_id: 10000,
      }],
      defaultTableProps: {
        size: "mini",
        border: true,
      },
      columns: [{
        prop: 'title',
        label: 'Title',
        required: true,
      }],
      products: [],
      totalProductsCount: 0,
      productsLoading: true,
      page: 1,
      pageSize: 30,
      s3ProductsBaseUrl: CONFIG.PRODUCT_IMAGES_URL,
      locationId: 10000,
      productSearchString: null,
    }
  },
  computed: {
    computedTableProps() {
      return {
        ...this.defaultTableProps,
        ...(this.tableProps || {}),
      };
    },
    getFullImageUrl() {
      return url => {
        return `${this.s3ProductsBaseUrl}/120x120/${url}`
      }
    },
  },
  mounted() {
    const previousLocationId = localStorage.getItem('productCityId')

    if (previousLocationId) {
      this.locationId = Number(previousLocationId)
    }

    this.fetchProducts(null, null, previousLocationId || undefined)
  },
  methods: {
    getProductUrl(productId) {
      return `/admin/template-products/${productId}`
    },
    changeCity(locationId) {
      localStorage.setItem('productCityId', locationId)
      this.fetchProducts(null, null, locationId)
    },
    async fetchProducts (page, limit, locationId = this.locationId) { // todo we need refactor location type filters
      page = page || this.page
      limit = limit || this.pageSize

      this.productsLoading = true

      try {
        let queryParamsString = `page=${page}&limit=${limit}&location_id=${locationId}`

        if (this.productSearchString) {
          queryParamsString += `&search_string=${this.productSearchString}`
        }

        const response = await $adminAxios.get(`/template-products?${queryParamsString}`)
        this.products = response.data.data
        this.totalProductsCount = response.data.total
      } catch (err) {
        console.error(err)
        this.$notify.success({
          title: 'Error',
          message: err.message || 'Something went wrong',
          type: 'error'
        });
      }

      this.productsLoading = false
    },
    getRowValue(object, columnObject) {
      const path = columnObject.prop;
      const type = columnObject.type;

      const value =
        typeof this.$lodash.result(object, path) === "undefined"
          ? "--"
          : this.$lodash.result(object, path);

      if (type === "date") {
        const valueTemp =
          value && value !== "--"
? {
              type: "string",
              value: moment.utc(new Date(value)).format('yyyy-mm-dd'),
            }
: { type: "string", value: "--" };
        return valueTemp;
      }
      if (Array.isArray(value)) {
        return { type: "array", value };
      }
      return { type: "string", value };
    },
    handlePageChange(page) {
      this.fetchProducts(page, this.size)
    },
    handlePageSizeChange(size) {
      this.fetchProducts(this.page, size)
    },
    createProduct() {
      this.$router.push('/admin/template-products/create')
    },
    searchProduct() {
      this.fetchProducts(null, null)
    },
  },
}
</script>

<style lang="scss" scoped>
.customer-details-scroll {
  position: relative;
  height: calc(100vh - 75px);
  margin-top: 10px;
}
</style>
