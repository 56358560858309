import Vue from "vue";
import "@/config/elementUi.js";
import App from "./App.vue";
import router from "@/router";
import store from "@/store";

import VTooltip from "v-tooltip";
import { cloneDeep, get, result, capitalize, debounce, throttle } from "lodash";

// global plugins space
Vue.use(VTooltip);

// global variables space
Vue.config.productionTip = false;
Vue.prototype.$lodash = {
  cloneDeep,
  get,
  result,
  capitalize,
  debounce,
  throttle
};

const vueObj = new Vue({
  router,
  store,
  render: h => h(App)
});

vueObj.$mount("#app");
