<template>
  <div class="main-body">
    <h3>Orders History</h3>
    <template>
      <el-row style="padding: 10px;">
        <el-col :xs="12" :sm="4" :md="4" :lg="2" v-if="!isUserVendor">
          <el-select v-model="locationId" filterable placeholder="Select City">
            <el-option
              v-for="city in cities"
              :key="city.auto_id"
              :label="city.title"
              :value="city.auto_id">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="5">
          <el-date-picker
            v-model="date"
            type="date"
            format="yyyy-MM-dd"
            placeholder="From Date">
          </el-date-picker>
        </el-col>
        <el-col :span="5">
          <el-date-picker
            v-model="to_date"
            type="date"
            format="yyyy-MM-dd"
            placeholder="To Date">
          </el-date-picker>
        </el-col>
        <el-col :span="5" class="">
          <el-select v-model="statusValue" placeholder="Status">
            <el-option
              v-for="item in orderStatuses"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="2" class="">
          <el-button @click="fetchOrders" type="success">
            Submit
          </el-button>
        </el-col>
      </el-row>
    </template>

    <br/>

    <el-scrollbar v-if="clickedOnce" class="customer-details-scroll" style="margin-top: 10px;">
      <div class="" >
        <el-table
          v-loading="mainLoading"
          class="table"
          :lazy="mainLoading"
          :data="orders"
          style="width: 100%; text-align: left; ">
          <el-table-column
            label="Order Id"
            prop="auto_id">
            <template slot-scope="{ row }">
              <el-link :href="getItemUrl(row.auto_id)" >{{ row.auto_id }}</el-link>
            </template>
          </el-table-column>
          <el-table-column
            v-if="!isUserVendor"
            label="City"
          >
            <template slot-scope="{ row }">
              {{ row.shipping_address.city }}
            </template>
          </el-table-column>
          <el-table-column
            label="Order Status"
            prop="status">
            <template slot-scope="{ row }">
              <el-tag v-if="row.status === 'open'" type="info" effect="dark">Not Acknowledged</el-tag>
              <el-tag v-else-if="row.status === 'processed'" type="warning" effect="dark">Processed</el-tag>
              <el-tag v-else-if="row.status === 'cancelled'" type="danger">Cancelled</el-tag>
              <el-tag v-else-if="row.status === 'delivered'" type="success" effect="dark">Delivered</el-tag>
              <el-tag v-else type="info">{{ row.status }}</el-tag>
            </template>
          </el-table-column>
          <el-table-column
            label="Delivery Date"
            prop="delivery_date">
            <template slot-scope="{ row }">
              {{ orderDeliveryDate(row) }}
            </template>
          </el-table-column>
          <!-- <el-table-column
            label="Order Date"
            prop="created_at">
            <template slot-scope="{ row }">
              {{ dateTimeFormat(row.created_at) }}
            </template>
          </el-table-column> -->
          <el-table-column
            label="Price">
            <template slot-scope="{ row }">
              &#8377;{{ isUserVendor ? row.vendor_sub_total_price : row.sub_total_price }}
            </template>
          </el-table-column>
          <el-table-column
            label="Delivery Charge">
            <template slot-scope="{ row }">
              &#8377;{{ isUserVendor ? row.vendor_shipping_price : row.total_shipping_price }}
            </template>
          </el-table-column>
          <el-table-column
            v-if="!isUserVendor"
            label="Profit After Charges">
            <template slot-scope="{ row }">
              &#8377;{{ isUserVendor ? '--' : Math.round((row.total_price - row.vendor_total_price) * 0.97)  }}
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div style="margin-top: 20px; text-align: center;">
        <el-pagination
          @current-change="handlePageChange"
          @size-change="handlePageSizeChange"
          :current-page.sync="page"
          :page-sizes="[5, 10, 20, 30]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="totalOrdersCount">
        </el-pagination>
      </div>
    </el-scrollbar>
  </div>
</template>

<script>
import { $adminAxios, $vendorAxios } from '../config/axios'
import priceMixin from '../mixins/priceMixin'
import moment from 'moment'

import errorHandlerMixin from '@/mixins/errorHandlerMixin'

export default {
  name: 'Categories',
  mixins: [
    priceMixin,
    errorHandlerMixin,
  ],
  data: function () {
    return {
      defaultTableProps: {
        size: "mini",
        border: true,
      },
      columns: [{
        prop: 'auto_id',
        label: 'Order Id',
        required: true,
      }],
      totalOrdersCount: 0,
      orders: [],
      mainLoading: false,
      page: 1,
      pageSize: 5,
      date: null,
      to_date: null,
      type: null,
      statusType: null,
      statusValue: null,
      orderStatuses: [{
        value: 'open',
        label: 'Not Acknowledged',
      }, {
        value: 'processed',
        label: 'Accepted',
      }, {
        value: 'out_for_delivery',
        label: 'Out For Delivery',
      }, {
        value: 'delivered',
        label: 'Delivered',
      }, {
        value: 'open,processed,out_for_delivery,delivered',
        label: 'Processed, Out For Delivery, Delivered',
      }, {
        value: 'cancelled',
        label: 'Cancelled',
      }],
      clickedOnce: false,
      locationId: null,
      cities: [],
    }
  },
  computed: {
    getOrderCounts() {
      return key => {
        return this.ordersCounts[key] || 0
      }
    },
    computedTableProps() {
      return {
        ...this.defaultTableProps,
        ...(this.tableProps || {}),
      };
    },
    dateTimeFormat() {
      return (date) => {
        const givenDate = moment(date)

        const format = this.getDateDifferenceInMonths(givenDate) < 12 ? 'MMM DD - hh:mm A' : 'MMM DD, yy - hh:mm A'
        return givenDate.format(format)
      }
    },
    dateFormat() {
      return (date) => {
        const givenDate = moment(date)

        const format = this.getDateDifferenceInMonths(givenDate) < 12 ? 'MMM DD' : 'MMM DD, yy'
        return givenDate.format(format)
      }
    },
    getDateDifferenceInMonths() {
      return (givenDateInMoment) => {
        const currentDate = moment.utc()

        return currentDate.diff(givenDateInMoment, 'months')
      }
    },
    orderDeliveryDate() {
      return order => {
        return `${this.dateFormat(order.delivery_date_string)} (${order.delivery_time_slot})`
      }
    },
    getAxiosName() {
      const userType = localStorage.getItem('userType')
      return userType === 'VENDOR' ? $vendorAxios : $adminAxios
    },
    isUserVendor() {
      return localStorage.getItem('userType') === 'VENDOR'
    },
    getHomeUrl() {
      const userType = localStorage.getItem('userType')
      const mainPath = userType === 'VENDOR' ? 'vendor' : 'admin'

      return `/${mainPath}/orders-main`
    },
  },
  async mounted() {
    await this.getLocations()
    await this.fetchDeliveredOrdersStats()
  },
  methods: {
    async getLocations() {
      const locations = await this.getAxiosName.get(`/locations`)
      this.cities.push({
        title: 'All',
        auto_id: null,
      })
      this.cities.push(...(locations.data.data || []))
    },
    getItemUrl(id) {
      const userType = localStorage.getItem('userType')
      const mainPath = userType === 'VENDOR' ? 'vendor' : 'admin'

      return `/${mainPath}/orders/${id}`
    },
    async fetchOrders (page, limit) {
      if (!this.date || !this.to_date || !this.statusValue) {
        return this.$notify.error({
          title: 'Error',
          message: 'Please enter data in fields',
          type: 'error'
        });
      }

      this.clickedOnce = true

      page = page || this.page
      limit = limit || this.pageSize

      this.orderCounts = {}

      this.mainLoading = true

      const statusType = 'status'
      const statusValue = this.statusValue && this.statusValue.split(',')

      const date = this.date ? moment(this.date).format('YYYY-MM-DD') : null
      const toDate = this.to_date ? moment(this.to_date).format('YYYY-MM-DD') : null

      try {
        const [response, orderCounts] = await Promise.all([
          this.getAxiosName.get(`/orders`, {
            params: {
              page,
              limit,
              statusType,
              statusValue,
              date,
              to_date: toDate,
              type: this.type,
              location_id: this.locationId,
            }
          }),
          this.getAxiosName.get(`/status-based-order-counts`, {
            params: {
              date,
              type: this.type,
              location_id: this.locationId,
            }
          }),
        ])

        this.orders = response.data.data
        this.totalOrdersCount = response.data.total
        this.ordersCounts = orderCounts.data.data
      } catch (err) {
        this.ehm__errorMessages(err, true);
      }

      this.mainLoading = false
    },
    getRowValue(object, columnObject) {
      const path = columnObject.prop;
      const type = columnObject.type;

      const value =
        typeof this.$lodash.result(object, path) === "undefined"
          ? "--"
          : this.$lodash.result(object, path);

      if (type === "date") {
        const valueTemp =
          value && value !== "--"
? {
              type: "string",
              value: moment.utc(new Date(value)).format('yyyy-mm-dd'),
            }
: { type: "string", value: "--" };
        return valueTemp;
      }
      if (Array.isArray(value)) {
        return { type: "array", value };
      }
      return { type: "string", value };
    },
    handlePageChange(page) {
      this.fetchOrders(page)
    },
    handlePageSizeChange(size) {
      this.fetchOrders(this.page, size)
    },
    async changeCity(locationId) {
      this.locationId = locationId
      await this.fetchOrders()
    },
  },
}
</script>

<style lang="scss" scoped>
.customer-details-scroll {
  position: relative;
  height: calc(100vh - 150px);
  margin-top: 10px;
}
</style>

<style lang="scss" scoped>
.b-active {
  color: #409eff;
  border-color: #c6e2ff;
  background-color: #ecf5ff;
}

.b-active {
  color: #409eff;
  border-color: #c6e2ff;
  background-color: #ecf5ff;
}
.mobile-order-options {
  display: none;
}
.desktop-order-options {
  display: block;
}

@media screen and (max-width:768px) {
  .mobile-order-options {
    display: block;
  }
  .desktop-order-options {
    display: none;
  }
}
</style>
