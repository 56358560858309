<template>
  <div class="main-body">
    <el-scrollbar class="customer-details-scroll">
    <div class="">
      <h4>Create Payment Link</h4>
    </div>
    <div class="" style="margin-top: 10px;">
      <el-row>
        <el-input style="max-width: 240px" v-model="amount" placeholder="Enter amount" />
      </el-row>
      <el-row>
        <el-button style="margin-top: 10px;" :disabled="loading" type="button" @click="createPaymentLink">Create Link</el-button>
      </el-row>

      <el-row v-if="paymentLink" style="margin-top: 20px;">
        Payment Link: {{ paymentLink }}
      </el-row>
    </div>
    </el-scrollbar>
  </div>
</template>

<script>
import { $adminAxios } from '../config/axios'
import priceMixin from '../mixins/priceMixin'

export default {
  name: 'PaymentLink',
  mixins: [
    priceMixin,
  ],
  data: function () {
    return {
      paymentLink: null,
      amount: null,
      loading: false,
    }
  },
  methods: {
    async createPaymentLink () { // todo we need refactor location type filters
      this.loading = true
      this.paymentLink = null

      const data = {
        amount: this.amount,
      }

      try {
        const response = await $adminAxios.post(`/payment-links`, data)
        this.paymentLink = response.data.data.short_url
      } catch (err) {
        console.error(err)
        this.$notify.success({
          title: 'Error',
          message: err.message || 'Something went wrong',
          type: 'error'
        });
      }

      this.loading = false
    },
  },
}
</script>

<style lang="scss" scoped>
.customer-details-scroll {
  position: relative;
  height: calc(100vh - 75px);
  margin-top: 10px;
}
</style>
