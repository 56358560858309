<template>
  <div>
    <p>Loggig out...</p>
  </div>
</template>

<script>
import { $UpdateAuthorizationTokens } from '@/config/axios'

export default {
  async mounted() {
    const userType = localStorage.getItem('userType')

    await this.$store.dispatch("auth/signOut");

    localStorage.removeItem('userType')

    localStorage.removeItem('adminAccessToken')
    localStorage.removeItem('adminRefreshToken')

    localStorage.removeItem('vendorAccessToken')
    localStorage.removeItem('vendorRefreshToken')

    $UpdateAuthorizationTokens()

    const isVendorPath = location.pathname.startsWith('/vendor')

    // checking is previous path vendor or not. if yes, forwarding to vendor path
    if (userType === 'VENDOR' || isVendorPath) {
      this.$router.push('/vendor/signin')
    } else {
      this.$router.push('/admin/signin')
    }
  }
}
</script>
