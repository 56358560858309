<template>
  <div>
    <el-scrollbar class="customer-details-scroll">
      <h3 style="">Previous Images</h3>
      <el-row style="min-height: 210px; margin-top: 20px;" v-if="newImages.length">
          <draggable
            :list="newImages"
            tag="v-layout"
            :group="{ name: 'row' }"
            class="row justify-space-around"
          >
            <div v-for="(item, index) of newImages" :key="item.auto_id">
              <v-card style="margin-left: 10px; margin-top: 10px;">
                <el-button @click="deleteImage(index)" style="position: absolute; top: 0; right: 0;"><i class="el-icon-delete"></i></el-button>
                <img :src="getFullImageUrl(item.src)" style="width: 180px; height: 180px;" />
                <!-- <p>{{ item.src }}</p> -->
                <p><el-input style="margin-top: 2px;" v-model="item.alt_text" placeholder="Alt Text"></el-input></p>
              </v-card>
            </div>
          </draggable>
      </el-row>

      <el-row style="min-height: 50px; margin-top: 20px;" v-if="!newImages.length">
        <p><i>No Images</i></p>
      </el-row>

      <h3 style="margin-top: 30px;">Upload Images</h3>
      <el-row style="margin-top: 20px;">
        <el-col>
          <el-upload
            class="upload-demo"
            drag
            ref="upload"
            action="https://jsonplaceholder.typicode.com/posts/"
            :file-list="fileList"
            multiple
            :auto-upload="false"
            list-type="picture">
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">Drop file here or <em>click to upload</em></div>
          </el-upload>
        </el-col>
      </el-row>
      <el-row>
        <el-col>
          <el-button :disabled="uploadingFiles" style="margin-top: 10px;" type="primary" @click="uploadImages">{{ uploadButtonText }}</el-button>
        </el-col>
      </el-row>
    </el-scrollbar>
  </div>
</template>

<script>
import CONFIG from '@/config/app'
import draggable from 'vuedraggable'
import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";

import { $adminAxios } from '@/config/axios'
import newAxios from "axios";
const richDataAxios = newAxios.create({});

Vue.use(Vuetify);

export default {
  components: {
    draggable,
  },
  props: [
    'productId',
    'productImages',
    'productUrl'
  ],
  data() {
    return {
      fileList: [],
      s3ProductsBaseUrl: CONFIG.PRODUCT_IMAGES_URL,
      newImages: [],
      uploadButtonText: 'Upload to server',
      uploadingFiles: false,
    }
  },
  methods: {
    updateData() {
      this.$emit('updateData')
    },
    getFullImageUrl(url) {
      return `${this.s3ProductsBaseUrl}/${url}`
    },
    async uploadImages() {
      console.log('hi')
      const files = this.$refs.upload.uploadFiles

      this.uploadButtonText = 'Uploading...'
      this.uploadingFiles = true

      const presignedUrlsData = []
      try {
        for (let i = 0; i < files.length; i++) {
          const file = files[i]
          console.log(files[i])

          const response = await this.createPreSignUrl({
            resource_id: this.productId,
            resource_type: "P",
            file_name: this.updateProductUrl(file.name, this.newImages.length + i + 1),
          })

          const targetUrl = response.data.data.target_url
          const fileUploadId = response.data.data.file_upload_id

          await this.putFileInS3(targetUrl, file.raw)

          await this.updateFileStatus(fileUploadId)
        }

        const imagesResponse = await this.fetchProduct(this.productId)

        const newImages = imagesResponse.data.data.images.map(el => {
          return {
            src: el.src,
            alt_text: el.alt_text,
            position: el.position,
            _id: el._id,
          }
        })

        console.log(newImages)

        this.newImages = newImages

        this.$refs.upload.clearFiles()

        this.$notify.success({
          title: 'Success',
          message: 'Uploaded successfully',
        })
      } catch (err) {
        console.error(err)
        this.$notify.error({
          title: 'Error',
          message: err.message || 'Something went wrong',
          type: 'error'
        });
      }

      this.uploadButtonText = 'Upload files to server'
      this.uploadingFiles = false
    },
    createPreSignUrl (payload = {}) {
      const url = `/products/file-uploads/product_image_create`

      return $adminAxios.post(url, payload)
    },
    updateFileStatus (fileUploadId) {
      const url = `/products/file-uploads-status/${fileUploadId}`

      return $adminAxios.post(url)
    },
    fetchProduct(productId) {
      const url = `/products/${productId}`

      return $adminAxios.get(url)
    },
    putFileInS3 (targetUrl, file) {
      console.log(targetUrl)
      return richDataAxios.put(targetUrl, file);
    },
    async deleteImage(index) {
      try {
        const imageId = this.newImages[index]._id
        await $adminAxios.delete(`/products/${this.productId}/${imageId}`)

        this.newImages.splice(index, 1)

        this.$notify.success({
          title: 'Success',
          message: 'Deleted successfully',
        })
      } catch (err) {
        console.error(err)
        this.$notify.error({
          title: 'Error',
          message: err.message || 'Something went wrong',
          type: 'error'
        });
      }
    },
    updateProductUrl(fileName, index) {
      const extension = fileName.split('.').pop()

      return `${this.productUrlWithoutId}_${index}.${extension}`
    },
  },
  computed: {
    finalProductImages: {
      get: function () {
        return this.newImages
      },
      set: function (val) {
        this.newImages = val
      }
    },
    productUrlWithoutId() {
      const url = this.productUrl.split('-')
      url.pop()
      return url.join('-')
    }
  },
  watch: {
    productImages() {
      this.newImages = this.productImages
    }
  }
}
</script>

<style lang="scss" scoped>
.customer-details-scroll {
  position: relative;
  height: calc(100vh - 130px);
  margin-top: 10px;
}
</style>
