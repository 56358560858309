<template>
  <el-container
    class="admin-container"
    v-loading="loading"
    element-loading-background="white"
  >
    <el-header class="header__nav">
      <admin-header class="header--padding"></admin-header>
    </el-header>
    <router-view></router-view>
  </el-container>
</template>

<script>
import adminHeader from "@/components/admin/adminHeader";

export default {
  components: {
    adminHeader,
  },
  data: function () {
    return {
      showControl: true,
      loading: false,
    };
  },
  async mounted() {
    await this.initComponent();
  },
  methods: {
    toggleNavigation: function () {
      this.showControl = !this.showControl;
    },
    async initComponent() {
      try {
        this.loading = true;
        await this.$store.dispatch("auth/fetchUserProfile");
      } catch (err) {
        this.errorMessages(err, true);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss">
.admin-container {
  display: block;
  $navigation-width-onopen: 200px;
  $navigation-width-onclose: 64px;
  box-sizing: border-box;
  //   height: 100vh;
  overflow: hidden;
  .header__nav {
    height: var(--header-height) !important;
    position: relative;
    border-bottom: none;
    top: 0px;
    left: 0px;
    right: 0px;
  }
  .header--padding {
    margin-left: 10px;
    margin-right: 10px;
  }
  .el-header {
    padding: 0px;
    box-sizing: border-box;
    -ms-flex-negative: 0;
    flex-shrink: 0;
  }
  .material-icons-round {
    font-size: 15px;
  }
}
</style>
