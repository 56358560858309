import store from "@/store";

export const auth = (to, from, next) => {
  if (store.getters["auth/isAuthenticated"]) {
    next();
  } else {
    next({ path: "/vendor/signin" });
  }
};

export const isUnAuthenticatedUser = (to, from, next) => {
  if (store.getters["auth/isAuthenticated"]) {
    next({ path: "/admin" });
  } else {
    next();
  }
};

export const saveAccessTokenToLocalStorage = userResponse => {
  const userType = userResponse.data.user_type;
  localStorage.setItem("userType", userType);

  if (userType === "SUPER_ADMIN" || userType === "ADMIN") {
    localStorage.setItem("adminAccessToken", userResponse.access_token);
    localStorage.setItem("adminRefreshToken", userResponse.reset_token);
  } else if (userType === "VENDOR") {
    localStorage.setItem("vendorAccessToken", userResponse.access_token);
    localStorage.setItem("vendorResetToken", userResponse.reset_token);
  }
};

export const clearUserLocalStorage = () => {
  const userType = localStorage.removeItem("userType");

  if (userType === "ADMIN" || userType === "SUPER_ADMIN") {
    localStorage.removeItem("adminAccessToken");
    localStorage.removeItem("adminRefreshToken");
  } else if (userType === "VENDOR") {
    localStorage.removeItem("vendorAccessToken");
    localStorage.removeItem("vendorResetToken");
  }

  if (!userType) {
    localStorage.removeItem("adminAccessToken");
    localStorage.removeItem("adminRefreshToken");
    localStorage.removeItem("vendorAccessToken");
    localStorage.removeItem("vendorResetToken");
  }

  localStorage.removeItem("userType")
};

export const doesUserHasAdminAccess = (to, from, next) => {
  return doesUserHasAccessBasedOnUserTypes(to, from, next, ['SUPER_ADMIN', 'ADMIN'])
};

export const doesUserHasVendorAccess = (to, from, next) => {
  return doesUserHasAccessBasedOnUserTypes(to, from, next, ['VENDOR'])
};

const doesUserHasAccessBasedOnUserTypes = (to, from, next, acceptedUserTypes) => {
  try {
    const currUserType = localStorage.getItem("userType");
    const userProfile = store.getters["auth/getUserProfileData"]

    const currentRoute = location.pathname
    const isVendorSite = location.hostname === 'partner.cakeway.in' || currentRoute.startsWith('/vendor')

    const signInRoute = isVendorSite ? '/vendor/signin' : 'admin/signin'

    if (!userProfile || !currUserType) {
      signOut();
      return next(signInRoute);
    }

    // checking given current user is present in given user type
    if (acceptedUserTypes.indexOf(currUserType) === -1) {
      signOut();
      return next(signInRoute);
    }

    return next();
  } catch (err) {
    console.error(
      "Check the flow logic and user permissions of the router",
      err
    );
  }
}

const signOut = () => {
  store.dispatch("auth/signOut").catch(err => {
    console.error(err);
    throw err;
  });
};

export default {
  auth,
  isUnAuthenticatedUser,
  saveAccessTokenToLocalStorage,
  clearUserLocalStorage
};
