import Vue from "vue";

import locale from "element-ui/lib/locale/lang/en";

import ElementUI from "element-ui";

import "element-ui/lib/theme-chalk/index.css";
import "element-ui/lib/theme-chalk/display.css";
import "@toast-ui/editor/dist/toastui-editor.css";

import { DataTablesServer, DataTables } from "vue-data-tables";

Vue.use(ElementUI, {
  locale
});

Vue.use(DataTablesServer);
Vue.use(DataTables);
