<template>
  <div class="main-body">
    <div class="desktop-order-options" style="clear: both; margin-top: 15px;">
      <div class="">
          <el-button :class="getClassListForOrderStatus('status$open')" @click="fetchOrdersForButtons('status', 'open')">Open</el-button>
          <el-button :class="getClassListForOrderStatus('all')" @click="fetchOrdersForButtons()">All</el-button>
          <el-button :class="getClassListForOrderStatus('status$processed')" @click="fetchOrdersForButtons('status', 'processed')">Processed</el-button>
          <el-dropdown @command="clickOtherButton" style="margin-left: 10px;">
            <el-button type="default">
              Other<i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item :command="['status', 'processed']">Processed</el-dropdown-item>
              <el-dropdown-item :command="['status', 'delivered']">Delivered </el-dropdown-item>
              <el-dropdown-item :command="['financialStatus', 'pending']">Payment Pending</el-dropdown-item>
              <el-dropdown-item :command="['financialStatus', 'failed']">Payment Failed</el-dropdown-item>
              <el-dropdown-item :command="['financialStatus', 'cancelled']">Payment Cancelled</el-dropdown-item>
              <el-dropdown-item :command="['financialStatus', 'paid']">Payment Paid</el-dropdown-item>
              <el-dropdown-item :command="['status', 'cancelled']">Cancelled</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
      </div>
    </div>
    <div class="mobile-order-options" style="margin-top: 15px;">
      <el-select v-model="mobileActiveStatusFilter" @change="filterOrdersBySelectMenu(mobileActiveStatusFilter)">
        <el-option value="all" label="All">All</el-option>
        <el-option value="status$open" label="Open">Open</el-option>
        <el-option value="status$processed" label="Processed">Processed</el-option>
        <el-option value="status$delivered" label="Delivered">Delivered</el-option>
        <el-option value="financialStatus$pending" label="Payment Pending">Payment Pending</el-option>
        <el-option value="financialStatus$failed" label="Payment Failed">Payment Failed</el-option>
        <el-option value="financialStatus$cancelled" label="Payment Cancelled">Payment Cancelled</el-option>
        <el-option value="financialStatus$paid" label="Payment Done">Payment Done</el-option>
        <el-option value="status$cancelled" label="Cancelled">Cancelled</el-option>
      </el-select>
    </div>

    <el-scrollbar class="customer-details-scroll" style="margin-top: 20px;">
      <div class="">
        <el-table
          v-loading="mainLoading"
          class="table"
          :lazy="mainLoading"
          :data="orders"
          style="width: 100%; text-align: left; ">
          <el-table-column
            label="Order Id"
            prop="auto_id">
            <template slot-scope="{ row }">
              <el-link :href="getItemUrl(row.auto_id)" >{{ row.auto_id }}</el-link>
            </template>
          </el-table-column>
          <el-table-column
            label="City"
          >
            <template slot-scope="{ row }">
              {{ row.shipping_address.city }}
            </template>
          </el-table-column>
          <el-table-column
            label="Payment Status"
            prop="financial_status">
            <template slot-scope="{ row }">
              <el-tag v-if="row.financial_status === 'paid'" type="success">Paid</el-tag>
              <el-tag v-else-if="row.financial_status === 'pending'" type="warning">Pending</el-tag>
              <el-tag v-else-if="row.financial_status === 'failed'" type="danger">Failed</el-tag>
              <el-tag v-else type="info">{{ row.financial_status }}</el-tag>
            </template>
          </el-table-column>
          <el-table-column
            label="Order Status"
            prop="status">
            <template slot-scope="{ row }">
              <el-tag v-if="row.status === 'open'" type="" effect="dark">Open</el-tag>
              <el-tag v-else-if="row.status === 'processed'" type="warning" effect="dark">Processed</el-tag>
              <el-tag v-else-if="row.status === 'cancelled'" type="danger">Cancelled</el-tag>
              <el-tag v-else-if="row.status === 'delivered'" type="success" effect="dark">Delivered</el-tag>
              <el-tag v-else type="info">{{ row.status }}</el-tag>
            </template>
          </el-table-column>
          <el-table-column
            label="Delivery Date"
            prop="delivery_date">
            <template slot-scope="{ row }">
              {{ orderDeliveryDate(row) }}
            </template>
          </el-table-column>
          <el-table-column
            label="Order Date"
            prop="created_at">
            <template slot-scope="{ row }">
              {{ dateTimeFormat(row.created_at) }}
            </template>
          </el-table-column>
          <el-table-column
            label="Price"
            prop="total_price">
          </el-table-column>
        </el-table>
      </div>
      <div style="margin-top: 20px; text-align: center;">
        <el-pagination
          @current-change="handlePageChange"
          @size-change="handlePageSizeChange"
          :current-page.sync="page"
          :page-sizes="[30, 40 ,50]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="totalOrdersCount">
        </el-pagination>
      </div>
    </el-scrollbar>
  </div>
</template>

<script>
import { $adminAxios } from '../config/axios'
import priceMixin from '../mixins/priceMixin'
import moment from 'moment'

export default {
  name: 'Categories',
  mixins: [
    priceMixin,
  ],
  data: function () {
    return {
      defaultTableProps: {
        size: "mini",
        border: true,
      },
      columns: [{
        prop: 'auto_id',
        label: 'Order Id',
        required: true,
      }],
      orders: [],
      totalOrdersCount: 0,
      mainLoading: true,
      page: 1,
      pageSize: 30,
      activeStatusFilter: 'status$open',
      mobileActiveStatusFilter: 'status$open',
    }
  },
  computed: {
    computedTableProps() {
      return {
        ...this.defaultTableProps,
        ...(this.tableProps || {}),
      };
    },
    dateTimeFormat() {
      return (date) => {
        const givenDate = moment(date)

        const format = this.getDateDifferenceInMonths(givenDate) < 12 ? 'MMM DD - hh:mm A' : 'MMM DD, yy - hh:mm A'
        return givenDate.format(format)
      }
    },
    dateFormat() {
      return (date) => {
        const givenDate = moment(date)

        const format = this.getDateDifferenceInMonths(givenDate) < 12 ? 'MMM DD' : 'MMM DD, yy'
        return givenDate.format(format)
      }
    },
    getDateDifferenceInMonths() {
      return (givenDateInMoment) => {
        const currentDate = moment.utc()

        return currentDate.diff(givenDateInMoment, 'months')
      }
    },
    orderDeliveryDate() {
      return order => {
        return `${this.dateFormat(order.delivery_date_string)} (${order.delivery_time_slot})`
      }
    },
  },
  mounted() {
    this.fetchOrders(null, null, 'status', 'open')
  },
  methods: {
    filterOrdersBySelectMenu(type) {
      if (type === "all") {
        this.fetchOrdersForButtons()
      } else if (type === "status$open" ) {
        this.fetchOrdersForButtons('status', 'open')
      } else if (type === "status$processed" ) {
        this.fetchOrdersForButtons('status', 'processed')
      } else if (type === "status$delivered" ) {
        this.fetchOrdersForButtons('status', 'delivered')
      } else if (type === "financialStatus$pending" ) {
        this.fetchOrdersForButtons('financialStatus', 'pending')
      } else if (type === "financialStatus$failed" ) {
        this.fetchOrdersForButtons('financialStatus', 'failed')
      } else if (type === "financialStatus$cancelled" ) {
        this.fetchOrdersForButtons('financialStatus', 'cancelled')
      } else if (type === "financialStatus$paid" ) {
        this.fetchOrdersForButtons('financialStatus', 'paid')
      } else if (type === "status$cancelled" ) {
        this.fetchOrdersForButtons('status', 'cancelled')
      }
    },
    getItemUrl(id) {
      return `/admin/orders/${id}`
    },
    async fetchOrders (page, limit, statusType, statusValue) {
      page = page || this.page
      limit = limit || this.pageSize

      this.mainLoading = true

      try {
        const response = await $adminAxios.get(`/orders?page=${page}&limit=${limit}&status[]=paid&statusType=${statusType}&statusValue=${statusValue}`)
        this.orders = response.data.data
        this.totalOrdersCount = response.data.total
      } catch (err) {
        console.error(err)
        this.$notify.success({
          title: 'Error',
          message: err.message || 'Something went wrong',
          type: 'error'
        });
      }

      this.mainLoading = false
    },
    getRowValue(object, columnObject) {
      const path = columnObject.prop;
      const type = columnObject.type;

      const value =
        typeof this.$lodash.result(object, path) === "undefined"
          ? "--"
          : this.$lodash.result(object, path);

      if (type === "date") {
        const valueTemp =
          value && value !== "--"
? {
              type: "string",
              value: moment.utc(new Date(value)).format('yyyy-mm-dd'),
            }
: { type: "string", value: "--" };
        return valueTemp;
      }
      if (Array.isArray(value)) {
        return { type: "array", value };
      }
      return { type: "string", value };
    },
    handlePageChange(page) {
      this.fetchOrders(page, this.size)
    },
    handlePageSizeChange(size) {
      this.fetchOrders(this.page, size)
    },
    clickOtherButton([statusType, statusValue]) {
      return this.fetchOrdersForButtons(statusType, statusValue)
    },
    fetchOrdersForButtons(statusType, statusValue) {
      this.activeStatusFilter = statusType && statusValue ? `${statusType}$${statusValue}` : 'all'
      this.fetchOrders(1, null, statusType, statusValue)
    },
    getClassListForOrderStatus(type) {
      const classList = ['b-active']

      if (this.activeStatusFilter === type) {
        return classList
      } if (this.activeStatusFilter === type) {
        return classList
      } else if (this.activeStatusFilter === type) {
        return classList
      } else if (this.activeStatusFilter === type) {
        return classList
      } else if (this.activeStatusFilter === type) {
        return classList
      } else if (this.activeStatusFilter === type) {
        return classList
      } else if (this.activeStatusFilter === type) {
        return classList
      } else if (this.activeStatusFilter === type) {
        return classList
      }

      return []
    },
  },
}
</script>

<style lang="scss" scoped>
.customer-details-scroll {
  position: relative;
  height: calc(100vh - 150px);
  margin-top: 10px;
}
</style>

<style lang="scss" scoped>
.b-active {
  color: #409eff;
  border-color: #c6e2ff;
  background-color: #ecf5ff;
}
.mobile-order-options {
  display: none;
}
.desktop-order-options {
  display: block;
}

@media screen and (max-width:768px) {
  .mobile-order-options {
    display: block;
  }
  .desktop-order-options {
    display: none;
  }
}
</style>
