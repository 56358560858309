import store from "@/store";

import view404 from "@/views/view404";
import adminMainIndex from "@/views/adminMainIndex";

import productsView from "@/views/products";
import templateProductsView from "@/views/templateProducts";
import productUpdateView from "@/views/productUpdate";
import templateProductUpdateView from "@/views/templateProductUpdate";
// import ordersView from "@/views/orders";
import adminOrdersView from "@/views/adminOrders";
import adminOrdersDashboardView from "@/views/adminOrdersDashboard";
import vendorOrdersDashboardView from "@/views/vendorOrdersDashboard";
import vendorOrdersHistoryView from "@/views/vendorOrdersHistory";
import vendorOrdersStatsView from "@/views/vendorOrdersStats";
import adminSingleOrderView from "@/views/adminSingleOrder";
import vendorOrdersMainView from "@/views/vendorOrdersMainView";
import customersView from "@/views/customers";
import productCreateView from "@/views/productCreate";
import categoriesView from "@/views/categories";
import vendorsView from "@/views/vendors";
import vendorUpdateView from "@/views/vendorUpdate";
import templateVendorUpdateView from "@/views/templateVendorUpdate";
import categoryCreateOrUpdate from "@/views/categoryCreateOrUpdate";
import vendorCreateView from "@/views/vendorCreate";
import locationsView from "@/views/locations";
import locationUpdateView from "@/views/locationUpdate";
import signInComponent from "@/components/admin/signIn";
import paymentLinkView from "@/views/paymentLink";
import configView from "@/views/config";

import {
  doesUserHasAdminAccess as doesUserCanAccess,
} from '@/middleware/auth'

export default {
  path: "/admin",
  component: adminMainIndex,
  children: [
    {
      path: "/",
      redirect: "orders-main"
    },
    {
      path: "products",
      name: "products",
      component: productsView,
      beforeEnter: doesUserCanAccess
    },
    {
      path: "template-products",
      name: "templateProducts",
      component: templateProductsView,
      beforeEnter: doesUserCanAccess
    },
    {
      path: "products/create",
      name: "productCreate",
      component: productCreateView,
      beforeEnter: doesUserCanAccess
    },
    {
      path: "products/:product_id",
      name: "productUpdate",
      component: productUpdateView,
      beforeEnter: doesUserCanAccess
    },
    {
      path: "template-products/:product_id",
      name: "templateProductUpdate",
      component: templateProductUpdateView,
      beforeEnter: doesUserCanAccess
    },
    {
      path: "orders-main",
      name: "ordersMain",
      component: vendorOrdersMainView,
      beforeEnter: doesUserCanAccess
    },
    {
      path: "orders",
      name: "orders",
      component: vendorOrdersDashboardView,
      beforeEnter: doesUserCanAccess
    },
    {
      path: "orders-history",
      name: "AdminOrdersHistory",
      component: vendorOrdersHistoryView,
      beforeEnter: doesUserCanAccess
    },
    {
      path: "stats",
      name: "AdminDeliveredOrdersStats",
      component: vendorOrdersStatsView,
      beforeEnter: doesUserCanAccess
    },
    {
      path: "orders-dashboard",
      name: "ordersDashboard",
      component: adminOrdersView,
      beforeEnter: doesUserCanAccess
    },
    {
      path: "orders/:order_id",
      name: "singleOrder",
      component: adminSingleOrderView,
      beforeEnter: doesUserCanAccess
    },
    {
      path: "customers",
      name: "customers",
      component: customersView,

      beforeEnter: doesUserCanAccess
    },
    {
      path: "categories",
      name: "Categories",
      component: categoriesView,

      beforeEnter: doesUserCanAccess
    },
    {
      path: "categories/create",
      name: "CategoryCreate",
      component: categoryCreateOrUpdate,
      beforeEnter: doesUserCanAccess
    },
    {
      path: "categories/:category_id",
      name: "CategoryCreate",
      component: categoryCreateOrUpdate,
      beforeEnter: doesUserCanAccess
    },
    {
      path: "vendors",
      name: "Vendors",
      component: vendorsView,

      beforeEnter: doesUserCanAccess
    },
    {
      path: "vendors/:vendor_id",
      name: "vendorUpdate",
      component: vendorUpdateView,
      beforeEnter: doesUserCanAccess
    },
    {
      path: "template-vendors/:vendor_id",
      name: "vendorUpdate",
      component: templateVendorUpdateView,
      beforeEnter: doesUserCanAccess
    },
    {
      path: "locations",
      name: "Locations",
      component: locationsView,
      beforeEnter: doesUserCanAccess
    },
    {
      path: "locations/:location_id",
      name: "LocationUpdate",
      component: locationUpdateView,
      beforeEnter: doesUserCanAccess
    },
    {
      path: "payment-links",
      name: "PaymentLinks",
      component: paymentLinkView,
      beforeEnter: doesUserCanAccess
    },
    {
      path: "config",
      name: "Config",
      component: configView,
      beforeEnter: doesUserCanAccess
    },
    {
      path: "signin",
      name: "signin",
      component: signInComponent
    }
  ]
};
