<template>
  <div>
    <el-scrollbar class="customer-details-scroll" v-loading="locationsLoading">
      <h2 style="padding: 10px">
        {{ vendorInformation.title }}
      </h2>
      <el-row :gutter="20">
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <el-card shadow="always">
            <p>
              <b>Total Pricing Models In Vendor:</b> {{ this.vendorLaunchDetails.totalVendorPrices }} <br/>
            </p>
            <p v-if="totalVendorPrices === 0" style="margin-top: 10px;">
              <el-button @click="copyAllVendorPricesFromTemplates" :disabled="copyVendorPricesLoading">
                <span v-if="copyVendorPricesLoading">
                  <i class="el-icon-loading"></i>
                </span>
                <span>
                  Copy Vendor Prices From Templates
                </span>
              </el-button>
            </p>
          </el-card>
        </el-col>
      </el-row>
      <el-row :gutter="20" style="margin-top: 10px;">
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <el-card shadow="always">
            <p>
              <b>Vendor User:</b> {{ this.vendorLaunchDetails.vendorUserDetails ? 'Created' : 'Not Created' }} <br/>
            </p>
            <p v-if="!this.vendorLaunchDetails.vendorUserDetails" style="margin-top: 10px;">
              <el-button @click="createVendorUser" :disabled="vendorUserCreateLoading">
                <span v-if="vendorUserCreateLoading">
                  <i class="el-icon-loading"></i>
                </span>
                <span>
                  Create Vendor User
                </span>
              </el-button>
            </p>
            <p v-else style="margin-top: 20px;">
              <el-form>
                <el-form-item>
                  <el-input v-model="password" placeholder="Password" type="password" show-password />
                </el-form-item>
              </el-form>
              <el-button @click="vendorUserPasswordUpdate" :disabled="vendorUserPasswordUpdateLoading">
                <span v-if="vendorUserPasswordUpdateLoading">
                  <i class="el-icon-loading"></i>
                </span>
                <span>
                  Update Vendor User Password
                </span>
              </el-button>
            </p>
          </el-card>
        </el-col>
      </el-row>
      <br/>
      <br/>
    </el-scrollbar>
  </div>
</template>

<script>
import { $adminAxios } from '../../../config/axios'
import config from '../../../config/app'

import { getMessageFromError } from '@/mixins/requestHelpers'

export default {
  computed: {
    totalVendorPrices() {
      return this.vendorLaunchDetails.totalVendorPrices
    },
    buttonText() {
      return this.vendorId ? 'Update Location' : 'Create Location'
    },
    cityLaunchLink() {
      return `${config.USER_WEBSITE_URL}/new-city-launch/${this.vendorId}`
    },
  },
  components: {
  },
  props: [
    'vendorInformation',
  ],
  data() {
    return {
      vendorInformationData: {},
      locationsLoading: false,
      vendorLaunchDetails: {},
      vendorId: null,
      updateStatusLoading: false,
      copyVendorPricesLoading: false,
      vendorUserCreateLoading: false,
      vendorUserPasswordUpdateLoading: false,
      password: null,
    }
  },
  methods: {
    async getVendorLaunchDetails() {
      try {
        const response = await $adminAxios.get(`/vendors/${this.vendorId}/launch-details`)

        this.vendorLaunchDetails = response.data.data
      } catch (err) {
        console.error(err)
        this.$notify.error({
          title: 'Error',
          message: getMessageFromError(err),
          type: 'error'
        });
      }
    },
    async copyAllVendorPricesFromTemplates() {
      this.copyVendorPricesLoading = true
      try {
        const response = await $adminAxios.post(`/copy-vendor-prices-from-templates-to-new-vendor`, {
          to_vendor_id: this.vendorId
        })
        this.$emit('reload')
        await this.getVendorLaunchDetails()

        this.$notify.success({
          title: 'Success',
          message: response.data.message,
          type: 'success'
        });
      } catch (err) {
        this.$notify.error({
          title: 'Error',
          message: getMessageFromError(err),
          type: 'error'
        });
      }
      this.copyVendorPricesLoading = false
    },
    async createVendorUser() {
      this.vendorUserCreateLoading = true
      try {
        const response = await $adminAxios.post(`/vendors/${this.vendorId}/user`)
        await this.getVendorLaunchDetails()

        this.$notify.success({
          title: 'Success',
          message: response.data.message,
          type: 'success'
        });
      } catch (err) {
        console.error(err)
        this.$notify.error({
          title: 'Error',
          message: getMessageFromError(err),
          type: 'error'
        });
      }
      this.vendorUserCreateLoading = false
    },
    async vendorUserPasswordUpdate() {
      if (!this.password) {
        return
      }

      this.vendorUserPasswordUpdateLoading = true
      try {
        const response = await $adminAxios.patch(`/vendors/${this.vendorId}/user-password`, { new_password: this.password, confirm_password: this.password })
        await this.getVendorLaunchDetails()

        this.$notify.success({
          title: 'Success',
          message: response.data.message,
          type: 'success'
        });

        this.password = null
      } catch (err) {
        console.error(err)
        this.$notify.error({
          title: 'Error',
          message: getMessageFromError(err),
          type: 'error'
        });
      }
      this.vendorUserPasswordUpdateLoading = false
    },
  },
  watch: {
    vendorInformation(val) {
      this.vendorInformationData = JSON.parse(JSON.stringify(val))
    },
  },
  async mounted() {
    const vendorId = this.$route.params.vendor_id
    console.log(vendorId)
    if (vendorId && vendorId !== 'create') {
      this.vendorId = vendorId
      await this.getVendorLaunchDetails()
    }
  },
}
</script>

<style lang="scss" scoped>
.customer-details-scroll {
  position: relative;
  height: calc(100vh - 120px);
  margin-top: 10px;
}
</style>
