<template>
  <div>
    <el-form label-position="left" label-width="100px" :model="metaDataInformation" >
      <el-row>
        <el-col :span="12">
          <el-form-item label="Title">
            <el-input v-model="metaDataInformation.title"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="Keywords">
            <el-select
              v-model="metaDataInformation.keywords"
              multiple
              filterable
              allow-create
              placeholder="Choose keywords"
              style="width: 100%">
              <el-option
                v-for="item in []"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="Description">
            <el-input type="textarea" rows="3" maxlength="200" v-model="metaDataInformation.description"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="6">
          <el-form-item>
            <el-button
              type="primary" @click="updateData">Update Meta Data</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
export default {
  props: [
    'metaDataInformation',
  ],
  data() {
    return {
    }
  },
  methods: {
    updateData() {
      this.$emit('updateData')
    }
  }
}
</script>
