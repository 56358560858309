<template>
  <el-row class="admin-header">
    <el-col :span="20">
        <el-menu
          class="admin-menu"
          mode="horizontal"
          :default-active="activeRoute"
          :router="true"
        >

          <er-menu-item
            index="orders"
            label="Orders"
            route="/vendor/orders-main"
            :isActive="activeRoute === 'orders' || activeRoute === 'orders-main'"
          >
          </er-menu-item>
          <el-dropdown placement="bottom-start" :hide-on-click="true" @command="handleCommand" class="smenu" style="font-size: 16px;">
            <span class="el-dropdown-link">
              Reports<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="orders-history">Orders History</el-dropdown-item>
              <el-dropdown-item command="stats">Stats</el-dropdown-item>
              <!-- <el-dropdown-item command="payments">Payments</el-dropdown-item> -->
            </el-dropdown-menu>
          </el-dropdown>
        </el-menu>
    </el-col>
    <el-col :span="4" style="align: right">
      <el-menu
          class="admin-menu"
          mode="horizontal"
          :default-active="activeRoute"
          :router="true"
        >
          <er-menu-item
            label="Logout"
            route="/logout"
            v-if="isAuthenticated"
          >
          </er-menu-item>
          <er-menu-item
            label="Sign In"
            route="/"
            v-else
          >
          </er-menu-item>
      </el-menu>
    </el-col>
  </el-row>
</template>

<script>
import erMenuItem from '@/components/base/erMenuItem'

export default {
  components: {
    erMenuItem,
  },
  computed: {
    isAuthenticated() {
      return this.$store.getters["auth/isAuthenticated"]
    },
    getUserName() {
      return (
        this.$store.getters["admin/profile"] ? this.$store.getters["admin/profile"].name : ''
      );
    },
    activeRoute() {
      return this.$route.path.split("/")[2];
    },
  },
  methods: {
    handleCommand(link) {
      this.$router.push("/vendor/" + link);
    }
  },
};
</script>

<style lang="scss" scoped>
.m-menu {
  display: block;
}
.d-m-menu {
  display: none;
  height: calc(var(--header-height) - 2px);
  line-height: calc(var(--header-height) - 2px);
  font-weight: 600;
}
.smenu {
  line-height: calc(var(--header-height) - 2px);
  margin-left: 20px;
}
@media screen and (max-width:768px) {
  .m-menu {
    display: none;
  }
  .d-m-menu {
    display: inline-block;
  }
  .smenu {
    margin-left: 10px;
  }
}

</style>
