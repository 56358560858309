import Vue from "vue";
import Router from "vue-router";

import adminRoutes from "./adminRoutes";
import vendorRoutes from "./vendorRoutes";

import logoutView from "@/components/logout";
import view404 from "@/views/view404";

Vue.use(Router);

const routes = [
  adminRoutes,
  vendorRoutes,
  {
    path: "/",
    redirect: "/vendor"
  },
  {
    path: "/logout",
    component: logoutView,
  },
  {
    path: "*",
    redirect: "/vendor"
  },
  {
    path: "/404",
    name: "page404",
    component: view404
  }
];

const router = new Router({ mode: "history", routes });
export default router;
