<template>
  <div id="website-app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  data: function () {
    return {};
  },
  created() {
  },
  methods: {},
};
</script>

<style lang="scss">
@media screen and (max-width:768px) {
    .table-container {
        overflow: auto;
    }
}
.customer-details-scroll {
  width: 100%;
}
:root {
  --header-height: 40px;
}

$font-family: "Lato", "HelveticaNeue", "Arial", "sans-serif";
* {
  margin: 0px;
  padding: 0px;
  outline: none;
  font-family: $font-family;
}

html,
body,
#website-app {
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  overflow: hidden;
}

hr {
  border-style: solid;
  border-width: 0.5px;
}

*::-webkit-scrollbar {
  background-color: #fff;
  width: 6px;
  height: 7.5px;
}

*::-webkit-scrollbar-track {
  background-color: transparent;
  border-color: transparent;
}
*::-webkit-scrollbar-button {
  display: none;
}
*::-webkit-scrollbar-thumb {
  position: relative;
  display: block;
  width: 0;
  height: 0;
  margin: 0px 20px;
  cursor: pointer;
  border-radius: 16px;
  border: 5px solid transparent;
  background-color: rgba(144, 147, 153, 0.3);
  -webkit-transition: 0.3s background-color;
  transition: 0.3s background-color;
}

.el-menu--popup {
  a {
    text-decoration: none;
  }
}

/* change the size of the img icons */
input[type="number"]::-webkit-inner-spin-button,
input[type="number  "]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.captalize {
  text-transform: capitalize;
}
.align-text--left {
  text-align: left;
}
.align-item-left {
  margin-left: auto;
}
.align-text--right {
  text-align: right;
}
.align-item-right {
  margin-right: auto;
}
.align-text--center {
  text-align: center;
}
.bottom-margin {
  margin-bottom: 10px;
}
// form styles
.form-container {
  display: flex;
  flex-direction: column;
  font-size: 1em;
}

.form-field-label {
  color: #909399;
  margin-bottom: 50px;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.el-input:not(.el-pagination__editor):not(.el-date-editor),
.el-input-number {
  .el-input__inner {
    text-align: left;
  }
}

.el-input__inner,
.el-input__inner:hover,
.el-textarea__inner,
.el-textarea__inner:hover {
  border-color: #acc9f4;
}

// table global styling
.el-table {
  .cell {
    word-break: break-word;
  }
}

.el-table__empty-text {
  // display: inline-flex;
  flex-direction: column;
  line-height: 25px;
  padding: 25px 0px;
  .no-data__img {
    width: 50px;
    height: 50px;
  }
  .no-data__text {
    text-transform: capitalize;
  }
}

// card default settings
.el-table__empty-text .no-data__text h4 {
  color: #000000cf;
  font-weight: 500;
}

.el-card {
  h3,
  h4 {
    font-weight: 500;
    color: #000000cf;
  }
  &.is-always-shadow,
  &.is-hover-shadow:focus,
  &.is-hover-shadow:hover {
    box-shadow: 1px 1px rgba(229, 236, 243, 0.8);
  }
}

.main-body {
  padding: 15px 20px;
}
</style>
