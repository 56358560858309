import { render, staticRenderFns } from "./vendorOrdersStats.vue?vue&type=template&id=845d1e26&scoped=true"
import script from "./vendorOrdersStats.vue?vue&type=script&lang=js"
export * from "./vendorOrdersStats.vue?vue&type=script&lang=js"
import style0 from "./vendorOrdersStats.vue?vue&type=style&index=0&id=845d1e26&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "845d1e26",
  null
  
)

export default component.exports