<template>
  <div class="main-body">
    <div class="desktop-order-options" style="clear: both; margin-top: 15px;">
      <div class="">
        <el-row :gutter="10">
          <el-col class="order-card" :xs="24" :sm="24" :md="6" :lg="6">
            <span class="pointer" @click="fetchOrderCountsForButtonsLevel1('allOpen')">
              <el-card @click="fetchOrderCountsForButtonsLevel1('allOpen')">
                Not Acknowledged {{ `(${getOrderCounts('allOpen') || 0})` }}
              </el-card>
            </span>
          </el-col>
          <el-col class="order-card" :xs="24" :sm="24" :md="6" :lg="6">
            <span class="pointer" @click="fetchOrderCountsForButtonsLevel1('today')">
              <el-card>
                Today {{ `(${getOrderCounts('today') || 0})` }}
              </el-card>
            </span>
          </el-col>
          <el-col class="order-card" :xs="24" :sm="24" :md="6" :lg="6" >
            <span class="pointer" @click="fetchOrderCountsForButtonsLevel1('tomorrow')">
              <el-card>
                Tomorrow {{ `(${getOrderCounts('tomorrow') || 0})` }}
              </el-card>
            </span>
          </el-col>
          <el-col class="order-card" :xs="24" :sm="24" :md="6" :lg="6">
            <span class="pointer" @click="fetchOrderCountsForButtonsLevel1('all')">
              <el-card>
                All Orders {{ `(${getOrderCounts('all') || 0})` }}
              </el-card>
            </span>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import { $adminAxios, $vendorAxios } from '../config/axios'
import priceMixin from '../mixins/priceMixin'
import moment from 'moment'
import errorHandlerMixin from "@/mixins/errorHandlerMixin";

export default {
  name: 'Categories',
  mixins: [
    priceMixin,
    errorHandlerMixin,
  ],
  data: function () {
    return {
      mainLoading: true,
      activeStatusFilter: 'status$open',
      mobileActiveStatusFilter: 'status$open',
      level1: 'today',
      ordersCounts: {},
    }
  },
  computed: {
    getOrderCounts() {
      return key => {
        return this.ordersCounts[key] || 0
      }
    },
    getAxiosName() {
      const userType = localStorage.getItem('userType')
      return userType === 'VENDOR' ? $vendorAxios : $adminAxios
    },
  },
  mounted() {
    this.fetchOrderCounts()
  },
  methods: {
    async fetchOrderCounts () {
      this.orderCounts = {}

      try {
        const orderCounts = await this.getAxiosName.get(`/order-main-dashboard-counts`)

        this.ordersCounts = orderCounts.data.data
      } catch (err) {
        this.ehm__errorMessages(err, true);
      }

      this.mainLoading = false
    },
    fetchOrderCountsForButtonsLevel1 (type) {
      let date
      if (type === 'today') {
        date = moment(new Date()).format('YYYY-MM-DD')
      } else if (type === 'tomorrow') {
        date = moment(new Date()).add(1, 'days').format('YYYY-MM-DD')
      }

      const userType = localStorage.getItem('userType')
      const routeName = userType === 'VENDOR' ? 'vendorOrders' : 'orders'

      let newQueryParams = {}

      if (type === 'allOpen') {
        newQueryParams = {
          type: 'currentOrders',
          statusType: 'status',
          statusValue: 'open',
        }
      } else if (type === 'all') {
        newQueryParams = {
          type: 'currentOrders',
        }
      }

      this.$router.push({
        name: routeName,
        query: {
          date,
          ...newQueryParams,
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.b-active {
  color: #409eff;
  border-color: #c6e2ff;
  background-color: #ecf5ff;
}

.b-active {
  color: #409eff;
  border-color: #c6e2ff;
  background-color: #ecf5ff;
}
.mobile-order-options {
  display: none;
}
.desktop-order-options {
  display: block;
}

a {
  text-decoration: none;
}

@media screen and (max-width:768px) {
  .order-card {
    margin-top: 10px;
  }
}
</style>
