<template>
  <div class="main-body">
      <div style="margin-left: 20px;">
        <el-scrollbar class="customer-details-scroll">
          <el-row>
            <h3 style="margin-bottom: 20px;">Product Main Information</h3>
            <product-main-information-update @updateData="updateProductmainInformation" :productId="productId" :productInformation="productInformation" :productLoading="productLoading" />
          </el-row>
        </el-scrollbar>
      </div>
  </div>
</template>

<script>import { $adminAxios } from '../config/axios'
import moment from 'moment'

import productMainInformationUpdate from '@/components/admin/productUpdate/productMainInformationUpdate.vue'

export default {
  components: {
    productMainInformationUpdate,
  },
  data() {
    return {
      productInformation: { delivery_information: {}, care_instructions: {}, status: 'DRAFT', stock_availability: true },
      metaDataInformation: {},
      product: {},
      productLoading: false,
      firstTab: "images",
      productId: null,
      productImages: [],
    }
  },
  methods: {
    async createData (data) {
      this.productLoading = true

      const url = `/products`

      try {
        const response = await $adminAxios.post(url, data)

        const productdDataInDb = response.data.data

        this.productId = productdDataInDb.auto_id
        this.product = productdDataInDb

        this.productInformation = this.product

        this.$notify.success({
          title: 'Success',
          message: 'Successfully created product',
          type: 'success'
        });

        this.$router.push(`/admin/products/${productdDataInDb.auto_id}`)
      } catch (err) {
        console.error(err)
        this.$notify.error({
          title: 'Error',
          message: err.message || 'Something went wrong',
          type: 'error'
        });
      }

      this.productLoading = false
    },
    async updateProductmainInformation (data) {
      if (this.productId) {
        await this.updateData(data, 'mainProductInformation')
      } else {
        await this.createData(data)
      }
    },
    async updateData (data, updateType) {
      const productId = this.productId

      this.productLoading = true

      let url
      if (updateType === 'mainProductInformation') {
        url = `/products/${productId}/main-product-information`
      }

      try {
        await $adminAxios.patch(url, data)

        this.$notify.success({
          title: 'Success',
          message: 'Successfully updated product',
          type: 'success'
        });
      } catch (err) {
        console.error(err)
        this.$notify.error({
          title: 'Error',
          message: err.message || 'Something went wrong',
          type: 'error'
        });
      }

      this.productLoading = false
    },
  }
}
</script>

<style lang="scss" scoped>
.customer-details-scroll {
  position: relative;
  height: calc(100vh - 75px);
  margin-top: 10px;
}
</style>
