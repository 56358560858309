<template>
  <div class="main-body">
    <el-scrollbar class="customer-details-scroll">
    <div class="">
      <el-row>
        <el-col :xs="12" :sm="4" :md="4" :lg="3" style="margin-left: 10px">
          <el-input v-model="searchString" placeholder="Search" clearable=""></el-input>
        </el-col>
        <el-col :xs="12" :sm="4" :md="4" :lg="2">
          <el-button @click="searchData">Search</el-button>
        </el-col>
        <el-col :sm="12" :md="6" :lg="3" :xl="2">
          <el-button @click="createVendor">Add Vendor</el-button>
        </el-col>
      </el-row>
    </div>
    <div class="" style="margin-top: 15px;">
      <el-table
        v-loading="vendorsLoading"
        class="table"
        :lazy="vendorsLoading"
        :data="vendors"
        style="width: 100%; text-align: left; "
        @sort-change="sortChange">
        <el-table-column
          label="Title"
          prop="title"
          sortable>
          <template slot-scope="{ row }">
            <el-link :href="getVendorUrl(row.auto_id)" >{{ row.title }}</el-link>
          </template>
        </el-table-column>
        <el-table-column
          label="City or Location"
          prop="location_data">
          <template slot-scope="{ row }">
            <p>{{ row.location_data && row.location_data.auto_id ? row.location_data.title : 'NA' }}</p>
          </template>
        </el-table-column>
        <el-table-column
          label="Phone"
          prop="phone">
        </el-table-column>
        <el-table-column
          label="Status"
          prop="status">
        </el-table-column>
        <el-table-column
          label="Discount by Vendor (%)"
          prop="vendor_discount_percentage">
        </el-table-column>
        <!-- <el-table-column
          label="User Purchase (%)"
          prop="user_purchase_percentage">
        </el-table-column>
        <el-table-column
          label="User Display (%)"
          prop="user_display_percentage">
        </el-table-column> -->
      </el-table>
    </div>
    <div style="margin-top: 20px; text-align: center;">
      <el-pagination
        @current-change="handlePageChange"
        @size-change="handlePageSizeChange"
        :current-page.sync="page"
        :page-sizes="[30, 40 ,50]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalVendorsCount">
      </el-pagination>
    </div>
    </el-scrollbar>
  </div>
</template>

<script>
import { $adminAxios } from '../config/axios'
import priceMixin from '../mixins/priceMixin'
import moment from 'moment'

export default {
  name: 'Vendors',
  mixins: [
    priceMixin,
  ],
  data: function () {
    return {
      defaultTableProps: {
        size: "mini",
        border: true,
      },
      columns: [{
        prop: 'title',
        label: 'Title',
        required: true,
      }],
      vendors: [],
      totalVendorsCount: 0,
      vendorsLoading: true,
      page: 1,
      pageSize: 30,
      searchString: '',
      orderBy: 'title',
      orderType: 'asc',
    }
  },
  computed: {
    computedTableProps() {
      return {
        ...this.defaultTableProps,
        ...(this.tableProps || {}),
      };
    },
  },
  mounted() {
    this.fetchVendors()
  },
  methods: {
    getVendorUrl(vendorId) {
      return `/admin/vendors/${vendorId}`
    },
    async fetchVendors (page, limit) {
      page = page || this.page
      limit = limit || this.pageSize

      this.vendorsLoading = true

      try {
        let queryParamsString = `page=${page}&limit=${limit}`

        if (this.searchString) {
          queryParamsString += `&search_string=${this.searchString}`
        }
        if (this.orderBy && this.orderType) {
          queryParamsString += `&order_type=${this.orderType}&order_by=${this.orderBy}`
        }

        const response = await $adminAxios.get(`/vendors?${queryParamsString}`)
        this.vendors = response.data.data
        this.totalVendorsCount = response.data.total
      } catch (err) {
        console.error(err)
        this.$notify.success({
          title: 'Error',
          message: err.message || 'Something went wrong',
          type: 'error'
        });
      }

      this.vendorsLoading = false
    },
    handlePageChange(page) {
      this.fetchVendors(page, this.size)
    },
    handlePageSizeChange(size) {
      this.fetchVendors(this.page, size)
    },
    createVendor() {
      this.$router.push('/admin/vendors/create')
    },
    searchData() {
      this.page = 1
      this.fetchVendors()
    },
    sortChange(el) {
      this.orderBy = el.prop
      this.orderType = el.order === 'ascending' ? 'asc' : 'desc'
      this.page = 1
      this.fetchVendors()
    },
  },
}
</script>

<style lang="scss" scoped>
.customer-details-scroll {
  position: relative;
  height: calc(100vh - 75px);
  margin-top: 10px;
}
</style>
