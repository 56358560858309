<template>
  <div class="main-body">
    <div style="margin-left: 20px">
      <el-scrollbar class="customer-details-scroll">
        <el-row>
          <h3 style="margin-bottom: 20px">{{ orderId }} Order Information</h3>

          <main class="col-md-9">
            <article class="card">
              <div class="card-header">
                <div class="d-flex justify-content-between align-items-center">
                  <div class="">
                    <strong class="d-inline-block mr-3">Order ID: {{ order.auto_id }},</strong>
                    <span> {{ orderPlacedDate(order) }} </span>
                  </div>
                  <div class="">
                    <p class="text-right mb-0 mr-1">
                      <span v-if="order.financial_status === 'pending'" class="badge badge-warning">{{ 'Payment Pending' }} </span>
                      <span v-else-if="order.financial_status === 'failed'" class="badge badge-danger"> {{ 'Payment Failed' }} </span>
                      <span v-else-if="order.financial_status === 'cancelled'" class="badge badge-danger"> {{ 'Payment Cancelled' }} </span>
                      <span v-else-if="order.financial_status !== 'paid'" class="badge badge-warning"> {{ toTitleCase(order.financial_status) }} </span>
                      <span v-else-if="order.financial_status === 'paid' && order.status === 'delivered'" class="badge badge-success"> Delivered </span>
                      <span v-else-if="order.financial_status === 'paid' && order.status === 'cancelled'" class="badge badge-danger"> Cancelled </span>
                      <span v-else-if="order.financial_status === 'paid'" class="badge badge-info">{{ toTitleCase(order.status) }} </span>
                    </p>
                  </div>
                </div>
                <div class="d-flex justify-content-between align-items-center mb-10">
                  <div class="">
                    <span><strong>Delivery Date:</strong> {{ orderDeliveryDate(order) }} </span>
                  </div>
                </div>
                <div class="d-flex justify-content-between align-items-center mb-10">
                  <div class="">
                    <span><strong>Payment Status:</strong>
                      <span v-if="!paymentEdit">
                        {{ order.financial_status }}
                      </span>
                      <span v-else>
                        <el-select @change="updateOrderStatus('financial_status')" v-model="orderStatusTypesData.financial_status" placeholder="Select">
                            <el-option
                              v-for="item in orderPaymentStatuses"
                              :key="item"
                              :label="item"
                              :value="item">
                            </el-option>
                        </el-select>
                      </span>
                      <span>
                        <span @click="paymentHideAndEnable(true)" v-if="!paymentEdit"><u>Edit</u></span>
                        <span @click="paymentHideAndEnable(false)" v-else><u>Hide</u></span>
                      </span>
                    </span>
                  </div>
                </div>
                <div class="d-flex justify-content-between align-items-center">
                  <div class="">
                    <span><strong>Order Status: </strong>
                      <el-select @change="updateOrderStatus('status')" v-model="orderStatusTypesData.status" placeholder="Select">
                        <el-option
                          v-for="item in orderStatuses"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value">
                        </el-option>
                      </el-select>
                    </span>
                  </div>
                </div>
                <div class="">
                  Payment status in razorpay:
                  <span>
                    <b>{{ orderStatusInRazorPay }}</b>
                  </span>
                  &nbsp;&nbsp;
                  <el-button size="small" @click="fetchRazorPayOrderDetails">
                    Check Now
                  </el-button>
                </div>
                <div class="" v-if="order.status === 'cancelled'">
                  Cancelled Reason: {{ order.cancelled_reason }}
                </div>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-md-4 col-sm-6 col-6">
                    <h4 class="text-muted">Delivery Address</h4>
                    <p>
                      {{ shippingAddress.name }} <br />
                      Address: {{ shippingAddress.line1 }} <br />
                      Landmark: {{ shippingAddress.landmark }} <br />
                      Pincode: {{ shippingAddress.pincode }} <br />
                      City: {{ shippingAddress.city }} <br />
                      Phone: {{ shippingAddress.phone }} <br />
                      Alternate Phone: {{ shippingAddress.alternative_phone || '--' }} <br />
                    </p>
                  </div>
                  <div class="col-md-4 col-sm-6 col-6">
                    <h4 class="text-muted">Billing Address</h4>
                    <p>
                      {{ billingAddress.name }} <br />
                      Address: {{ billingAddress.line1 }} <br />
                      Landmark: {{ billingAddress.landmark || '--' }} <br />
                      Pincode: {{ billingAddress.pincode }} <br />
                      City: {{ billingAddress.city }} <br />
                      Phone: {{ billingAddress.phone }} <br />
                      Alternate Phone: {{ billingAddress.alternative_phone || '--' }} <br />
                    </p>
                  </div>
                  <div class="col-md-4 col-sm-6">
                    <h4 class="text-muted">Payment</h4>
                    <!-- <span class="text-success">
                      <i class="fab fa-lg fa-cc-visa"></i>
                      Visa **** 4216
                    </span> -->
                    <p>
                      Subtotal: &#8377;{{order.sub_total_price}} <br />
                      Shipping fee: &#8377;{{order.total_shipping_price || 0}} <br />
                      Discount: &#8377;{{order.total_discount || 0}} <br />
                      <span class="b">Total: &#8377;{{order.total_price}} </span><br/>
                      RP Order Id: {{order.razorpay_order_id}} <br/>
                      RP Payment Id: {{order.razorpay_payment_id}} <br/>
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4 col-sm-6 col-6">
                    <h4 class="text-muted">Sender Details</h4>
                    <p>
                      Sender Name: {{ order.sender_name }}
                      <br/>
                      Sender Phone: {{ order.sender_phone }}
                    </p>
                  </div>
                </div>
                <!-- row.// -->
              </div>
              <div class="table-responsive"
                  style="border-top: 1px solid rgba(0,0,0,.125);">
                <table class="table">
                  <tbody>
                    <tr v-for="(item, index) of order.items" :key="index">
                      <td width="65">
                        <a :href="getProductUserUrl(item.product_details.url)" target="_blank">
                          <img
                            :src="getProductFirstImageUrlFromImages(item.product_details.images)"
                            class="img-xs border"
                          />
                        </a>
                      </td>
                      <td>
                        <p class="title mb-0">{{ (item.product_details && item.product_details.title) || '' }} </p>
                        <var class="price text-muted">&#8377; {{ item && item.product_variant && (item.product_variant.sale_price || item.product_variant.strike_price) || 0 }}, {{ individualPrice(item.auto_id) }}</var>
                      </td>
                      <td style="line-height: 1.5">
                        <span v-if="item.variant_details.Size && item.product_details.product_type !== 'gifts'">
                          {{ getOptions().labelConstants['Size'][item.product_details.product_type] }}: {{ item.product_variant._so.Size }}<br />
                        </span>
                        <span v-if="item.product_details.product_type === 'cakes' || item.has_cake">
                          Message On Cake: {{ item.message_on_cake || '' }} <br />
                          Eggless: {{ item.egg_less ? 'Yes' : 'No' }} <br />
                        </span>
                        Quantity: {{ item.quantity || 1 }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- table-responsive .end// -->
            </article>
          </main>
        </el-row>

        <br/><br/>
        <el-row>
          <h3>Copy Details <i class="el-icon-copy-document pointer" @click="copyMarkup"></i></h3>
          <br/>
          <br/>
          <div class="row alert alert-success" >
            <main class="col-md-9" ref="orderMarkUp">
              <p>*Order Id:* {{ order.auto_id }}</p>
              <p>*Flavour:* {{ order.items && order.items[0] && order.items[0].product_details && order.items[0].product_details.title }}{{ order.items[0].egg_less ? ' - Eggless' : '' }}</p>
              <p>*Weight:* {{ order.items && order.items[0].product_variant && order.items[0].product_variant._so && order.items[0].product_variant._so.Size }}</p>
              <p>*Message on Cake:* {{ order.items && order.items[0].message_on_cake }}</p>
              <br/>
              <p>*Time:* General ({{ order.delivery_time_slot }})</p>
              <p>*Date:* {{ getDateForCopy(order) }}</p>
              <br/>
              <p>*Address:* {{order.shipping_address.line1}} {{order.shipping_address.pincode }}, Landmark: {{order.shipping_address.landmark}}</p>
              <br/>
              <p>*Phone number:* {{ order.shipping_address.phone }}</p>
              <p>Alternative Phone number: {{ order.shipping_address.alternative_phone ? order.shipping_address.alternative_phone : '--' }}</p>
              <br/>
            </main>
          </div>
        </el-row>
      </el-scrollbar>
    </div>
  </div>
</template>

<script>
import { $adminAxios } from "../config/axios";
import CONFIG from '@/config/app'
import moment from "moment";

export default {
  computed: {
    shippingAddress() {
      return (this.order && this.order.shipping_address) || {}
    },
    billingAddress() {
      return (this.order && this.order.billing_address) || {}
    },
    orderDeliveryDate() {
      return order => {
        const deliveryDateInDiffFormat = order.delivery_date_string && order.delivery_date_string.split('-').reverse().join('-')
        return `${deliveryDateInDiffFormat}, ${order.delivery_time_slot_name} (${order.delivery_time_slot})`
      }
    },
    orderPlacedDate() {
      return order => {
        return moment(order.created_at).format('MMM DD, yy hh:mm A')
      }
    },
    getFullImageUrl() {
      return url => {
        return url ? `${this.underscoreConfig.s3ProductImagesBaseUrl}/${url}` : `${this.underscoreConfig.s3ProductImagesBaseUrl}/${this.underscoreConfig.productSampleUrl}`
      }
    },
    individualPrice() {
      return itemId => {
        const item = this.order.items.find(el => el.auto_id === itemId)
        let cost = (item.quantity || 1) * item.product_variant.sale_price

        if (item.egg_less) {
          const eggLessCost = Number((item.product_variant._so.Size).split(/kg/i)[0]) * 100 // todo update eggless cost from vendor data
          cost += (item.quantity || 1) * eggLessCost
        }

        return cost
      }
    },
  },
  data: function () {
    return {
      orderId: null,
      order: {},
      mainLoading: true,
      s3ProductsBaseUrl: CONFIG.PRODUCT_IMAGES_URL,
      productSampleUrl: CONFIG.product_images_sample_image_url,
      underscoreConfig: {
        imagesBaseUrl: CONFIG.PRODUCT_IMAGES_URL,
        s3ProductImagesBaseUrl: CONFIG.PRODUCT_IMAGES_URL,
        productSampleUrl: CONFIG.product_images_sample_image_url,
      },
      orderStatuses: [{
        value: 'open',
        label: 'Not Acknowledged',
      }, {
        value: 'processed',
        label: 'Accepted',
      }, {
        value: 'out_for_delivery',
        label: 'Out For Delivery',
      }, {
        value: 'delivered',
        label: 'Delivered',
      }, {
        value: 'cancelled',
        label: 'Cancelled',
      }, {
        value: 'backordered',
        label: 'Back Ordered (Duplicate Order)',
      }],
      orderPaymentStatuses: ['pending', 'authorized', 'paid', 'partially_paid', 'refunded', 'partially_refunded', 'voided', 'failed', 'cancelled'],
      orderStatusTypesData: {},
      paymentEdit: false,
      orderStatusInRazorPay: '',
    };
  },
  mounted() {
    this.orderId = this.$route.params.order_id;
    this.fetchOrder();
  },
  methods: {
    getProductImagesFullUrl (url) {
      return `${this.underscoreConfig.imagesBaseUrl}/${url || this.productSampleUrl}`
    },
    getProductFirstImageUrlFromImages (images) {
      return this.getProductImagesFullUrl(images && images[0] && images[0].src)
    },
    getProductUserUrl(productUrl) {
      console.log(CONFIG)
      return CONFIG.USER_WEBSITE_URL + '/gift/' + productUrl
    },
    getOptions() {
      return {
        labelConstants: {
          Size: {
            cakes: 'Weight',
            flowers: 'Units',
            combos: 'Units',
            chocolates: 'Units',
          },
        }
      }
    },
    toTitleCase (phrase = '') {
      return phrase
        .toLowerCase()
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ')
    },
    async fetchOrder(data) {
      this.mainLoading = true;

      const url = `/orders/${this.orderId}`;

      try {
        const response = await $adminAxios.get(url, data);

        const orderData = response.data.data;

        this.order = orderData;
        this.orderStatusTypesData = {
          financial_status: this.order.financial_status,
          status: this.order.status,
        }
      } catch (err) {
        console.error(err);
        this.$notify.error({
          title: "Error",
          message: err.message || "Something went wrong",
          type: "error",
        });
      }

      this.mainLoading = false;
    },
    async fetchRazorPayOrderDetails() {
      this.mainLoading = true;

      const url = `/orders/${this.orderId}/razorpay-order-details`;

      try {
        const response = await $adminAxios.get(url);

        if (response.data.data.amount_due === 0) {
          this.orderStatusInRazorPay = 'Paid'
        } else {
          this.orderStatusInRazorPay = 'Not paid'
        }
      } catch (err) {
        console.error(err);
        this.$notify.error({
          title: "Error",
          message: err.message || "Something went wrong",
          type: "error",
        });
      }

      this.mainLoading = false;
    },
    async updateOrderDetails(url, data) {
      this.mainLoading = true;

      try {
        await $adminAxios.patch(url, data);

        this.$notify.success({
          title: "Success",
          message: "Successfully updated order details",
          type: "success",
        });
      } catch (err) {
        console.error(err);
        this.$notify.error({
          title: "Error",
          message: err.message || "Something went wrong",
          type: "error",
        });
      }

      this.mainLoading = false;
    },
    async updateOrderStatus(statusType) {
      const data = {
        status_type: statusType,
        status_value: this.orderStatusTypesData[statusType]
      }

      const url = `/orders/${this.orderId}/status`;

      this.updateOrderDetails(url, data)
    },
    paymentHideAndEnable(status) {
      this.paymentEdit = status
    },
    getDateForCopy(order) {
      return moment.utc(order.delivery_date_string).format('DD MMM, yyyy')
    },
    copyMarkup() {
      const htmlElement = this.$refs.orderMarkUp;

      let data = ''

      htmlElement.childNodes.forEach((el, index) => {
        if (el.tagName === 'BR') {
          data += '\n'
        } else {
          data += index > 0 ? '\n' + el.textContent : el.textContent
        }
      })

      navigator.clipboard.writeText(data);
      this.$notify.success({
        title: "Success",
        message: "Copied",
        type: "success",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.customer-details-scroll {
  position: relative;
  height: calc(100vh - 75px);
  margin-top: 10px;
}

td img {
  width: 75px;
}

.d-flex {
  display: flex;
}

.card-header {
  padding: 0.75rem 1.25rem !important;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.card-header {
  padding: 0.75rem 1.25rem !important;
}

.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-body {
  flex: 1 1 auto;
  padding: 1rem 1rem;
}

p {
  color: #333;
}
p {
  line-height: 30px;
}
p {
  margin-top: 0;
  margin-bottom: 1rem;
}

table {
  width: 100%;
}

.table > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
  background-color: var(--bs-table-bg);
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}

.align-items-center {
  align-items: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}
</style>

<style>
.mb-0 {
  margin-bottom: 0;
}
.mb-10 {
  margin-bottom: 10px;
}
.mb-20 {
  margin-bottom: 20px;
}

.alert-success {
    color: #3c763d;
    background-color: #dff0d8;
    border-color: #d6e9c6;

}
.alert-success p {
  color: #3c763d;
  margin-bottom: 0px;
}
.alert {
    padding: 15px;
    margin-bottom: 20px;
    border: 1px solid transparent;
    border-radius: 4px;
}
.pointer {
  cursor: pointer;
}
</style>
