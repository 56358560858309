<template>
  <div class="main-body" v-loading="productLoading">
    <h3>Template Products</h3>
    <br/>
    <el-tabs type="card" v-model="firstTab">
      <el-tab-pane name="mainProductInformation" label="Product Main Information">
        <product-main-information-update @updateData="updateProductmainInformation" :productId="productId" :productInformation="productInformation" />
      </el-tab-pane>
      <el-tab-pane name="metaData" label="Meta Data">
        <product-meta-data-update @updateData="updateMetaData" :metaDataInformation="metaDataInformation" />
      </el-tab-pane>
      <el-tab-pane name="images" label="Images">
        <product-images-update :productId="productId" :productImages="productImages" :productUrl="productInformation.url" />
      </el-tab-pane>
      <el-tab-pane name="variants" label="Variants">
        <product-variants-data-update-new
          :product="product"
        />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { $adminAxios } from '../config/axios'
import moment from 'moment'

import productMetaDataUpdate from '@/components/admin/productUpdate/productMetaDataUpdate'
import productMainInformationUpdate from '@/components/admin/productUpdate/productMainInformationUpdate.vue'
import productImagesUpdate from '@/components/admin/productUpdate/productImagesUpdate.vue'
import productVariantsDataUpdateNew from '@/components/admin/productUpdate/productVariantsDataUpdateNew.vue'

export default {
  components: {
    // editor: Editor
    productMainInformationUpdate,
    productMetaDataUpdate,
    productImagesUpdate,
    productVariantsDataUpdateNew,
  },
  data() {
    return {
      productInformation: {},
      metaDataInformation: {},
      product: {},
      productLoading: true,
      firstTab: "variants",
      productId: null,
      productImages: [],
    }
  },
  mounted() {
    const productId = this.$route.params.product_id
    this.productId = productId

    this.fetchProduct()
  },
  methods: {
    async fetchProduct () {
      this.productLoading = true

      try {
        const response = await $adminAxios.get(`/template-products/${this.productId}`)
        this.product = response.data.data
        this.productInformation = {
          title: this.product.title,
          sku: this.product.sku,
          description: this.product.description,
          stock_availability: this.product.stock_availability,
          status: this.product.status,
          new_description: this.product.new_description,
          care_instructions_description: this.product.care_instructions_description,
          delivery_information_description: this.product.delivery_information_description,
          please_note_description: this.product.please_note_description,
          extra_description: this.product.extra_description,
          url: this.product.url,
          location_id: this.product.location_id,
          location_data: this.product.location_data,
          categories: this.product.categories,
          categories_data: this.product.categories_data,
          main_category_id: this.product.main_category_id,
          product_type: this.product.product_type,
          can_eggless: this.product.can_eggless,
          vendor_data: this.product.vendor_data,
          total_rating: this.product.total_rating,
          total_votes: this.product.total_votes,
          main_tag: this.product.main_tag,
          earliest_delivery: this.product.earliest_delivery,
        }

        const metaData = this.product.meta_data || {}

        this.metaDataInformation = {
          title: metaData.title,
          description: metaData.description,
          keywords: metaData.keywords,
          auto_id: metaData.auto_id,
        }

        this.productImages = this.product.images.map(el => { return { auto_id: el.auto_id, src: el.src, alt_text: el.alt_text, _id: el._id } })
      } catch (err) {
        console.error(err)
        this.$notify.error({
          title: 'Error',
          message: err.message || 'Something went wrong',
          type: 'error'
        });
      }

      this.productLoading = false
    },

    async updateProductmainInformation (data) {
      if (data.categories) {
        data.categories = [...new Set(data.categories)]
      }

      await this.updateData(data, 'mainProductInformation')
    },
    async updateMetaData() {
      const data = this.metaDataInformation

      if (data.auto_id) {
        await this.updateData(data, 'metaDataUpdate')
      } else {
        await this.updateData(data, 'metaDataCreate')
      }
    },
    async updateData (data, updateType) {
      const productId = this.$route.params.product_id

      this.productLoading = true

      let url
      let method = 'patch'
      if (updateType === 'mainProductInformation') {
        url = `/template-products/${productId}/main-product-information`
      } else if (updateType === 'metaDataUpdate') {
        url = `/template-products/${productId}/meta-data/${data.auto_id}`
      } else if (updateType === 'metaDataCreate') {
        url = `/template-products/${productId}/meta-data`
        method = 'post'
      }

      console.log(url)

      try {
        await $adminAxios[method](url, data)

        this.$notify.success({
          title: 'Success',
          message: 'Successfully updated product',
          type: 'success'
        });
      } catch (err) {
        console.error(err)
        this.$notify.error({
          title: 'Error',
          message: err.message || 'Something went wrong',
          type: 'error'
        });
      }

      this.productLoading = false
    }
  }
}
</script>
