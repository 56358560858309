<template>
  <div>
    <el-scrollbar class="customer-details-scroll">
      <div style="margin-top: 40px;">
        <el-form>
          <el-row>
            <el-col :span="6">
              <el-form-item label="Price Model">
                <el-select
                  v-model="selectedPricingModel"
                  :loading="fetchPricingModelsLoading"
                  remote
                  :remote-method="fetchPricingModels"
                  filterable
                  placeholder="Select Pricing Model"
                  style="display: block;"
                  :clearable="true"
                  @change="pricingModelChange"
                >
                  <el-option v-for="pricingModel of pricingModelNames" :key="pricingModel.name" :label="pricingModel.new_name" :value="pricingModel.value"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="4">
              <el-select placeholder="Select option" v-model="optionKeys" :multiple="true">
                <el-option v-for="option of fullOptions" :key="option" :label="option" :value="option"></el-option>
              </el-select>
            </el-col>
            <el-col :span="6">
              <el-input v-model="skuSuffix" placeholder="sku suffix"></el-input>
            </el-col>
            <el-button style="margin-left: 10px;" type="default" @click="addVariant">Add Variant</el-button>
          </el-row>
        </el-form>

        <el-form>
          <el-table
            class="table"
            :data="variants"
            style="width: 100%; text-align: left; margin-top: 10px;">
            <el-table-column
              label="Size"
              prop="Size"
              >
              <template slot-scope="{ row, $index }">
                <el-input v-model="row._op.Size" :key="getKey('Size', $index)"></el-input>
              </template>
            </el-table-column>
            <el-table-column
              v-if="optionKeysMap['Flavour']"
              label="Flavour"
              prop="Flavour">
              <template slot-scope="{ row }">
                <el-input v-model="row._op.Flavour"></el-input>
              </template>
            </el-table-column>
            <el-table-column
              label="Bakery Price"
              prop="bakery_price">
              <template slot-scope="{ row, $index }">
                <p v-if="!row.manual">{{ addSymbol(row.bakery_price) }} </p>
                <el-input v-else @input="chagePriceForUs($event, $index)" v-model="row.bakery_price"></el-input>
              </template>
            </el-table-column>
            <el-table-column
              label="Price For Us"
              prop="price_for_us">
              <template slot-scope="{ row }">
                <p>{{ addSymbol(row.price_for_us) }} </p>
              </template>
            </el-table-column>
            <el-table-column
              label="Strike Hike"
              >
              <template slot-scope="{ row }">
              <!-- <template slot-scope="{ row, $index }"> -->
                <el-input v-model="row.hike_value"></el-input>

                <!-- <el-popover
                  placement="bottom-start"
                  width="220"
                  popper-class="position-fixed-popover"
                  v-model="row.pop_visible">
                  <p>Apply new percentage <br/> {{ suggestedHikePercentage }}% = {{ addSymbol(suggestedStrikePrice) }} ?</p>
                  <div style="text-align: right; margin: 0;">
                    <el-button size="mini" type="primary" @click="updateSuggestedHikePercentage($index, true)">confirm</el-button>
                    <el-button type="text" size="mini" @click="updateSuggestedHikePercentage($index, false)">cancel</el-button>
                  </div>
                </el-popover> -->
              </template>

            </el-table-column>
            <el-table-column
              label="Strike Price"
              >
              <template slot-scope="{ row }">
                <p>{{ addSymbol(strikeValue(row)) }}</p>
              </template>

            </el-table-column>
            <el-table-column
              label="Sale Discount">
              <!-- <template slot-scope="{ row, $index }"> -->
              <template slot-scope="{ row }">
                <el-input v-model="row.discount_value"></el-input>

                <!-- <el-popover
                  placement="bottom-start"
                  width="220"
                  popper-class="position-fixed-popover"
                  v-model="row.discount_pop_visible">
                  <p>Apply new percentage <br/> {{ suggestedDiscountPercentage }}% = {{ addSymbol(suggestedSalePrice) }} ?</p>
                  <div style="text-align: right; margin: 0;">
                    <el-button size="mini" type="primary" @click="updateSuggestedDiscountPercentage($index, true)">confirm</el-button>
                    <el-button type="text" size="mini" @click="updateSuggestedDiscountPercentage($index, false)">cancel</el-button>
                  </div>
                </el-popover> -->
              </template>
            </el-table-column>
            <el-table-column
              label="Sale Price">
              <template slot-scope="{ row }">
                <p><b>{{ addSymbol(saleValue(row)) }}</b></p>
              </template>
            </el-table-column>
            <el-table-column
              label="Extra Variant Cost"
              >
              <template slot-scope="{ row }">
                <el-input v-model="row.extra_variant_cost"></el-input>
              </template>

            </el-table-column>
            <el-table-column
              label="Margin">
              <template slot-scope="{ row }">
                <p>{{ addSymbol(margin(row)) }}%</p>
              </template>
            </el-table-column>
            <el-table-column
              label="Profit">
              <template slot-scope="{ row }">
                <p>{{ addSymbol(profit(row)) }}</p>
              </template>
            </el-table-column>
            <el-table-column
              label="SKU"
              prop="sku">
              <template slot-scope="{ row }">
                <el-input v-model="row.sku"></el-input>
              </template>
            </el-table-column>
            <el-table-column
              label="Status"
              prop="status">
              <template slot-scope="{ row }">
                <el-select v-model="row.status" placeholder="Select Status">
                  <el-option label="Active" value="ACTIVE"></el-option>
                  <el-option label="Archive" value="ARCHIVED"></el-option>
                </el-select>
              </template>
            </el-table-column>
            <el-table-column
              label="Stock Availability"
              >
              <template slot-scope="{ row, $index }">
                <el-checkbox v-model="row.stock_availability" name="type"></el-checkbox>
                <el-button style="margin-left: 4px; color: red;" @click="deleteRow($index)" type="text" size="small">Delete</el-button>
              </template>
            </el-table-column>
          </el-table>

          <!-- We are looping the cake sizes and giving option to add easy products from max size item present in pricing model -->
          <el-row v-if="product.product_type === 'cakes' && (product.pricing_model || newPricingModel)" style="margin-top: 30px;">
            <el-col :span="24">
              <el-button v-for="cakeSize of cakeSizes" :key="cakeSize" type="default" @click="addVariant(cakeSize)">{{ cakeSize }}</el-button>
            </el-col>
          </el-row>

          <el-row v-if="!onlyOneVariant" style="margin-top: 30px;">
            <el-col :span="6">
                <el-button :disabled="updateProductLoading" type="primary" @click="updateData">Update</el-button>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </el-scrollbar>
  </div>
</template>

<script>
import { $adminAxios } from '../../../config/axios'
import { unionOfArrays } from '../../../mixins/varibleHelpers'
import priceMixin from '../../../mixins/priceMixin'

export default {
  mixins: [
    priceMixin,
  ],
  props: [
    'product',
  ],
  data() {
    return {
      suggestedHikePercentage: null,
      suggestedStrikePrice: null,
      suggestedDiscountPercentage: null,
      suggestedSalePrice: null,
      options: [],
      optionKeys: ['Size'],
      variants: [],
      onlyOneVariantVarints: [{}],
      onlyOneVariantOptions: [],
      onlyOneVariant: true,
      productLoading: true,
      updateProductLoading: false,
      skuSuffix: null,
      fetchPricingModelsLoading: false,
      pricingModels: [],
      pricingModelNames: [],
      pricingModelSearchString: null,
      selectedPricingModel: null,
      newPricingModel: null,
      defaultOptionsKeys: [
        'Size',
        'Flavour',
      ],
      variantKeys: [{
        label: 'Title',
        key: 'title',
        type: "string",
      }, {
        label: 'SKU',
        key: 'sku',
        type: "string",
      }, {
        label: 'Stock Availability',
        key: 'stock_availability',
        type: "boolean",
      }, {
        label: 'Price',
        key: 'price',
        type: "number",
      }, {
        label: 'Sale Price',
        key: 'sale_price',
        type: "number",
      }, {
        label: 'Actual Price',
        key: 'actual_price',
        type: "number",
      }],
      cakeSizes: [
        '0.5 Kg', '1 Kg', '1.5 Kg', '2 Kg', '2.5 Kg', '3 Kg', '4 Kg', '5 Kg', '6 Kg', '7 Kg', '8 Kg', '9 Kg', '10 Kg',
      ],
    }
  },
  methods: {
    async updateSingleVariant() {
      this.updateProductLoading = true
      try {
        const data = {
          variants: this.onlyOneVariantVarints,
          options: this.onlyOneVariantOptions,
          has_only_default_variant: this.onlyOneVariant,
        }

        await $adminAxios.put(`/products/${this.productId}/variants-and-options`, data)

        this.$notify.success({
          title: 'Success',
          message: 'Successfully updated variants and options',
          type: 'success'
        });
      } catch (err) {
        this.$notify.error({
          title: 'Error',
          message: err.message || 'Something went wrong',
          type: 'error'
        });
      }
      this.updateProductLoading = false
    },
    async updateData() {
      this.updateProductLoading = true

      const newVariants = this.prepareFinalVariants()
      const newOptions = this.prepareFinalOptions()

      try {
        const data = {
          variants: newVariants,
          options: newOptions,
          has_only_default_variant: this.onlyOneVariant,
          pricing_model: this.selectedPricingModel,
        }

        await $adminAxios.put(`/products/${this.productId}/variants-and-options`, data)

        this.$notify.success({
          title: 'Success',
          message: 'Successfully updated variants and options',
          type: 'success'
        });
      } catch (err) {
        this.$notify.error({
          title: 'Error',
          message: err.message || 'Something went wrong',
          type: 'error'
        });
      }

      this.updateProductLoading = false
    },
    prepareFinalVariants() {
      let variantPostion = 1

      const newVariants = this.variants.map(variant => {
        variant = JSON.parse(JSON.stringify(variant, null, 2))
        variant.selected_options = this.optionKeys.map(option => {
          return {
            title: option,
            value: variant._op[option],
          }
        })

        variant.position = variantPostion

        variantPostion++

        this.optionKeys.forEach(option => {
          delete variant[option]
        })

        return variant
      })

      newVariants.sort((a, b) => a.price_for_us - b.price_for_us)

      return newVariants
    },
    prepareFinalOptions() {
      let optionPosition = 1

      const newOptionsMap = new Map()

      const newOptions = []

      this.options.forEach(el => {
        if (!this.optionKeysMap[el.title]) {
          return
        }

        newOptionsMap[el.title] = true

        el.position = optionPosition
        el.values = this.variants.map(variant => variant._op[el.title])

        newOptions.push(el)

        optionPosition++
      })

      this.optionKeys.forEach(el => {
        if (newOptionsMap[el]) {
          return
        }

        const data = {
          title: el,
          position: optionPosition,
          values: this.variants.map(variant => variant[el]),
        }

        newOptions.push(data)

        optionPosition++
      })

      return newOptions
    },
    addVariant(Size) {
      const defaultData = this.generateDefaultVariantData()

      const manual = true
      let data = {}

      const models = this.newPricingModel ? this.pricingModels.filter(el => el.name === this.newPricingModel) : (this.product.pricing_models || [])

      const maxSizeBaseData = models.map(el => {
        return el.value.toLowerCase().replace('kg', '').replace(/s+/, '')
      })

      const maxSizeValue = JSON.parse(JSON.stringify(maxSizeBaseData)).sort((a, b) => b - a)[0]
      const maxSizeIndex = maxSizeBaseData.findIndex(el => el === maxSizeValue)

      if (Size) {
        const SizeValue = Size.toLowerCase().replace('kg', '').replace(/s+/, '')

        const maxSizeData = maxSizeIndex !== -1 ? models[maxSizeIndex] : {}
        const pricePerKg = maxSizeData.bakery_price / maxSizeValue

        const bakeryPriceForNewItem = pricePerKg * SizeValue

        data = this.prepareVariant(Size, bakeryPriceForNewItem, this.variants.length)

        data.manual = false
      }

      this.variants.push({
        ...defaultData,
        manual,
        ...data,
      })
    },
    deleteVariant(index) {
      this.variants.splice(index, 1)
    },
    generateDefaultVariantData() {
      const sku = this.generateDefaultSku()
      const status = this.generateDefaultStatus()
      const stockAvailability = true

      return {
        sku,
        status,
        stock_availability: stockAvailability,
        _op: {},
      }
    },
    generateDefaultSku() {
      if (!this.skuSuffix) {
        return null
      }

      const sku = `${this.skuSuffix}${this.variants.length + 1}`
      return sku
    },
    generateDefaultStatus() {
      return 'ACTIVE'
    },
    async fetchPricingModels (str) {
      this.fetchPricingModelsLoading = true
      this.pricingModelSearchString = str
      try {
        let url = `/locations/${this.product.location_id}/pricing-models?get_all=true`
        if (str) {
          url = url + '&name=' + str
        }

        const response = await $adminAxios.get(url)
        if (str === this.pricingModelSearchString) {
          this.pricingModels = response.data.data
          const dataMap = (this.pricingModels || []).reduce((acc, cur) => {
            acc[cur.name] = {
              new_name: `${cur.name} ${cur.type}`,
              value: cur.name,
            }

            return acc
          }, new Map())

          this.pricingModelNames = Object.values(dataMap)
        }
      } catch (err) {
        // nothing
      }
      this.fetchPricingModelsLoading = false
    },
    pricingModelChange(item) {
      if (!item) {
        return
      }

      if (this.variants.length) {
        this.$confirm('Are you sure to replace previous all variants?', 'Warning', {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning'
        }).then(() => {
          this.assignPricingModelsToVariant(item)
        }).catch(() => {
          /* nothing */
        });
      } else {
        this.assignPricingModelsToVariant(item)
      }
    },
    assignPricingModelsToVariant(item) {
      this.newPricingModel = item
      const models = this.pricingModels.filter(el => el.name === this.newPricingModel)

      const pricingsMap = (models || []).reduce((acc, cur) => {
        acc[cur.value] = cur.bakery_price
        return acc
      }, new Map())

      const newVariants = models.map((pricingModel, index) => {
        const data = this.prepareVariant(pricingModel.value, pricingModel.bakery_price, index)

        data.bakery_price = pricingsMap[data.Size] || data.bakery_price
        data.price_for_us = data.bakery_price - ((this.vendorDiscount * data.bakery_price) / 100)

        return data
      })

      this.variants = newVariants.sort((a, b) => a.price_for_us - b.price_for_us)
    },
    // prepare variant based inputs
    prepareVariant(Size, bakeryPrice, index) {
      const data = {
        Size: Size,
        title: Size,
        bakery_price: bakeryPrice,
        price_for_us: bakeryPrice - ((this.vendorDiscount * bakeryPrice) / 100),
        sku: `${this.skuSuffix}${index + 1}`,
        status: 'ACTIVE',
        stock_availability: true,
        hike_value: 0,
        discount_value: 0,
        extra_variant_cost: 0,
      }

      const hikeValue = this.getHikeValue(bakeryPrice, 40)
      const strikePrice = bakeryPrice + hikeValue
      const discountValue = this.getDiscountValue(strikePrice, 10)

      data.hike_value = hikeValue
      data.discount_value = discountValue

      data._op = {}
      const selectedOptions = [{ title: 'Size', value: Size }]

      selectedOptions.forEach(option => {
        data._op[option.title] = option.value
      })

      return data
    },
     changeHikePercentage(val, index) {
      const row = this.variants[index]

      const strikePrice = this.strikeValue(row)

      let newStrikePrice
      if (strikePrice < 50) {
        newStrikePrice = strikePrice
      } else if (strikePrice < 2000) {
        newStrikePrice = this.get49Or99Value(strikePrice)
      } else {
        newStrikePrice = (Math.ceil(strikePrice / 100) * 100) - 1
      }

      const newHikePercentage = this.getHikePercentageByStrikePrice(newStrikePrice, row.bakery_price).toFixed(2)

      this.suggestedHikePercentage = newHikePercentage
      this.suggestedStrikePrice = newStrikePrice

      row.pop_visible = true
    },
    addPercentage (price, percentage) {
      return price * (1 + (percentage / 100))
    },
    getHikeValue (bakeryPrice, hikePercentage) {
      const strikePrice = this.addPercentage(bakeryPrice, 40)

      return strikePrice - bakeryPrice
    },
    getDiscountValue (strikePrice, discountPercentage) {
      let salePrice = this.addPercentage(strikePrice, discountPercentage * -1)

      if (salePrice < 50) {
        // nothing to do
      } else {
        salePrice = this.get49Or99Value(salePrice)
      }

      return (strikePrice - salePrice)
    },
    changeDiscountPercentage(val, index) {
      const row = this.variants[index]

      const salePrice = this.saleValue(row)

      let newSalePrice
      if (salePrice < 50) {
        newSalePrice = salePrice
      } else {
        newSalePrice = this.get49Or99Value(salePrice)
      }

      const strikePrice = this.strikeValue(row)

      const newDiscountPercentage = this.getDiscountPercentageBySalePrice(newSalePrice, strikePrice).toFixed(2)

      this.suggestedDiscountPercentage = newDiscountPercentage
      this.suggestedSalePrice = newSalePrice

      row.discount_pop_visible = true
    },
    chagePriceForUs(val, index) {
      const row = this.variants[index]

      row.price_for_us = row.bakery_price - ((this.vendorDiscount * row.bakery_price) / 100)
    },
    get49Or99Value (price) {
      return (Math.round(price / 50) * 50) - 1
    },
    getHikePercentageByStrikePrice (strikePrice, bakeryPrice) {
      return ((strikePrice - bakeryPrice) / bakeryPrice) * 100
    },
    getDiscountPercentageBySalePrice (salePrice, strikePrice) {
      return ((strikePrice - salePrice) / strikePrice) * 100
    },
    updateSuggestedHikePercentage(index, confirmation) {
      if (confirmation) {
        this.variants[index].hike_percentage = this.suggestedHikePercentage
      }

      this.variants[index].pop_visible = false
    },
    updateSuggestedDiscountPercentage(index, confirmation) {
      if (confirmation) {
        this.variants[index].discount_percentage = this.suggestedDiscountPercentage
      }

      this.variants[index].discount_pop_visible = false
    },
    deleteRow(index) {
      this.variants.splice(index, 1)
    },
  },
  watch: {
    product(val) {
      this.productId = val.auto_id

      this.onlyOneVariant = val.has_only_default_variant

      this.skuSuffix = `${this.product.sku}`

      this.selectedPricingModel = val.pricing_model

      if (val.options && val.options.length) {
        this.options = val.options
        this.optionKeys = this.options.map(el => el.title)

        this.onlyOneVariantOptions = val.options
      } else {
        this.options = []

        this.onlyOneVariantOptions = [{}]
      }

      const pricingsMap = (val.pricing_models || []).reduce((acc, cur) => {
        acc[cur.value] = cur.bakery_price
        return acc
      }, new Map())

      if (val.variants && val.variants.length) {
        this.variants = val.variants.map(variant => {
          variant._op = {}
          const selectedOptions = variant.selected_options

          selectedOptions.forEach(option => {
            variant._op[option.title] = option.value
          })

          variant.bakery_price = pricingsMap[variant.Size] || variant.bakery_price
          variant.price_for_us = variant.bakery_price - ((this.vendorDiscount * variant.bakery_price) / 100)

          return variant
        })

        this.variants.sort((a, b) => a.price_for_us - b.price_for_us)

        this.onlyOneVariantVarints = val.variants
      } else {
        this.variants = []

        this.onlyOneVariantVarints = [{
          title: '',
          selected_options: [{}],
        }]
      }
    },
  },
  computed: {
    getKey() {
      return (key, index) => {
        return `${key}_${index}`
      }
    },
    vendorDiscount() {
      if (!this.product || !this.product.vendor_data) {
        return 0
      }

      return this.product.vendor_data.vendor_discount_percentage
    },
    margin() {
      return (row) => {
        // if (!row.hike_value || !row.discount_value) {
        //   return '0'
        // }

        const salePrice = this.saleValue(row)

        const margin = ((salePrice - row.price_for_us - (row.extra_variant_cost || 0)) / salePrice) * 100

        return margin.toFixed(2)
      }
    },
    profit() {
      return (row) => {
        // if (!row.hike_value || !row.discount_value) {
        //   return '0'
        // }

        const salePrice = this.saleValue(row)

        const profit = salePrice - row.price_for_us - (row.extra_variant_cost || 0)

        return profit.toFixed(2)
      }
    },
    fullOptions() {
      return unionOfArrays(this.defaultOptionsKeys, this.optionKeys)
    },
    fullOptionsMap() {
      return (this.fullOptions || []).reduce((acc, cur) => {
        acc[cur] = true
        return acc
      }, new Map())
    },
    optionKeysMap() {
      return this.optionKeys.reduce((acc, cur) => {
        acc[cur] = true
        return acc
      }, new Map())
    },
    fullVariantKeys() {
      return this.variantKeysFromOptions.concat(...this.variantKeys)
    },
    variantKeysFromOptions() {
      return this.optionKeys.map(option => {
        return {
          label: option,
          key: option,
          type: "string",
        }
      })
    },
    disableSignleVariant() {
      if (this.onlyOneVariant) {
        return false
      }

      if (this.options && this.options.indexOf('Title') > -1) {
        return false
      }

      return true
    },
    disableMultipleVariantsSwitch() { // we are disabling variants when single variant present. we need to update the logic regarding delete variants
      if (this.options && this.options.length) {
        return true
      }

      return false
    },
    strikeValue() {
      return (row) => {
        const bakeryPrice = Number(row.bakery_price)
        const strikePriceHike = Number(row.hike_value) || 0

        const price = bakeryPrice + strikePriceHike
        return price.toFixed(2)
      }
    },
    saleValue() {
      return (row) => {
        const salePriceDiscount = Number(row.discount_value) || 0

        const strikePrice = this.strikeValue(row)

        const price = strikePrice - salePriceDiscount
        return price.toFixed(2)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.customer-details-scroll {
  position: relative;
  height: calc(100vh - 140px);
  margin-top: 10px;
}
</style>

<style>
.position-fixed-popover {
  position: fixed;
}
</style>
