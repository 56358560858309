<template>
  <div>
    <el-form
      label-position="left"
      label-width="100px"
    >
      <div style="display: flex; justify-content: space-between;">
        <div style="display: flex; justify-content: space-between; align-items: center;">
          <el-button @click="addRow">Add Row</el-button>
          <el-select
              v-model="enabledOptions"
              placeholder="Select values"
              style="margin-left: 10px; min-width: 360px;"
              multiple
            >
              <el-option v-for="option of currentConfiguration.quantity" :label="option.title" :value="option.value" :key="option.value"></el-option>
            </el-select>
            <el-switch
              style="margin-left: 10px;"
              type="checkbox"
              v-model="actualPriceForUsEnable"
              active-color="#13ce66"
              inactive-color="#ff4949"
              active-text="Price For Us"
              inactive-text="Baker Pricey"
            ></el-switch>
        </div>
        <div>
          <el-button @click="updateData" type="success" plain>Update Details</el-button>
        </div>
      </div>
      <el-table
        :data="pricesListData"
        height="calc(100vh - 210px)"
        style="width: 100%;"
      >
        <el-table-column
          :label="getFirstColumn"
          :prop="getFirstColumn"
        >
          <template slot-scope="{ row }">
            <el-select
              v-model="row.name"
              placeholder="Select"
              filterable=""
            >
              <el-option
                v-for="option of currentConfiguration.name" :label="option" :value="option" :key="option"
                :disabled="option && optionsDisabled[option]"
              ></el-option>
            </el-select>
          </template>
        </el-table-column>

        <el-table-column
          :label="getSecondColumn"
          :prop="getSecondColumn"
        >
          <template slot-scope="{ row }">
            <el-select
              v-model="row.value"
              placeholder="Select"
            >
              <el-option v-for="option of enabledOptionsData" :label="option.title" :value="option.value" :key="option.value"></el-option>
            </el-select>
          </template>
        </el-table-column>
        <!-- <el-table-column
          label="Title"
          prop="title"
        >
          <template slot-scope="{ row }">
            <el-input v-model="row.title"></el-input>
          </template>
        </el-table-column> -->
        <el-table-column
          label="Bakery Price"
          prop="bakery_price"
        >
          <template slot-scope="{ row, $index }">
            <el-input @input="inputBakeryPrice($event, $index)" v-if="!actualPriceForUsEnable" v-model="row.bakery_price"></el-input>
            <p v-else> {{ getBakeryCost(row.actual_price_for_us) }} </p>
          </template>
        </el-table-column>
        <el-table-column
          label="Price for us"
          prop="actual_price_for_us"
        >
          <template slot-scope="{ row, $index }">
            <div style="display: flex; align-items: center;">
              <el-input @input="inputPriceForUs($event, $index)" v-if="actualPriceForUsEnable" v-model="row.actual_price_for_us"></el-input>
              <p v-else> {{ getCostForUs(row.bakery_price) }} </p>
            </div>
          </template>
        </el-table-column>

        <el-table-column
          label="Stock Availability"
        >
          <template slot-scope="{ row }">
            <el-switch
              type="checkbox"
              v-model="row.stock_availability"
              active-color="#13ce66"
              inactive-color="#ff4949"
            ></el-switch>
          </template>
        </el-table-column>

        <el-table-column
          label="Operations"
        >
          <template slot-scope="{ $index }">
            <div style="display: flex;">
              <el-button style="color: green;" @click="addRow()" type="text" size="small">Add</el-button>
              <el-button @click="cloneRow($index)" type="text" size="small">Clone</el-button>
              <el-button style="color: red;" @click="deleteRow($index)" type="text" size="small">Delete</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div style="margin-top: 10px;">
        <el-button @click="addRow">Add Row</el-button>
      </div>
    </el-form>
  </div>
</template>

<script>
export default {
  components: {},
  props: ["pricesList", "type", 'vendorInformation', "vendorId"],
  data() {
    return {
      actualPriceForUsEnable: false,
      deletedPricings: [],
      enabledOptions: [],
      pricesListData: [{
        stock_availability: true,
      }],
      configurations: {
        cakes: {
          name: [
            "Almond",
            "Almond Delight",
            "Badam",
            "Batter Almond",
            "Belgium Chocolate",
            "Black Current",
            "Black Forest",
            "Blue Berry",
            "Blue Berry Cheese",
            "Bournvita",
            "Brownie",
            "Butter Scotch",
            "Coffee",
            "Choco Almond",
            "Choco Avegen",
            "Choco Butterscotch",
            "Choco Chip",
            "Choco Dipping",
            "Choco Five Star",
            "Choco Hazelnut",
            "Choco Kitkat",
            "Choco Mocha",
            "Choco Orange",
            "Choco Rice",
            "Choco Strawberry",
            "Choco Vanilla",
            "Chocolate",
            "Chocolate Brownie",
            "Chocolate Caramel",
            "Chocolate Crunch",
            "Chocolate Evation",
            "Chocolate Excess",
            "Chocolate Fantasy",
            "Chocolate Ferrero Rocher",
            "Chocolate French",
            "Chocolate Fudge",
            "Chocolate Ganache",
            "Chocolate Gateau",
            "Chocolate Globe",
            "Chocolate M.M.S",
            "Chocolate Marble",
            "Chocolate Mousse",
            "Chocolate Mud",
            "Chocolate Nuts",
            "Chocolate Overload",
            "Chocolate Symphony",
            "Chocolate Truffle",
            "Chocolate Zebra",
            "CranBerry",
            "Dark Chocolate Truffle",
            "Dark Forest",
            "Dark Knight",
            "Death By Chocolate",
            "Duch Truffle",
            "Ferrero Rocher",
            "Flaky",
            "Flavors",
            "Fresh Fruit",
            "Fruits & Nuts",
            "German Black Forest",
            "GreenApple",
            "Hazelnut",
            "Honey Almond",
            "Honey Butterscotch",
            "Honey Coconut",
            "Honey Wal Nut",
            "Italian Chocolate",
            "Jerman Black Forest",
            "Kiwi",
            "Kitkat",
            "Light Chocolate",
            "Lychee",
            "Lychee Strawberry",
            "Mango",
            "Mango Cheese",
            "Marble",
            "Milk Chocolate Truffle",
            "Milky Badam",
            "Milky Butterscotch",
            "Mixed Dry Fruit",
            "Nutty Butterscotch",
            "Orange",
            "Pinata",
            "Pinata Premium",
            "Pineapple",
            "Pista",
            "Plum",
            "Rainbow",
            "Red Apple",
            "Red Velvet",
            "Red Velvet Cheese",
            "Red Velvet Chocolate",
            "RespBerry",
            "Rich Chocolate",
            "Snow Chocolate",
            "Strawberry",
            "Strawberry Cheese",
            "Swiss Chocolate",
            "Vanilla",
            "Vanilla Flakes",
            "Walnut Brownie",
            "Walnut",
            "White Chocolate Gautex",
            "White Forest",
            "White Truffle",
            "Fondant Vanilla",
            "Fondant Butterscotch",
            "Fondant Pineapple",
            "Fondant Blackforest",
            "Fondant Chocochips",
            "Fondant Brown Chocolate",
            "Fondant Choco Fantasy",
            "Fondant Choco Vanilla",
            "Fondant Milky Badam",
            "Fondant Milky Butterscotch",
            "Fondant Honey Almonds",
            "Fondant German Blackforest",
            "Fondant Fruit n Nuts",
            "Fondant Fresh Fruit Cake",
            "Fondant Redvelvet",
            "Fondant Any Floavour",
            "Fondant French Butter",
          ],
          quantity: [{
            title: '0.5 Kg',
            value: '0.5 Kg',
          }, {
            title: '1 Kg',
            value: '1 Kg'
          }, {
            title: '1.5 Kg',
            value: '1.5 Kg'
          }, {
            title: '2 Kg',
            value: '2 Kg'
          }, {
            title: '2.5 Kg',
            value: '2.5 Kg'
          }, {
            title: '3 Kg',
            value: '3 Kg'
          }, {
            title: '4 Kg',
            value: '4 Kg'
          }, {
            title: '5 Kg',
            value: '5 Kg'
          }, {
            title: '6 Kg',
            value: '6 Kg'
          }, {
            title: '7 Kg',
            value: '7 Kg'
          }, {
            title: '8 Kg',
            value: '8 Kg'
          }, {
            title: '9 Kg',
            value: '9 Kg'
          }, {
            title: '10 Kg',
            value: '10 Kg'
          }]
        },
        flowers: {
          name: [
            "Roses",
            "Gerberas",
            "Lilies",
            "Orchids",
            "Exotic Flowers",
            "Carnations",
            "Mixed Flowers",
          ],
          quantity: [{
            title: '1',
            value: '1',
          }, {
            title: '2',
            value: '2',
          }, {
            title: '3',
            value: '3',
          }, {
            title: '4',
            value: 4,
          }, {
            title: '5',
            value: 5,
          }, {
            title: '10',
            value: 10,
          }, {
            title: '15',
            value: 15,
          }, {
            title: '20',
            value: 20,
          }, {
            title: '25',
            value: 25,
          }, {
            title: '30',
            value: 30,
          }, {
            title: '40',
            value: 40,
          }, {
            title: '50',
            value: 50,
          }, {
            title: '60',
            value: 60
          }]
        },
        chocolates: {
          name: [
            'Bournville Small',
            'Bournville Big',
            'Bournville Large',
            'Dairy Milk Small',
            'Dairy Milk Big',
            'Dairy Milk Large',
            'Dairy Milk Silk',
            'Dairy Milk Fruit & Nut',
            'Five Star Small',
            'Five Star Big',
            'Five Star Large',
            'Kit Kat Samll',
            'Kit Kat Big',
            'Kit Kat Large',
            'Ferrero Rocher 1 Peice',
            'Ferrero Rocher 2 Peices',
            'Ferrero Rocher 3 Peices',
            'Ferrero Rocher 5 Peices',
            'Ferrero Rocher 6 Peices',
            'Ferrero Rocher 8 Peices',
            'Ferrero Rocher 9 Peices',
            'Ferrero Rocher 12 Peices',
            'Ferrero Rocher 15 Peices',
            'Ferrero Rocher 16 Peices',
            'Ferrero Rocher 24 Peices',
          ],
          quantity: [{
            title: '1',
            value: '1',
          }]
        },
        gifts: {
          name: [
            'Teddy 6 inch',
            'Teddy 9 inch',
            'Teddy 12 inch',
            'Teddy 15 inch',
            'Teddy 18 inch',
            'Teddy 21 inch',
            'Teddy 24 inch',
            'Teddy 27 inch',
            'Teddy 30 inch',
            'Mug',
            'Printed Mug',
            'Premium Mug',
            'Printed Premium Mug',
            'Cushion',
            'Printed Cushion',
          ],
          quantity: [{
            title: '1',
            value: '1',
          }]
        }
      },
    };
  },
  methods: {
    updateData() {
      this.$emit("updateData", this.type, this.pricesListData.filter(el => el.name));
    },
    addRow() {
      this.pricesListData.push({
        stock_availability: true,
      })
    },
    cloneRow(index) {
      const data = JSON.parse(JSON.stringify(this.pricesListData[index]))

      if (data.name && this.optionsDisabled[data.name]) {
        return
      }

      const oldValueWithoutUnits = Number(this.trimUnits(data.value))

      const newValue = this.currentConfiguration.quantity.filter(el => this.enabledOptionsDataMap[el.value] && this.trimUnits(el.value) > this.trimUnits(data.value))

      if (!newValue || !newValue.length) {
        return
      }

      data.value = data.value ? ((newValue && newValue[0].value) || data.value) : null

      const modelValueWithoutUnits = Number(this.trimUnits(data.value))

      data.bakery_price = (modelValueWithoutUnits / oldValueWithoutUnits) * data.bakery_price
      data.actual_price_for_us = this.getCostForUs(data.bakery_price)

      delete data.auto_id
      delete data.created_at
      delete data.updated_at
      delete data._id

      this.pricesListData.splice(index + 1, 0, data)
    },
    trimUnits (str) {
      return str.toString().replace(/[Kk][Gg]/i, '').trim()
    },
    deleteRow(index) {
      this.pricesListData.splice(index, 1)
    },
    inputBakeryPrice(val, index) {
      const priceForUs = this.getCostForUs(val)

      this.pricesListData[index].actual_price_for_us = priceForUs
    },
    inputPriceForUs(val, index) {
      const bakeryPrice = this.getBakeryCost(val)

      this.pricesListData[index].bakery_price = bakeryPrice
    },
  },
  computed: {
    optionsDisabled() {
      const selectedCount = this.pricesListData.reduce((acc, cur) => {
        const name = cur.name
        if (!acc[name]) {
          acc[name] = 0
        }

        acc[name]++

        return acc
      }, new Map())

      const count = this.enabledOptionsData.length

      const data = Object.keys(selectedCount).reduce((acc, cur) => {
        if (selectedCount[cur] >= count) {
          acc[cur] = true
        } else {
          acc[cur] = false
        }

        return acc
      }, new Map())

      return data
    },
    getCostForUs() {
      return (bakeryPrice) => {
        if (!bakeryPrice) {
          return 0
        }

        bakeryPrice = Number(bakeryPrice)

        return bakeryPrice - (bakeryPrice * ( this.vendorInformation.vendor_discount_percentage / 100 ))
      }
    },
    getBakeryCost() {
      return (actualPrice) => {
        if (!actualPrice) {
          return 0
        }

        actualPrice = Number(actualPrice)

        return actualPrice / ( 1 - ( this.vendorInformation.vendor_discount_percentage / 100 ))
      }
    },
    getColumns() {
      if (this.type === 'cakes') {
        return ['Flavour', 'Weight']
      } else if (this.type === 'flowers') {
        return ['Flowers', 'Quantity']
      } else if (this.type === 'chocolates') {
        return ['Chocolates', 'Quantity']
      } else if (this.type === 'gifts') {
        return ['Gifts', 'Quantity']
      }
      return []
    },
    getFirstColumn() {
      return (this.getColumns && this.getColumns[0]) || ''
    },
    getSecondColumn() {
      return (this.getColumns && this.getColumns[1]) || ''
    },
    currentConfiguration() {
      return this.configurations[this.type]
    },
    enabledOptionsData() {
      if (!this.currentConfiguration || !this.currentConfiguration.quantity) {
        return []
      }

      const optionsMap = this.currentConfiguration.quantity.reduce((acc, cur) => { acc[cur.value] = cur; return acc; }, new Map())

      return this.enabledOptions.map(el => optionsMap[el])
    },
    enabledOptionsDataMap() {
      return this.enabledOptionsData.reduce((acc, cur) => {
        acc[cur.value] = cur
        return acc
      }, {})
    },
  },
  watch: {
    pricesList(val) {
      if (val && val.length) {
        val = JSON.parse(JSON.stringify(val, null, 2));

        this.pricesListData = val.map(el => {
          if (el.bakery_price) {
            el.actual_price_for_us = this.getCostForUs(el.bakery_price)
          } else {
            el.bakery_price = this.getBakeryCost(el.actual_price_for_us)
          }
          return el
        })

        this.enabledOptions = [...new Set(this.pricesListData.map(el => el.value))].filter(el => el !== undefined)
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.customer-details-scroll {
  position: relative;
  height: calc(100vh - 120px);
  margin-top: 10px;
}
</style>
