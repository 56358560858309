<template>
  <div class="main-body">
      <h3>Delivered Orders Stats</h3>
      <br/>
      <div class="desktop-order-options" style="clear: both; margin-top: 15px;">
        <el-scrollbar class="customer-details-scroll">
        <div class="">
          <el-row style="margin-top: 15px;">
            <el-col :xs="12" :sm="4" :md="4" :lg="2" v-if="!isUserVendor">
              <el-select v-model="locationId" filterable placeholder="Select City">
                <el-option
                  v-for="city in cities"
                  :key="city.auto_id"
                  :label="city.title"
                  :value="city.auto_id">
                </el-option>
              </el-select>
            </el-col>
            <el-col :xs="12" :sm="4" :md="4" :lg="2">
              <el-select v-model="year" filterable placeholder="Select Year">
                <el-option
                  v-for="year in years"
                  :key="year.value"
                  :label="year.title"
                  :value="year.value">
                </el-option>
              </el-select>
            </el-col>
            <el-col :xs="12" :sm="4" :md="4" :lg="2">
              <el-button @click="searchData">Search</el-button>
            </el-col>
          </el-row>
        </div>
        <el-table
          v-loading="mainLoading"
          class="table"
          :data="deliveredOrdersStats"
          style="width: 100%; text-align: left; margin-top: 10px;">
          <el-table-column
            label="Month"
            prop="month">
          </el-table-column>
          <el-table-column
            label="Sub Total Price Total"
            prop="data.total_sub_total_price">
            <template slot-scope="{ row }">
              ₹{{ row.data.total_sub_total_price || 0 }}
            </template>
          </el-table-column>
          <el-table-column
            label="Shipping Price Total"
            prop="data.total_shipping_price">
            <template slot-scope="{ row }">
              ₹{{ row.data.total_shipping_price || 0 }}
            </template>
          </el-table-column>
          <el-table-column
            label="Order Total Price"
            >
            <template slot-scope="{ row }">
              ₹{{ row.data.total_price || 0 }}
            </template>
          </el-table-column>
          <el-table-column
            v-if="!isUserVendor"
            label="Vendor Total Price"
            >
            <template slot-scope="{ row }">
              ₹{{ (row.data.vendor_total_price || 0) }}
            </template>
          </el-table-column>
          <el-table-column
            v-if="!isUserVendor"
            label="Profit After Charges"
            >
            <template slot-scope="{ row }">
              ₹{{ (row.data.total_price || 0) - (row.data.vendor_total_price || 0) - (row.data.total_shipping_price || 0) }}
            </template>
          </el-table-column>
        </el-table>
      </el-scrollbar>
      </div>
  </div>
</template>

<script>
import { $adminAxios, $vendorAxios } from '../config/axios'
import priceMixin from '../mixins/priceMixin'
import moment from 'moment'
import errorHandlerMixin from '@/mixins/errorHandlerMixin'

export default {
  name: 'Categories',
  mixins: [
    priceMixin,
    errorHandlerMixin,
  ],
  data: function () {
    return {
      mainLoading: true,
      deliveredOrdersStats: [],
      locationId: null,
      cities: [],
      year: null,
      years: []
    }
  },
  computed: {
    getAxiosName() {
      const userType = localStorage.getItem('userType')
      return userType === 'VENDOR' ? $vendorAxios : $adminAxios
    },
    isUserVendor() {
      return localStorage.getItem('userType') === 'VENDOR'
    },
  },
  async mounted() {
    this.year = new Date().getFullYear();

    for (let startYear = this.year; startYear >= 2021; startYear--) {
      this.years.push({
        title: startYear,
        value: startYear,
      });
    }

    await this.getLocations()
    await this.fetchDeliveredOrdersStats()
  },
  methods: {
    async getLocations() {
      const locations = await this.getAxiosName.get(`/locations`)
      this.cities.push({
        title: 'All',
        auto_id: null,
      })
      this.cities.push(...(locations.data.data || []))
    },
    async fetchDeliveredOrdersStats () {
      this.mainLoading = true
      try {
        const queryString = `?location_id=${this.locationId}&year=${this.year}`
        const stats = await this.getAxiosName.get(`/delivered-orders-stats${queryString}`)

        this.deliveredOrdersStats = stats.data.data
      } catch (err) {
        this.ehm__errorMessages(err, true);
      }

      this.mainLoading = false
    },
    async changeCity(locationId) {
      this.locationId = locationId
      await this.fetchDeliveredOrdersStats()
    },
    async changeYear(year) {
      this.year = year
      await this.fetchDeliveredOrdersStats()
    },
    async searchData() {
      await this.fetchDeliveredOrdersStats()
    },
  },
}
</script>

<style lang="scss" scoped>
.b-active {
  color: #409eff;
  border-color: #c6e2ff;
  background-color: #ecf5ff;
}

.b-active {
  color: #409eff;
  border-color: #c6e2ff;
  background-color: #ecf5ff;
}
.mobile-order-options {
  display: none;
}
.desktop-order-options {
  display: block;
}

a {
  text-decoration: none;
}

@media screen and (max-width:768px) {
  .order-card {
    margin-top: 10px;
  }
}

.customer-details-scroll {
  position: relative;
  height: calc(100vh);
}
</style>
