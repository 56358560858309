import axios from "axios";
import { get } from "lodash";

const Something_went_wrong_try_again = 'Something went wrong. Try again'
const errorHandlerMixin = {
  methods: {
    ehm__errorMessages(err, isAuthenticated = false, notifyErrors = false) {
      if (!err) return;
      if (axios.isCancel(err)) return;
      if (err === "cancel") return;
      if (err.type === "ACCESS_DENIED") {
        this.ehm__errorAccessDenied(err.errors);
        return;
      }
      if (err.response != null || err.status != null) {
        try {
          switch (err.response.status || err.status) {
            case 401:
              this.ehm__error401Handler(err, isAuthenticated);
              break;
            case 400:
              this.ehm__error400Handler(err, isAuthenticated);
              break;
            case 500:
            case 501:
            case 502:
              this.ehm__error500Handler(err, isAuthenticated);
              break;
            default:
              console.error(err);
              break;
          }
        } catch (err) {
          console.log("Some thing wond");
        }
      } else if (!navigator.onLine) {
        console.log("offline");
      } else if (err.request && !navigator.onLine) {
        console.log(err.request, "Pondlogs");
        this.ehm__isNetworkError = true;
        this.ehm__unhandledErrorMessage = Something_went_wrong_try_again
        this.$notify({
          title: 'Error',
          message: Something_went_wrong_try_again,
          duration: 5000,
          type: "error"
        });
      } else {
        console.log(err);
        // Something happened in setting up the request that triggered an Error
        this.ehm__isNetworkError = true;
        this.ehm__unhandledErrorMessage = Something_went_wrong_try_again;
        this.$notify({
          title: 'Error',
          message: Something_went_wrong_try_again,
          duration: 5000,
          type: "error"
        });
      }
      console.log(err.config);
    },
    ehm__error401Handler: function(err, isAuthenticated) {
      const tokenExpiredErrorNames = [
        "TOKEN_EXPIRED",
        "INVALID_ACCESS_TOKEN",
        "UNAUTHORIZED",
      ];
      if (isAuthenticated) {
        if (
          tokenExpiredErrorNames.indexOf(err.response.data.error_code) !== -1
        ) {
          this.$store.dispatch("auth/signOut");
          this.$router.push("/");
        }
      } else {
        this.ehm__unhandledErrorMessage =
          err.response.data.message || Something_went_wrong_try_again;
        this.$store.dispatch("auth/signOut");
        if (this.$route.path !== "/") {
          this.$router.push("/");
        }
      }
    },
    ehm__error400Handler: function(err, isAuthenticated) {
      this.ehm__unhandledErrorMessage = err.response.data.message || Something_went_wrong_try_again
    },
    ehm__error500Handler: function() {
      this.$notify({
        title: 'Error',
        message: 'Something went wrong. Try again',
        duration: 5000,
        type: "error"
      });
    },
    ehm__errorAccessDenied: async function(errors) {
      const h = this.$createElement;
      return this.$msgbox({
        title: "Error",
        message: h("div", null, [
          h("span", { class: { "popup-error-lists": true } }, errors[0].message)
        ]),
        confirmButtonText: "OK",
        closeOnClickModal: false
      });
    }
  }
};

export default errorHandlerMixin;
