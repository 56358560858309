<template>
  <div class="main-body">
    <a href="#" @click="gotoHome">Goto Home</a>
    <br/>
    <template>
      <el-date-picker
        style="margin-top: 15px;"
        v-model="date"
        @change="changeDate"
        type="date"
        format="yyyy-MM-dd"
        placeholder="Select Date">
      </el-date-picker>
    </template>

    <br/>

    <template>
      <div style="margin-top: 10px;">
        <el-button plain :class="getClassListForLevel2('open')" @click="fetchOrdersForButtonsLevel2('open')">
          Not Acknowledged {{ getOrderCounts('open') ? `(${getOrderCounts('open')})` : '' }}
        </el-button>
        <el-button v-if="!hideAllButtonsExpectOpen" plain :class="getClassListForLevel2('processed')" @click="fetchOrdersForButtonsLevel2('processed')">
          Processed {{ getOrderCounts('processed') ? `(${getOrderCounts('processed')})` : '' }}
        </el-button>
        <el-button v-if="!hideAllButtonsExpectOpen" plain :class="getClassListForLevel2('out_for_delivery')" @click="fetchOrdersForButtonsLevel2('out_for_delivery')">
          Out For Delivery {{ getOrderCounts('out_for_delivery') ? `(${getOrderCounts('out_for_delivery')})` : '' }}
        </el-button>
        <el-button v-if="!hideAllButtonsExpectOpen" plain :class="getClassListForLevel2('delivered')" @click="fetchOrdersForButtonsLevel2('delivered')">
          Delivered {{ getOrderCounts('delivered') ? `(${getOrderCounts('delivered')})` : '' }}
        </el-button>
        <el-button v-if="!hideAllButtonsExpectOpen" plain :class="getClassListForLevel2('cancelled')" @click="fetchOrdersForButtonsLevel2('cancelled')">
          Cancelled {{ getOrderCounts('cancelled') ? `(${getOrderCounts('cancelled')})` : '' }}
        </el-button>
        <el-button v-if="!hideAllButtonsExpectOpen" plain :class="getClassListForLevel2()" @click="fetchOrdersForButtonsLevel2()">
          All Stages {{ getOrderCounts() ? `(${getOrderCounts()})` : '' }}
        </el-button>
      </div>
    </template>

    <el-scrollbar class="customer-details-scroll" style="margin-top: 20px;">
      <div class="">
        <el-table
          v-loading="mainLoading"
          class="table"
          :lazy="mainLoading"
          :data="orders"
          style="width: 100%; text-align: left; ">
          <el-table-column
            label="Order Id"
            prop="auto_id">
            <template slot-scope="{ row }">
              <el-link :href="getItemUrl(row.auto_id)" >{{ row.auto_id }}</el-link>
            </template>
          </el-table-column>
          <el-table-column
            v-if="!isUserVendor"
            label="City"
          >
            <template slot-scope="{ row }">
              {{ row.shipping_address.city }}
            </template>
          </el-table-column>
          <!-- <el-table-column
            label="Payment Status"
            prop="financial_status">
            <template slot-scope="{ row }">
              <el-tag v-if="row.financial_status === 'paid'" type="success">Paid</el-tag>
              <el-tag v-else-if="row.financial_status === 'pending'" type="warning">Pending</el-tag>
              <el-tag v-else-if="row.financial_status === 'failed'" type="danger">Failed</el-tag>
              <el-tag v-else type="info">{{ row.financial_status }}</el-tag>
            </template>
          </el-table-column> -->
          <el-table-column
            label="Order Status"
            prop="status">
            <template slot-scope="{ row }">
              <!-- <el-tag v-if="row.status === 'open'" type="" effect="dark">
              </el-tag> -->
              <el-button
                type="success" v-if="row.status === 'open'" size="small"
                @click="acceptOrder(row.auto_id)"
              >
                Accept<i class="el-icon-circle-check"></i>
              </el-button>
              <el-button
                type="danger" v-if="row.status === 'open'" size="small"
                @click="rejectOrder(row.auto_id)"
              >
                Reject <i class="el-icon-circle-close"></i>
              </el-button>
              <el-tag v-else-if="row.status === 'processed'" type="warning" effect="dark">Processed</el-tag>
              <el-tag v-else-if="row.status === 'cancelled'" type="danger">Cancelled</el-tag>
              <el-tag v-else-if="row.status === 'delivered'" type="success" effect="dark">Delivered</el-tag>
              <el-tag v-else type="info">{{ row.status }}</el-tag>
            </template>
          </el-table-column>
          <el-table-column
            label="Delivery Date"
            prop="delivery_date">
            <template slot-scope="{ row }">
              {{ orderDeliveryDate(row) }}
            </template>
          </el-table-column>
          <!-- <el-table-column
            label="Order Date"
            prop="created_at">
            <template slot-scope="{ row }">
              {{ dateTimeFormat(row.created_at) }}
            </template>
          </el-table-column> -->
          <el-table-column
            label="Price">
            <template slot-scope="{ row }">
              &#8377;{{ isUserVendor ? row.vendor_sub_total_price : row.sub_total_price }}
            </template>
          </el-table-column>
          <el-table-column
            label="Delivery Charge">
            <template slot-scope="{ row }">
              &#8377;{{ isUserVendor ? row.vendor_shipping_price : row.total_shipping_price }}
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div style="margin-top: 20px; text-align: center;">
        <el-pagination
          @current-change="handlePageChange"
          @size-change="handlePageSizeChange"
          :current-page.sync="page"
          :page-sizes="[5, 10, 20, 30]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="totalOrdersCount">
        </el-pagination>
      </div>
    </el-scrollbar>
  </div>
</template>

<script>
import { $adminAxios, $vendorAxios } from '../config/axios'
import priceMixin from '../mixins/priceMixin'
import moment from 'moment'
import errorHandlerMixin from '@/mixins/errorHandlerMixin'

export default {
  name: 'Categories',
  mixins: [
    priceMixin,
    errorHandlerMixin,
  ],
  data: function () {
    return {
      defaultTableProps: {
        size: "mini",
        border: true,
      },
      columns: [{
        prop: 'auto_id',
        label: 'Order Id',
        required: true,
      }],
      orders: [],
      totalOrdersCount: 0,
      mainLoading: true,
      page: 1,
      pageSize: 5,
      activeStatusFilter: 'status$open',
      mobileActiveStatusFilter: 'status$open',
      openOrdersCount: 10,
      level1: 'today',
      date: null,
      type: null,
      statusType: null,
      statusValue: null,
      dateFilter: 'today',
      ordersCounts: {},
    }
  },
  computed: {
    getOrderCounts() {
      return key => {
        return this.ordersCounts[key] || 0
      }
    },
    computedTableProps() {
      return {
        ...this.defaultTableProps,
        ...(this.tableProps || {}),
      };
    },
    dateTimeFormat() {
      return (date) => {
        const givenDate = moment(date)

        const format = this.getDateDifferenceInMonths(givenDate) < 12 ? 'MMM DD - hh:mm A' : 'MMM DD, yy - hh:mm A'
        return givenDate.format(format)
      }
    },
    dateFormat() {
      return (date) => {
        const givenDate = moment(date)

        const format = this.getDateDifferenceInMonths(givenDate) < 12 ? 'MMM DD' : 'MMM DD, yy'
        return givenDate.format(format)
      }
    },
    getDateDifferenceInMonths() {
      return (givenDateInMoment) => {
        const currentDate = moment.utc()

        return currentDate.diff(givenDateInMoment, 'months')
      }
    },
    orderDeliveryDate() {
      return order => {
        return `${this.dateFormat(order.delivery_date_string)} (${order.delivery_time_slot})`
      }
    },
    getAxiosName() {
      const userType = localStorage.getItem('userType')
      return userType === 'VENDOR' ? $vendorAxios : $adminAxios
    },
    isUserVendor() {
      return localStorage.getItem('userType') === 'VENDOR'
    },
    getHomeUrl() {
      const userType = localStorage.getItem('userType')
      const mainPath = userType === 'VENDOR' ? 'vendor' : 'admin'

      return `/${mainPath}/orders-main`
    },
    hideAllButtonsExpectOpen() {
      return this.type === 'currentOrders' && this.statusType === 'status' && this.statusValue === 'open'
    },
  },
  mounted() {
    const { date, type, statusType, statusValue } = this.$route.query

    this.date = date
    this.type = type
    this.statusType = statusType || 'status'
    this.statusValue = statusValue

    if (this.type !== 'currentOrders') {
      this.statusValue = this.statusValue || 'processed'
    }

    this.fetchOrders(null, null)
  },
  methods: {
    getItemUrl(id) {
      const userType = localStorage.getItem('userType')
      const mainPath = userType === 'VENDOR' ? 'vendor' : 'admin'

      return `/${mainPath}/orders/${id}`
    },
    async fetchOrders (page, limit) {
      page = page || this.page
      limit = limit || this.pageSize

      this.orderCounts = {}

      this.mainLoading = true

      const statusType = 'status'
      const statusValue = this.statusValue

      const date = this.date ? moment(this.date).format('YYYY-MM-DD') : null

      try {
        const [response, orderCounts] = await Promise.all([
          this.getAxiosName.get(`/orders`, {
            params: {
              page,
              limit,
              statusType,
              statusValue,
              date,
              type: this.type,
            }
          }),
          this.getAxiosName.get(`/status-based-order-counts`, {
            params: {
              date,
              type: this.type,
            }
          }),
        ])

        this.orders = response.data.data
        this.totalOrdersCount = response.data.total
        this.ordersCounts = orderCounts.data.data
      } catch (err) {
        this.ehm__errorMessages(err, true);
      }

      this.mainLoading = false
    },
    getRowValue(object, columnObject) {
      const path = columnObject.prop;
      const type = columnObject.type;

      const value =
        typeof this.$lodash.result(object, path) === "undefined"
          ? "--"
          : this.$lodash.result(object, path);

      if (type === "date") {
        const valueTemp =
          value && value !== "--"
? {
              type: "string",
              value: moment.utc(new Date(value)).format('yyyy-mm-dd'),
            }
: { type: "string", value: "--" };
        return valueTemp;
      }
      if (Array.isArray(value)) {
        return { type: "array", value };
      }
      return { type: "string", value };
    },
    handlePageChange(page) {
      this.fetchOrders(page)
    },
    handlePageSizeChange(size) {
      this.fetchOrders(this.page, size)
    },
    fetchOrdersForButtonsLevel2(value) {
      this.statusValue = value
      this.fetchOrders(1, null)
    },
    getClassListForLevel2(value) {
      const classList = ['b-active']

      if (this.statusValue === value) {
        return classList
      }

      return []
    },
    changeDate() {
      this.fetchOrders(1)
    },
    async acceptOrder(orderId) {
      const data = {
        status_type: 'status',
        status_value: 'processed'
      }

      const url = `/orders/${orderId}/status`;
      await this.updateOrderDetails(url, data)
      await this.fetchOrders()
    },
    async rejectOrder(orderId) {
      this.$prompt('Enter cancelling reason', 'Order Cancel Confirmation', {
        confirmButtonText: 'Submit',
        cancelButtonText: 'Cancel',
      }).then(async ({ value }) => {
        const data = {
          status_type: 'status',
          status_value: 'cancelled',
          cancelled_reason: value,
        }

        const url = `/orders/${orderId}/status`;

        await this.updateOrderDetails(url, data)
        await this.fetchOrders()
      })
    },
    async updateOrderDetails(url, data) {
      this.mainLoading = true;

      try {
        await this.getAxiosName.patch(url, data);

        this.$notify.success({
          title: "Success",
          message: "Successfully updated order details",
          type: "success",
        });
      } catch (err) {
        this.ehm__errorMessages(err, true);
      }

      this.mainLoading = false;
    },
    gotoHome() {
      return this.$router.push(this.getHomeUrl)
    },
  },
}
</script>

<style lang="scss" scoped>
.customer-details-scroll {
  position: relative;
  height: calc(100vh - 150px);
  margin-top: 10px;
}
</style>

<style lang="scss" scoped>
.b-active {
  color: #409eff;
  border-color: #c6e2ff;
  background-color: #ecf5ff;
}

.b-active {
  color: #409eff;
  border-color: #c6e2ff;
  background-color: #ecf5ff;
}
.mobile-order-options {
  display: none;
}
.desktop-order-options {
  display: block;
}

@media screen and (max-width:768px) {
  .mobile-order-options {
    display: block;
  }
  .desktop-order-options {
    display: none;
  }
}
</style>
