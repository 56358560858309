<template>
  <div class="main-body">
    <el-scrollbar class="customer-details-scroll">
      <div class="">
        <el-row :gutter="20">
          <el-col :xs="12" :sm="4" :md="4" :lg="3" style="margin-left: 10px">
            <el-input v-model="searchString" placeholder="Search" clearable=""></el-input>
          </el-col>
          <el-col :xs="12" :sm="4" :md="4" :lg="2">
            <el-button @click="searchData">Search</el-button>
          </el-col>
          <el-col :sm="12" :md="6" :lg="3" :xl="2">
            <el-button @click="createLocation">Add Location</el-button>
          </el-col>
          <el-col :sm="12" :md="6" :lg="3" :xl="2">
            <el-button @click="regenerateDynamicUrls">Regenerate Dynamic Urls</el-button>
          </el-col>
        </el-row>
      </div>
      <div class="" style="margin-top: 15px;">
        <el-table
          v-loading="locationsLoading"
          class="table"
          :lazy="locationsLoading"
          :data="locations"
          style="width: 100%; text-align: left;"
          @sort-change="sortChange">
          <el-table-column
            label="Title"
            prop="title"
            sortable
            >
            <template slot-scope="{ row }">
              <el-link :href="getLocationUrlUrl(row.auto_id)" >{{ row.title }}</el-link>
            </template>
          </el-table-column>
          <el-table-column
            label="Status"
            prop="status">
          </el-table-column>
          <el-table-column
            label="Opening time"
            prop="open_time">
          </el-table-column>
          <el-table-column
            label="Closing time"
            prop="close_time">
          </el-table-column>
        </el-table>
      </div>
      <div style="margin-top: 20px; text-align: center;">
        <el-pagination
          @current-change="handlePageChange"
          @size-change="handlePageSizeChange"
          :current-page.sync="page"
          :page-sizes="[30, 40 ,50]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="totalLocationsCount">
        </el-pagination>
      </div>
    </el-scrollbar>
  </div>
</template>

<script>
import { $adminAxios } from '../config/axios'
import priceMixin from '../mixins/priceMixin'
import moment from 'moment'

export default {
  name: 'Locations',
  mixins: [
    priceMixin,
  ],
  data: function () {
    return {
      defaultTableProps: {
        size: "mini",
        border: true,
      },
      columns: [{
        prop: 'title',
        label: 'Title',
        required: true,
      }],
      locations: [],
      totalLocationsCount: 0,
      locationsLoading: true,
      page: 1,
      pageSize: 30,
      searchString: '',
      orderBy: 'title',
      orderType: 'asc',
    }
  },
  computed: {
    computedTableProps() {
      return {
        ...this.defaultTableProps,
        ...(this.tableProps || {}),
      };
    },
  },
  mounted() {
    this.fetchLocations()
  },
  methods: {
    getLocationUrlUrl(locationId) {
      return `/admin/locations/${locationId}`
    },
    async fetchLocations (page, limit) {
      page = page || this.page
      limit = limit || this.pageSize

      this.locationsLoading = true

      try {
        let queryParamsString = `page=${page}&limit=${limit}`

        if (this.searchString) {
          queryParamsString += `&search_string=${this.searchString}`
        }
        if (this.orderBy && this.orderType) {
          queryParamsString += `&order_type=${this.orderType}&order_by=${this.orderBy}`
        }

        const response = await $adminAxios.get(`/locations?${queryParamsString}`)
        this.locations = response.data.data
        this.totalLocationsCount = response.data.total
      } catch (err) {
        console.error(err)
        this.$notify.success({
          title: 'Error',
          message: err.message || 'Something went wrong',
          type: 'error'
        });
      }

      this.locationsLoading = false
    },
    async regenerateDynamicUrls() {
      try {
        const response = await $adminAxios.get(`/generate-dynamic-urls`)

        this.$notify.success({
          title: 'Success',
          message: 'Successfully Generated Dynamic Urls',
        })
      } catch (err) {
        console.error(err)
        this.$notify.success({
          title: 'Error',
          message: err.message || 'Something went wrong',
          type: 'error'
        });
      }
    },
    handlePageChange(page) {
      this.fetchLocations(page, this.size)
    },
    handlePageSizeChange(size) {
      this.fetchLocations(this.page, size)
    },
    createLocation() {
      this.$router.push('/admin/locations/create')
    },
    searchData() {
      this.page = 1
      this.fetchLocations()
    },
    sortChange(el) {
      this.orderBy = el.prop
      this.orderType = el.order === 'ascending' ? 'asc' : 'desc'
      this.page = 1
      this.fetchLocations()
    },
  },
}
</script>

<style lang="scss" scoped>
.customer-details-scroll {
  position: relative;
  height: calc(100vh - 75px);
  margin-top: 10px;
}
</style>
