<template>
  <div>
    <el-scrollbar class="customer-details-scroll" v-loading="locationsLoading">
      <h2 style="padding: 10px">
        {{ locationInformation.title }}
      </h2>
      <el-row :gutter="20">
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <el-card shadow="always">
            <p>
              <b>Location Launch Status:</b> {{ locationStatus }}<br/>
            </p>
            <p style="margin-top: 10px;">
              <el-button v-if="showReadyForLaunch" @click="locationStatusUpdate('READY_FOR_LAUNCH')" :disabled="updateStatusLoading">
                <span v-if="updateStatusLoading">
                  <i class="el-icon-loading"></i>
                </span>
                <span>
                  Ready For Launch
                </span>
              </el-button>
              <!-- <el-button v-else-if="showLaunchStatus" @click="locationStatusUpdate('ACTIVE')">Direct Launch</el-button> -->
            </p>
          </el-card>
        </el-col>
      </el-row>
      <el-row :gutter="20" style="margin-top: 20px;">
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <el-card shadow="always">
            <p>
              <b>Total Products In Location:</b> {{ this.locationLaunchDetails.totalProductsInLocation }} <br/>
            </p>
            <p v-if="totalProducts === 0" style="margin-top: 10px;">
              <el-button @click="copyAllProductsFromTemplates" :disabled="copyProductsLoading">
                <span v-if="copyProductsLoading">
                  <i class="el-icon-loading"></i>
                </span>
                <span>
                  Copy Products From Templates
                </span>
              </el-button>
            </p>
          </el-card>
        </el-col>
      </el-row>
      <el-row :gutter="20" style="margin-top: 20px;">
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <el-card shadow="always">
            <p>
              <b>Vendor Details of Location:</b>
            </p>
            <p style="margin-top: 10px;">
              Title: {{ (this.locationLaunchDetails.vendorDetails && this.locationLaunchDetails.vendorDetails.title) || '--' }}
            </p>
            <p style="margin-top: 10px;">
              Total vendor prices: {{ this.locationLaunchDetails.totalVendorPrices }}
            </p>
          </el-card>
        </el-col>
      </el-row>
      <el-row :gutter="20" style="margin-top: 20px;" v-if="showLaunchStatus">
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <p>
            <a :href="cityLaunchLink" target="_blank">City Launch Link</a>
          </p>
        </el-col>
      </el-row>
      <br/>
      <br/>
    </el-scrollbar>
  </div>
</template>

<script>
import { $adminAxios } from '../../../config/axios'
import config from '../../../config/app'
import errorHandlerMixin from '@/mixins/errorHandlerMixin'

export default {
  mixins: [
    errorHandlerMixin,
  ],
  computed: {
    locationStatus() {
      return this.locationLaunchDetails.locationDetails && this.locationLaunchDetails.locationDetails.status
    },
    totalProducts() {
      return this.locationLaunchDetails.totalProductsInLocation
    },
    showReadyForLaunch() {
      if (this.locationStatus && this.locationStatus !== 'ACTIVE' && this.locationStatus !== 'READY_FOR_LAUNCH') {
        return true
      }
      return false
    },
    showLaunchStatus() {
      if (this.locationStatus && this.locationStatus === 'READY_FOR_LAUNCH') {
        return true
      }
      return false
    },
    buttonText() {
      return this.locationId ? 'Update Location' : 'Create Location'
    },
    cityLaunchLink() {
      return `${config.USER_WEBSITE_URL}/new-city-launch/${this.locationId}`
    },
  },
  components: {
  },
  props: [
    'locationInformation',
  ],
  data() {
    return {
      locationInformationData: {},
      locationsLoading: false,
      locationLaunchDetails: {},
      locationId: null,
      updateStatusLoading: false,
      copyProductsLoading: false,
    }
  },
  methods: {
    async getLocationLaunchDetails() {
      try {
        const response = await $adminAxios.get(`/locations/${this.locationId}/launch-details`)

        this.locationLaunchDetails = response.data.data
      } catch (err) {
        this.ehm__errorMessages(err, true);
      }
    },
    async locationStatusUpdate(status) {
      this.updateStatusLoading = true
      try {
        const data = {
          status
        }

        await $adminAxios.patch(`/locations/${this.locationId}/main-location-information`, data)

        this.$emit('reload')
        await this.getLocationLaunchDetails()

        this.$notify.success({
          title: 'Success',
          message: 'Successfully updated the status',
          type: 'success'
        });
      } catch (err) {
        console.error(err)
        this.$notify.error({
          title: 'Error',
          message: err.message || 'Something went wrong',
          type: 'error'
        });
      }
      this.updateStatusLoading = false
    },
    async copyAllProductsFromTemplates() {
      this.copyProductsLoading = true
      try {
        const response = await $adminAxios.post(`/copy-products-from-templates-to-new-location`, {
          to_location_id: this.locationId
        })
        await this.getLocationLaunchDetails()

        this.$notify.success({
          title: 'Success',
          message: response.data.message,
          type: 'success'
        });
      } catch (err) {
        const message = err.response && err.response.data && err.response.data.message
        this.$notify.error({
          title: 'Error',
          message: message || 'Something went wrong',
          type: 'error'
        });
      }
      this.copyProductsLoading = false
    },
  },
  watch: {
    locationInformation(val) {
      this.locationInformationData = JSON.parse(JSON.stringify(val))
    },
  },
  async mounted() {
    const locationId = this.$route.params.location_id
    if (locationId && locationId !== 'create') {
      this.locationId = locationId
      await this.getLocationLaunchDetails()
    }
  },
}
</script>

<style lang="scss" scoped>
.customer-details-scroll {
  position: relative;
  height: calc(100vh - 120px);
  margin-top: 10px;
}
</style>
