function unionOfArrays(x, y) {
  const obj = {};
  for (let i = x.length - 1; i >= 0; --i) {
    obj[x[i]] = x[i];
  }
  for (let i = y.length - 1; i >= 0; --i) {
    obj[y[i]] = y[i];
  }
  const res = []
  for (const k in obj) {
    if (obj.hasOwnProperty(k)) {
      res.push(obj[k]);
    }
  }
  return res;
}

export {
  unionOfArrays,
}
