<template>
  <div>
    <el-scrollbar class="customer-details-scroll">
      <el-form label-position="left" label-width="100px" :model="vendorInformationData" >
        <el-row v-if="vendorId" style="margin-bottom: 15px;">
          <el-col :span="6">
            <p><b>Location:</b> {{ vendorInformationData.location_data ? vendorInformationData.location_data.title : 'NA' }}</p>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6">
            <el-form-item label="Location">
              <el-select v-model="vendorInformationData.location_id" :loading="locationsLoading" remote :remote-method="fetchLocations" filterable placeholder="Select Vendor">
                <el-option v-for="location of locations" :key="location.auto_id" :label="location.title" :value="location.auto_id"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6">
            <el-form-item label="Title">
              <el-input v-model="vendorInformationData.title"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6">
            <el-form-item label="Status">
              <el-select v-model="vendorInformationData.status" placeholder="Select Status">
                <el-option label="Draft" value="DRAFT"></el-option>
                <el-option label="Active" value="ACTIVE"></el-option>
                <el-option label="Inactive" value="INACTIVE"></el-option>
                <el-option label="Archive" value="ARCHIVED"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6">
            <el-form-item label="Email">
              <el-input v-model="vendorInformationData.email" name="type"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6">
            <el-form-item label="Send Email Updates">
              <el-select v-model="vendorInformationData.send_email_updates">
                <el-option
                  key="true"
                  label="Yes"
                  :value="true"
                ></el-option>
                <el-option
                  key="false"
                  label="No"
                  :value="false"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6">
            <el-form-item label="Phone">
              <el-input v-model="vendorInformationData.phone" name="type"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6">
            <el-form-item label="Address">
              <el-input type="textarea" rows="3" v-model="vendorInformationData.address" name="type"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6">
            <el-form-item label="Discount by Vendor (%)">
              <el-input type="number" v-model="vendorInformationData.vendor_discount_percentage" name="type"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6">
            <el-form-item label="Eggless cost per KG">
              <el-input type="number" v-model="vendorInformationData.egg_less_cost_per_kg" name="type"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6">
            <el-form-item>
              <el-button
                type="primary" @click="updateData">{{ buttonText }}</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-scrollbar>
  </div>
</template>

<script>
import { $adminAxios } from '../../../config/axios'
export default {
  components: {
  },
  props: [
    'vendorInformation',
    'vendorId',
  ],
  data() {
    return {
      vendorInformationData: {},
      locationsLoading: false,
      locationSearchString: null,
      locations: [],
    }
  },
  methods: {
    updateData() {
      this.$emit('updateData', this.vendorInformationData)
    },
    async fetchLocations (str) {
      this.locationsLoading = true
      this.locationSearchString = str
      try {
        let url = '/locations?get_all=true&minimal=true'
        if (str) {
          url = url + '&title=' + str
        }

        const response = await $adminAxios.get(url)
        if (str === this.locationSearchString) {
          this.locations = response.data.data
        }
      } catch (err) {
        // nothing
      }
      this.locationsLoading = false
    },
  },
  computed: {
    buttonText() {
      return this.vendorId ? 'Update Vendor' : 'Create Vendor'
    },
  },
  watch: {
    vendorInformation(val) {
      this.vendorInformationData = JSON.parse(JSON.stringify(val))
      if (val.location_data && val.location_data.auto_id) {
        this.locations = [val.location_data]
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.customer-details-scroll {
  position: relative;
  height: calc(100vh - 120px);
  margin-top: 10px;
}
</style>
