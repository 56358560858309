<template>
  <div class="main-body">
    <el-scrollbar class="customer-details-scroll">
    <div class="">
      <h4>Basic Config</h4>
    </div>
    <div class="" style="margin-top: 10px;">
      <el-row>
        <el-input type="textarea" rows="3" style="max-width: 300px" v-model="basicConfig.non_delivery_dates" placeholder="Ex: 2023-01-01, 2023-01-14" />
      </el-row>
      <el-row>
        <el-button style="margin-top: 10px;" :disabled="loading" type="button" @click="updateConfig">Update Config</el-button>
      </el-row>
    </div>
    </el-scrollbar>
  </div>
</template>

<script>
import { $adminAxios } from '../config/axios'
import priceMixin from '../mixins/priceMixin'

export default {
  name: 'BasicConfig',
  mixins: [
    priceMixin,
  ],
  data: function () {
    return {
      basicConfig: {},
      loading: false,
    }
  },
  created() {
    this.fetchBasicConfig()
  },
  methods: {
    async fetchBasicConfig() {
      try {
        const response = await $adminAxios.get(`/basic-config`)
        const data = response.data.data
        this.basicConfig = data
        this.basicConfig.non_delivery_dates = data.non_delivery_dates.join(', ')
        console.log('ss', this.basicConfig)
      } catch (err) {
        console.error(err)
        this.$notify.success({
          title: 'Error',
          message: err.message || 'Something went wrong',
          type: 'error'
        });
      }
    },
    async updateConfig () {
      this.loading = true

      this.basicConfig.non_delivery_dates = this.basicConfig.non_delivery_dates.split(',').map(el => el.replace(/\s+/g, ' ').trim())

      try {
        const response = await $adminAxios.patch(`/basic-config`, this.basicConfig)
        this.$notify.success({
          title: 'Success',
          message: response.data.message || 'Done',
          type: 'success'
        });
      } catch (err) {
        console.error(err)
        this.$notify.error({
          title: 'Error',
          message: err.message || 'Something went wrong',
          type: 'error'
        });
      }

      this.loading = false
    },
  },
}
</script>

<style lang="scss" scoped>
.customer-details-scroll {
  position: relative;
  height: calc(100vh - 75px);
  margin-top: 10px;
}
</style>
