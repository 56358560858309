<template>
  <div class="main-body" v-loading="vendorLoading">
    <el-tabs type="card" v-model="firstTab">
      <el-tab-pane name="mainVendorInfo" label="Main Vendor Information">
        <vendor-main-information-update @updateData="updateVendorInformation" :vendorId="vendorId" :vendorInformation="vendorInformation" />
      </el-tab-pane>
      <el-tab-pane name="cakePricesList" label="Cake Prices List">
        <vendor-price-menu-update @updateData="updateVendorPrices" :vendorId="vendorId" :pricesList="priceInformation['cakes']" :vendorInformation="vendorInformation" type="cakes" />
      </el-tab-pane>
      <el-tab-pane name="flowerPricesList" label="Flower Prices List">
        <vendor-price-menu-update @updateData="updateVendorPrices" :vendorId="vendorId" :pricesList="priceInformation['flowers']" :vendorInformation="vendorInformation" type="flowers" />
      </el-tab-pane>
      <el-tab-pane name="chocolatePricesList" label="Chocolate Prices List">
        <vendor-price-menu-update @updateData="updateVendorPrices" :vendorId="vendorId" :pricesList="priceInformation['chocolates']" :vendorInformation="vendorInformation" type="chocolates" />
      </el-tab-pane>
      <el-tab-pane name="giftsPricesList" label="Gift Prices List">
        <vendor-price-menu-update @updateData="updateVendorPrices" :vendorId="vendorId" :pricesList="priceInformation['gifts']" :vendorInformation="vendorInformation" type="gifts" />
      </el-tab-pane>
      <el-tab-pane name="vendorLaunchDetails" label="Launch Details">
        <vendor-launch-details @updateData="updateVendorPrices" :vendorId="vendorId" :vendorInformation="vendorInformation" @reload="reload" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import vendorMainInformationUpdate from '@/components/admin/vendorUpdate/vendorMainInformationUpdate.vue'
import vendorPriceMenuUpdate from '@/components/admin/vendorUpdate/vendorPriceMenuUpdate.vue'
import vendorLaunchDetails from '@/components/admin/vendorUpdate/vendorLaunchDetails.vue'
import { $adminAxios } from '../config/axios'

export default {
  components: {
    vendorMainInformationUpdate,
    vendorPriceMenuUpdate,
    vendorLaunchDetails,
  },
  data() {
    return {
      vendorInformation: {},
      priceInformation: {},
      vendor: {},
      vendorLoading: false,
      firstTab: "mainVendorInfo",
      vendorId: null,
    }
  },
  mounted() {
    this.defaultFn()
  },
  watch: {
    '$route.params.vendor_id'() {
      this.defaultFn()
    },
  },
  methods: {
    defaultFn() {
      const vendorId = this.$route.params.vendor_id

      if (vendorId !== 'create') {
        this.vendorId = vendorId
        this.fetchVendor()
      }
    },
    async fetchVendor () {
      this.vendorLoading = true

      try {
        const response = await $adminAxios.get(`/vendors/${this.vendorId}`)
        this.vendor = response.data.data
        this.vendorInformation = {
          title: this.vendor.title,
          status: this.vendor.status,
          email: this.vendor.email,
          phone: this.vendor.phone,
          address: this.vendor.address,
          vendor_discount_percentage: this.vendor.vendor_discount_percentage,
          user_display_percentage: this.vendor.user_display_percentage,
          user_purchase_percentage: this.vendor.user_purchase_percentage,
          location_id: this.vendor.location_id,
          location_data: this.vendor.location_data,
          egg_less_cost_per_kg: this.vendor.egg_less_cost_per_kg,
          stock_availability: this.vendor.stock_availability,
          send_email_updates: this.vendor.send_email_updates,
        }

        this.priceInformation.cakes = this.vendor.cakes
        this.priceInformation.chocolates = this.vendor.chocolates
        this.priceInformation.flowers = this.vendor.flowers
        this.priceInformation.gifts = this.vendor.gifts
      } catch (err) {
        console.error(err)
        this.$notify.error({
          title: 'Error',
          message: err.message || 'Something went wrong',
          type: 'error'
        });
      }

      this.vendorLoading = false
    },

     async updateVendorInformation (data) {
      if (this.vendorId) {
        await this.updateData(data, 'mainVendorInformation')
      } else {
        await this.updateData(data, 'createVendorInformation')
      }
    },
    async updateVendorPrices (type, data) {
      console.log(type, data)
      await this.updatePrices(type, data)
    },
    async updateData (data, updateType) {
      this.vendorLoading = true

      let message
      let url
      let method = 'patch'
      if (updateType === 'mainVendorInformation') {
        url = `/vendors/${this.vendorId}/main-vendor-information`
        message = 'Succesfully updated vendor'
      } else if (updateType === 'createVendorInformation') {
        url = `/vendors`
        message = 'Succesfully created vendor'
        method = 'post'
      }

      try {
        const response = await $adminAxios[method](url, data)

        this.$notify.success({
          title: 'Success',
          message,
          type: 'success'
        });

        this.vendorLoading = false
        this.vendorInformation = JSON.parse(JSON.stringify(data, null, 2))

        if (!this.vendorId) {
          this.$router.push(`/admin/vendors/${response.data.data.auto_id}`)
        }
      } catch (err) {
        console.error(err)
        this.$notify.error({
          title: 'Error',
          message: err.message || 'Something went wrong',
          type: 'error'
        });
      }

      this.vendorLoading = false
    },
    async updatePrices (type, data) {
      this.vendorLoading = true

      try {
        const response = await $adminAxios.patch(`/vendors/${this.vendorId}/prices/${type}`, data)

        this.$notify.success({
          title: 'Success',
          message: 'Succesfully updated the vendor prices',
          type: 'success'
        });

        this.vendorLoading = false
        this.vendorInformation[type] = JSON.parse(JSON.stringify(response.data.data, null, 2))
      } catch (err) {
        console.error(err)
        this.$notify.error({
          title: 'Error',
          message: err.message || 'Something went wrong',
          type: 'error'
        });
      }

      this.vendorLoading = false
    },
    async reload() {
      await this.fetchVendor()
    },
  }
}
</script>
