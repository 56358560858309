<template>
  <div class="main-body" v-loading="locationLoading">
    <el-tabs type="card" v-model="firstTab">
      <el-tab-pane name="mainLocationInfo" label="Location Information">
        <location-main-information-update @updateData="updateLocationInformation" :locationId="locationId" :locationInformation="locationInformation" />
      </el-tab-pane>
      <el-tab-pane name="locationAreasUpdate" label="Areas">
        <location-areas-update @updateData="updateLocationInformation" :locationId="locationId" :locationInformation="locationInformation" />
      </el-tab-pane>
      <el-tab-pane name="locationLaunchDetails" label="Launch Details">
        <location-launch-details :locationId="locationId" :locationInformation="locationInformation" @reload="reload" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import locationMainInformationUpdate from '@/components/admin/locations/locationMainInformationUpdate'
import locationAreasUpdate from '@/components/admin/locations/locationAreasUpdate'
import locationLaunchDetails from '@/components/admin/locations/locationLaunchDetails'
import { $adminAxios } from '../config/axios'

export default {
  components: {
    locationMainInformationUpdate,
    locationAreasUpdate,
    locationLaunchDetails,
  },
  data() {
    return {
      locationInformation: {},
      location: {},
      locationLoading: false,
      firstTab: "mainLocationInfo",
      locationId: null,
    }
  },
  mounted() {
    this.defaultFn()
  },
  watch: {
    '$route.params.location_id'() {
      this.defaultFn()
    },
  },
  methods: {
    defaultFn() {
      const locationId = this.$route.params.location_id

      if (locationId !== 'create') {
        this.locationId = locationId
        this.fetchLocation()
      }
    },
    async fetchLocation () {
      this.locationLoading = true

      try {
        const response = await $adminAxios.get(`/locations/${this.locationId}`)
        this.location = response.data.data
        this.locationInformation = {
          auto_id: this.location.auto_id,
          title: this.location.title,
          status: this.location.status,
          open_time: this.location.open_time,
          close_time: this.location.close_time,
          areas: this.location.areas,
        }
      } catch (err) {
        console.error(err)
        this.$notify.error({
          title: 'Error',
          message: err.message || 'Something went wrong',
          type: 'error'
        });
      }

      this.locationLoading = false
    },
    async updateLocationInformation (data) {
      if (this.locationId) {
        await this.updateData(data, 'mainLocationInformation')
      } else {
        await this.updateData(data, 'createLocationInformation')
      }
    },
    async updateData (data, updateType) {
      this.locationLoading = true

      let message
      let url
      let method = 'patch'
      if (updateType === 'mainLocationInformation') {
        url = `/locations/${this.locationId}/main-location-information`
        message = 'Successfully update location'
      } else if (updateType === 'createLocationInformation') {
        url = `/locations`
        message = 'Successfully created location'
        method = 'post'
      }

      try {
        const response = await $adminAxios[method](url, data)

        this.$notify.success({
          title: 'Success',
          message,
          type: 'success'
        });

        if (!this.locationId) {
          this.$router.push(`/admin/locations/${response.data.data.auto_id}`)
        }
      } catch (err) {
        console.error(err)
        this.$notify.error({
          title: 'Error',
          message: err.message || 'Something went wrong',
          type: 'error'
        });
      }

      this.locationLoading = false
    },
    async reload() {
      await this.fetchLocation()
    }
  }
}
</script>
