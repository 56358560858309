<template>
  <el-row class="admin-header">
    <el-col :span="20">
        <el-menu
          class="admin-menu"
          mode="horizontal"
          :default-active="activeRoute"
          :router="true"
        >
          <er-menu-item
            index="orders-dashboard"
            label="Dashboard"
            route="/admin/orders-dashboard"
            :isActive="activeRoute === 'orders-dashboard'"
          >
          </er-menu-item>

          <er-menu-item
            index="orders"
            label="Orders"
            route="/admin/orders-main"
            :isActive="activeRoute === 'orders' || activeRoute === 'orders-main'"
          >
          </er-menu-item>

          <er-menu-item
            class="m-menu"
            index="customers"
            label="Customers"
            route="/admin/customers"
            :isActive="activeRoute === 'customers'"
          >
          </er-menu-item>
          <er-menu-item
            class="m-menu"
            index="products"
            label="Products"
            route="/admin/products"
            :isActive="activeRoute === 'products'"
          >
          </er-menu-item>
          <er-menu-item
            class="m-menu"
            index="locations"
            label="Locations"
            route="/admin/locations"
            :isActive="activeRoute === 'locations'"
          >
          </er-menu-item>
          <er-menu-item
            class="m-menu"
            index="vendors"
            label="Vendors"
            route="/admin/vendors"
            :isActive="activeRoute === 'vendors'"
          >
          </er-menu-item>

          <el-dropdown placement="bottom-start" :hide-on-click="true" @command="handleCommand" class="smenu" style="font-size: 16px;">
            <span class="el-dropdown-link">
              More<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="categories">Categories</el-dropdown-item>
              <el-dropdown-item command="payment-links">Payment Links</el-dropdown-item>
              <el-dropdown-item command="config">Config</el-dropdown-item>
              <el-dropdown-item command="template-products">Template Products</el-dropdown-item>
              <el-dropdown-item command="template-vendors/10000">Template Vendor</el-dropdown-item>
              <!-- <el-dropdown-item command="payments">Payments</el-dropdown-item> -->
            </el-dropdown-menu>
          </el-dropdown>

          <!-- <el-dropdown placement="bottom-start" :hide-on-click="true" @command="handleCommand" class="smenu" style="font-size: 16px;">
            <span class="el-dropdown-link">
              Templates<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="template-products">Template Products</el-dropdown-item>
              <el-dropdown-item command="template-vendors/10000">Template Vendor</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown> -->

          <el-dropdown placement="bottom-start" :hide-on-click="true" @command="handleCommand" class="smenu" style="font-size: 16px;">
            <span class="el-dropdown-link">
              Reports<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="orders-history">Orders History</el-dropdown-item>
              <el-dropdown-item command="stats">Stats</el-dropdown-item>
              <!-- <el-dropdown-item command="payments">Payments</el-dropdown-item> -->
            </el-dropdown-menu>
          </el-dropdown>

          <el-dropdown placement="bottom-start" :hide-on-click="true" @command="handleCommand" class="d-m-menu" style="font-size: 16px;">
            <span class="el-dropdown-link">
              Other<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="categories">Categories</el-dropdown-item>
              <el-dropdown-item command="products">Products</el-dropdown-item>
              <el-dropdown-item command="customers">Customers</el-dropdown-item>
              <el-dropdown-item command="vendors">Vendors</el-dropdown-item>
              <el-dropdown-item command="locations">Locations</el-dropdown-item>
              <el-dropdown-item command="payment-links">Payment Links</el-dropdown-item>
              <el-dropdown-item command="config">Config</el-dropdown-item>
              <el-dropdown-item command="orders-history">Orders History</el-dropdown-item>
              <el-dropdown-item command="stats">Stats</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </el-menu>
    </el-col>
    <el-col :span="4" style="align: right">
      <el-menu
          class="admin-menu"
          mode="horizontal"
          :default-active="activeRoute"
          :router="true"
        >
          <er-menu-item
            label="Logout"
            route="/logout"
            v-if="isAuthenticated"
          >
          </er-menu-item>
          <er-menu-item
            label="Sign In"
            route="/"
            v-else
          >
          </er-menu-item>
      </el-menu>
    </el-col>
  </el-row>
</template>

<script>
import erMenuItem from '@/components/base/erMenuItem'

export default {
  components: {
    erMenuItem,
  },
  computed: {
    isAuthenticated() {
      return this.$store.getters["auth/isAuthenticated"]
    },
    getUserName() {
      return (
        this.$store.getters["admin/profile"] ? this.$store.getters["admin/profile"].name : ''
      );
    },
    activeRoute() {
      return this.$route.path.split("/")[2];
    },
  },
  methods: {
    handleCommand(link) {
      console.log('ll', link)
      this.$router.push("/admin/" + link);
    }
  },
};
</script>

<style lang="scss" scoped>
.m-menu {
  display: block;
}
.d-m-menu {
  display: none;
  height: calc(var(--header-height) - 2px);
  line-height: calc(var(--header-height) - 2px);
  font-weight: 600;
}
.smenu {
  line-height: calc(var(--header-height) - 2px);
  margin-left: 20px;
}
@media screen and (max-width:768px) {
  .m-menu {
    display: none;
  }
  .d-m-menu {
    display: inline-block;
  }
  .smenu {
    margin-left: 10px;
  }
}
</style>
