<template>
  <div>
    <el-scrollbar class="customer-details-scroll">
      <el-form label-position="left" label-width="100px">
        <el-row>
          <el-col :span="6">
            <el-form-item label="Title">
              <el-input v-model="categoryData.title"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6">
            <el-form-item label="URL">
              <el-input v-model="categoryData.url"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="Description">
              <el-input type="textarea" rows="20"  v-model="categoryData.description"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6">
            <el-form-item label="Meta Title">
              <el-input v-model="categoryData.meta_data.title"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6">
            <el-form-item label="Meta Desctiption">
              <el-input v-model="categoryData.meta_data.description"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6">
            <!-- <el-form-item> -->
            <el-button type="primary" @click="updateData">{{
              buttonText
            }}</el-button>
            <!-- </el-form-item> -->
          </el-col>
        </el-row>
      </el-form>
    </el-scrollbar>
  </div>
</template>

<script>
import { $adminAxios } from "../config/axios";
export default {
  components: {},
  data() {
    return {
      categoryData: {
        meta_data: {},
      },
      categoryLoading: false,
      categoryId: null,
    };
  },
  created() {
    console.log(this.$route.params)
    this.categoryId = this.$route.params.category_id;

    if (this.categoryId) {
      this.fetchCategory();
    }
  },
  computed: {
    buttonText() {
      return this.categoryId ? "Update Category" : "Create Category";
    },
  },
  methods: {
    updateData() {
      if (this.categoryId) {
        this.updateCategory();
      } else {
        this.createCategory();
      }
    },
    async fetchCategory() {
      this.categoryLoading = true;
      try {
        const url = `/categories/${this.categoryId}`;

        const response = await $adminAxios.get(url);

        this.categoryData = JSON.parse(JSON.stringify(response.data.data));
        console.log('ds', this.categoryData)
      } catch (err) {
        console.error(err);
        this.$notify.error({
          title: "Error",
          message: err.message || "Something went wrong",
          type: "error",
        });
      }
      this.categoryLoading = false;
    },
    async createCategory() {
      this.categoryLoading = true;
      try {
        const url = `/categories`;

        const response = await $adminAxios.post(url, this.categoryData);

        this.$notify.success({
          title: "Success",
          message: "Created successfully",
        });

        this.$router.push(`/admin/categories/${response.data.data.auto_id}`);
      } catch (err) {
        console.error(err);
        this.$notify.error({
          title: "Error",
          message: err.message || "Something went wrong",
          type: "error",
        });
      }
      this.categoryLoading = false;
    },
    async updateCategory() {
      this.categoryLoading = true;
      try {
        const url = `/categories/${this.categoryId}`;

        await $adminAxios.patch(url, this.categoryData);

        this.$notify.success({
          title: "Success",
          message: "Updated successfully",
        });
      } catch (err) {
        console.error(err);
        this.$notify.error({
          title: "Error",
          message: err.message || "Something went wrong",
          type: "error",
        });
      }
      this.categoryLoading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.customer-details-scroll {
  position: relative;
  height: calc(100vh - 120px);
  margin-top: 10px;
}
</style>
