var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('el-scrollbar',{staticClass:"customer-details-scroll"},[_c('el-form',{attrs:{"label-position":"left","label-width":"100px","model":_vm.locationInformationData}},[_c('el-row',[_c('el-col',{attrs:{"span":6}},[_c('el-form-item',{attrs:{"label":"Title"}},[_c('el-input',{model:{value:(_vm.locationInformationData.title),callback:function ($$v) {_vm.$set(_vm.locationInformationData, "title", $$v)},expression:"locationInformationData.title"}})],1)],1)],1),_c('el-row',[_c('el-col',{attrs:{"span":6}},[_c('el-form-item',{attrs:{"label":"Status"}},[_c('el-select',{attrs:{"placeholder":"Select Status"},model:{value:(_vm.locationInformationData.status),callback:function ($$v) {_vm.$set(_vm.locationInformationData, "status", $$v)},expression:"locationInformationData.status"}},[_c('el-option',{attrs:{"label":"Draft","value":"DRAFT"}}),_c('el-option',{attrs:{"label":"Active","value":"ACTIVE"}}),_c('el-option',{attrs:{"label":"Inactive","value":"INACTIVE"}}),_c('el-option',{attrs:{"label":"Archive","value":"ARCHIVED"}})],1)],1)],1)],1),_c('el-row',[_c('el-col',{attrs:{"span":6}},[_c('el-form-item',{attrs:{"label":"Is Popular"}},[_c('el-checkbox',{attrs:{"name":"type"},model:{value:(_vm.locationInformationData.is_popular),callback:function ($$v) {_vm.$set(_vm.locationInformationData, "is_popular", $$v)},expression:"locationInformationData.is_popular"}})],1)],1)],1),_c('el-row',[_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{attrs:{"label":"Phone"}},[_c('el-time-select',{attrs:{"placeholder":"Start time","picker-options":{
                start: '00:00',
                step: '00:05',
                end: '23:55'
              }},model:{value:(_vm.locationInformationData.open_time),callback:function ($$v) {_vm.$set(_vm.locationInformationData, "open_time", $$v)},expression:"locationInformationData.open_time"}}),_c('el-time-select',{staticStyle:{"margin-left":"5px"},attrs:{"placeholder":"End time","picker-options":{
                start: '00:05',
                step: '00:05',
                end: '23:55',
                minTime: _vm.locationInformationData.open_time
              }},model:{value:(_vm.locationInformationData.close_time),callback:function ($$v) {_vm.$set(_vm.locationInformationData, "close_time", $$v)},expression:"locationInformationData.close_time"}})],1)],1)],1),_c('el-row',[_c('el-col',{attrs:{"span":6}},[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.updateData}},[_vm._v(_vm._s(_vm.buttonText))])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }